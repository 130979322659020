import React, { useState, useEffect } from 'react';
import {  } from 'react-router-dom';
import db from '../components/GlobalDb';
import Header from '../components/header'; // Header bileşenini ekleyin
import Navbar from '../components/Navbar'; // Header bileşenini ekleyin
import CreateClassroom from './DevamEdenEgitimler';
import NoteuserComponent from '../components/NotSistemiuser';





export default function Home() {



  const [token, setToken] = useState(null);
  useEffect(() => {
    // Fetch token from Dexie on component mount
    const fetchToken = async () => {
      const tokens = await db.tokens.toArray();
      if (tokens.length > 0) {
        setToken(tokens[0].token);
      }
    };
    fetchToken();
  }, [token]);







  return (

    <div id="layout-wrapperx" className="layout-wrapper layout-navbar-full layout-horizontal layout-without-menu">
      <Navbar />
      <div id="content-wrapperx" className="layout-container">

        <Header />

        <div className="layout-page">

          <div className="content-wrapper">

            <div className="container-xxl flex-grow-1 container-p-y">





            <div class="row mb-4 g-4">
            <div class="col-12 col-xl-6 col-md-6">
              <CreateClassroom />
              </div>

         
              <div class="col-12 col-xl-6 col-md-6">
              <NoteuserComponent />
              </div>

              </div>


              <div className='mb-5'>
              </div>

           





            </div>




          </div>
        </div>
      </div>

    </div>




  );
}
