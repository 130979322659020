import React, { useState, useEffect, useRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import trLocale from '@fullcalendar/core/locales/tr';
import { Modal, Form } from 'react-bootstrap';
import listPlugin from '@fullcalendar/list';
import db from '../components/GlobalDb';
import config from '../config';
import { Calendar } from 'primereact/calendar'; // Import Calendar from PrimeReact
import '../assets/vendor/libs/fullcalendar/fullcalendar.css';
import '../assets/vendor/css/pages/app-calendar.css';
import 'primereact/resources/themes/saga-blue/theme.css'; // Tema
import 'primereact/resources/primereact.min.css'; // core css
import 'primeicons/primeicons.css'; // İkonlar
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import Header from './header';
import Navbar from './Navbar';

function MonthlyPlanning() {
  const [events, setEvents] = useState([]);
  const [visible, setVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [eventForm, setEventForm] = useState({ person: '', title: '', start: '', end: '' });
  const calendarRef = useRef(null);
  const initialEventForm = { person: '', title: '', start: '', end: '' };
  const [isUpdating, setIsUpdating] = useState(false);
  const [originalEventDates, setOriginalEventDates] = useState({ start: null, end: null });
  const toast = useRef(null);


  const resetFormAndShow = () => {
    setEventForm(initialEventForm); // Formu sıfırla
    setVisible(true); // Sidebar'ı aç
    setIsUpdating(false); // Güncelleme durumunu false yap
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  const handleEventClick = (clickInfo) => {
    const event = clickInfo.event;
    const [person, title] = event.title.split(' - ');
    setEventForm({
      id: event.id,
      person: person,
      title: title,
      start: event.start,
      end: event.end,
    });
    // Orijinal tarihleri sakla
    setOriginalEventDates({
      start: event.start.toISOString().split('T')[0], // orijinal başlangıç tarihi
      end: event.end ? event.end.toISOString().split('T')[0] : null, // orijinal bitiş tarihi
    });
    setIsUpdating(true);
    setVisible(true);
  };

  const clearEventForm = () => {
    setEventForm(initialEventForm);
    setIsUpdating(false); // Yeni görev ekleme moduna dön
  };

  const fetchEvents = async () => {
    try {
      const tokenObject = await db.tokens.toCollection().first();
      if (tokenObject) {
        const token = tokenObject.token;
        const response = await fetch(`${config.MY_API}api/events-listele`, {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });
        const responseData = await response.json();
        if (response.ok && responseData.success) {
          const formattedEvents = responseData.message.map(event => {
            const start = event.start_date ? new Date(event.start_date) : null;
            const end = event.end_date ? new Date(event.end_date) : null;
          
            // Assuming start and end are the same day for single-day events
            let allDay = false;
            if (start && end) {
              start.setHours(3, 0, 0, 0); // Adjust if necessary
              end.setHours(21, 0, 0, 0); // Adjust if necessary
              allDay = start.toISOString().split('T')[0] === end.toISOString().split('T')[0];
            }
          
            return {
              id: event.id,
              title: `${event.person} - ${event.title}`,
              start: start,
              end: end ? end.toISOString() : null,
              color: event.color,
              textColor: event.text_color,
              allDay: allDay, // This line is crucial
            };
          });
          setEvents(formattedEvents);
        } else {
          console.error('Data fetch error:', responseData.message || 'Unknown error');
        }
      } else {
        throw new Error('No token found');
      }
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  };
  
  
  

  const getFilteredEvents = () => {
    if (selectedCategory === 'all') {
      return events;
    } else {
      return events.filter(event => event.title.startsWith(selectedCategory));
    }
  };

  const handleCategoryChange = e => {
    setSelectedCategory(e.target.value);
  };

  const handleEventFormChange = e => {
    const { name, value } = e.target;
    setEventForm(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleEventDrop = async (info) => {
    // Sürüklenen etkinliğin yeni başlangıç ve bitiş tarihlerini al
    const start = info.event.start;
    const end = info.event.end || start; // Eğer bitiş tarihi yoksa, başlangıç tarihini kullan
  
 

    const formattedStart = start.toISOString().split('T')[0];
    
    
    const formattedEnd = end.toISOString().split('T')[0];



    const [person, title] = info.event.title.split(' - ');
  
    // API isteğini hazırla
    try {
      const tokenObject = await db.tokens.toCollection().first();
      if (!tokenObject) throw new Error('No token found');
      const token = tokenObject.token;
      const response = await fetch(`${config.MY_API}api/events-duzenlex`, {
        method: 'POST', // Ya da güncelleme için uygun metod
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        body: JSON.stringify({
          id: info.event.id,
          person: person,
          title: title,
          start: formattedStart,
          end: formattedEnd,
        }),
      });
  
      const responseData = await response.json();
      if (!response.ok || !responseData.success) {
        throw new Error('Event update failed: ' + (responseData.message || 'Unknown error'));
      }
  
      console.log('Event updated successfully:', responseData.message);
      fetchEvents(); // Etkinlik listesini yeniden çek
    } catch (error) {
      console.error('Error updating event:', error);
      info.revert(); // Güncelleme başarısız olursa, etkinliği eski konumuna geri döndür
    }
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault(); // Form gönderimi varsayılan davranışını engelle
    // Tarihleri formatla



    if (!eventForm.person) {
      toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Lütfen bir eğitmen seçin.', life: 3000 });
      return;
    }

    if (!eventForm.title) {
      toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Görev açıklaması boş bırakılamaz.', life: 3000 });
      return;
    }

  // Başlangıç ve bitiş tarihlerinin doluluğunu kontrol et
  if (!eventForm.start || !eventForm.end) {
    toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Başlangıç ve bitiş tarihleri boş bırakılamaz.', life: 3000 });
    return;
  }

  // Başlangıç ve bitiş tarihlerinin geçerliliğini kontrol et
  const startDate = new Date(eventForm.start);
  const endDate = new Date(eventForm.end);
  if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
    toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Geçersiz tarih.', life: 3000 });
    return;
  }

  if (startDate > endDate) {
    toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Başlangıç tarihi bitiş tarihinden sonra olamaz.', life: 3000 });
    return;
  }

// Formdan alınan tarihleri Date objesine çevir
const newStart = new Date(eventForm.start+'Z');
const newEnd = new Date(eventForm.end+'Z');

// Orijinal tarihler ile karşılaştır ve farklıysa +1 gün ekle
if (originalEventDates.start !== newStart.toISOString().split('T')[0]) {
  newStart.setDate(newStart.getDate() + 0);
}
if (originalEventDates.end !== newEnd.toISOString().split('T')[0]) {
  newEnd.setDate(newEnd.getDate() + 0);
}

const formattedStart = newStart.toISOString().split('T')[0];
const formattedEnd = newEnd.toISOString().split('T')[0];

    try {
      const tokenObject = await db.tokens.toCollection().first();
      if (!tokenObject) throw new Error('No token found');
      const token = tokenObject.token;
      const response = await fetch(`${config.MY_API}api/events-duzenlex`, {
        method: 'POST', // Güncelleme için PUT metodu
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        body: JSON.stringify({
          id: eventForm.id, // Güncellenecek etkinliğin ID'si
          person: eventForm.person,
          title: eventForm.title,
          start: formattedStart,
          end: formattedEnd,
        }),
      });
  
      const responseData = await response.json();
      if (!response.ok || !responseData.success) {
        throw new Error('Event update failed: ' + (responseData.message || 'Unknown error'));
      }
  
      console.log('Event updated successfully:', responseData.message);
      setVisible(false);
      fetchEvents(); // Etkinlik listesini güncelle
      clearEventForm(); // Formu temizle
    } catch (error) {
      console.error('Error updating event:', error);
    }
  };


 const handleFormSubmit = async (e) => {
  if (isUpdating) {
    handleUpdateSubmit(e); // Eğer güncelleme durumu true ise, güncelleme fonksiyonunu çağır
  } else {
    e.preventDefault();
    // Formun varsayılan gönderim davranışını durdur
    // Tarihleri formatla


    if (!eventForm.person) {
      toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Lütfen bir eğitmen seçin.', life: 3000 });
      return;
    }

    if (!eventForm.title) {
      toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Görev açıklaması boş bırakılamaz.', life: 3000 });
      return;
    }

  // Başlangıç ve bitiş tarihlerinin doluluğunu kontrol et
  if (!eventForm.start || !eventForm.end) {
    toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Başlangıç ve bitiş tarihleri boş bırakılamaz.', life: 3000 });
    return;
  }

  // Başlangıç ve bitiş tarihlerinin geçerliliğini kontrol et
  const startDate = new Date(eventForm.start);
  const endDate = new Date(eventForm.end);
  if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
    toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Geçersiz tarih.', life: 3000 });
    return;
  }

  if (startDate > endDate) {
    toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Başlangıç tarihi bitiş tarihinden sonra olamaz.', life: 3000 });
    return;
  }


    const start = new Date(eventForm.start+'Z');

    const formattedStart = start.toISOString().split('T')[0];
    
    const end = new Date(eventForm.end+'Z');

    const formattedEnd = end.toISOString().split('T')[0];


    const apiEndpoint = isUpdating ? `${config.MY_API}api/events-duzenle` : `${config.MY_API}api/events-kaydet`;
    const method = isUpdating ? 'PUT' : 'POST';

    try {
      const tokenObject = await db.tokens.toCollection().first();
      if (!tokenObject) throw new Error('No token found');
      const token = tokenObject.token;
      const response = await fetch(apiEndpoint, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        body: JSON.stringify({
          id: isUpdating ? eventForm.id : undefined,
          person: eventForm.person,
          title: eventForm.title,
          start: formattedStart,
          end: formattedEnd,
        }),
      });




      const responseData = await response.json();
      if (!response.ok || !responseData.success) {
        throw new Error('Event operation failed: ' + (responseData.message || 'Unknown error'));
      }

      console.log('Event operation successful:', responseData.message);
      setVisible(false);
      fetchEvents();
      clearEventForm();
    } catch (error) {
      console.error(error);
    }
  }
  };

  const handleDeleteEvent = async () => {
    try {
      const tokenObject = await db.tokens.toCollection().first();
      if (!tokenObject) throw new Error('No token found');
      const token = tokenObject.token;
      const response = await fetch(`${config.MY_API}api/event-silx`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        body: JSON.stringify({
          id: eventForm.id,
        }),
      });

      const responseData = await response.json();
      if (!response.ok || !responseData.success) {
        throw new Error('Event delete failed: ' + (responseData.message || 'Unknown error'));
      }

      console.log('Event deleted successfully:', responseData.message);
      setVisible(false);
      fetchEvents();
      clearEventForm();
    } catch (error) {
      console.error('Error deleting event:', error);
    }
  };


  const sidebarHeader = (
    <h2 className="offcanvas-title" id="addEventSidebarLabel">
      Görev Atama
    </h2>
  );

  return (
    <div id="layout-wrapperx" className="layout-wrapper layout-navbar-full layout-horizontal layout-without-menu">
        <Toast ref={toast} />
    <Navbar />
    <div id="content-wrapperx" className="layout-container">
      <Header />
      <div className="layout-page">
          <div className="container-xxl flex-grow-1 container-p-y">
            <div className="card pekstra app-calendar-wrapper">
              <div className="row g-0">
                <div className={`col app-calendar-sidebar`} id="app-calendar-sidebar">
                  <div className="border-bottom p-4g my-sm-0 mb-3 mx-3">
                    <div className="d-grid">
                
                      <Button label="Görev Ekle" icon="pi pi-plus" onClick={resetFormAndShow} />
                    </div>
                  </div>
                  <div className="mb-3 ms-3">
                    <small className="text-small text-muted text-uppercase align-middle">Filter</small>
                  </div>
                  <div className="border-bottom mx-3">
                    <div className="filter-section mt-3">
                      <select className="form-select" value={selectedCategory} onChange={handleCategoryChange}>
                        <option value="all">Hepsini Göster</option>
                        <option value="AHMET ALKAC">AHMET ALKAC</option>
                        <option value="ADEM ILKAY TUNCEL">ADEM ILKAY TUNCEL</option>
                        <option value="CAVIT PILAVCIOGLU">CAVIT PILAVCIOGLU</option>
                        <option value="ERAY YAVUZASLAN">ERAY YAVUZASLAN</option>
                        <option value="ERDEM AGBAS">ERDEM AGBAS</option>
                        <option value="KENAN ISEN">KENAN ISEN</option>
                        <option value="OGUZ SAN">OGUZ SAN</option>
                        <option value="OZAN CIKAR">OZAN CIKAR</option>
                        <option value="OMER FARUK ATMACA">OMER FARUK ATMACA</option>
                        <option value="SENCERHAN AVCI">SENCERHAN AVCI</option>
                        <option value="SAHIN HACIMINASOGLU">SAHIN HACIMINASOGLU</option>
                      

                      </select>
                    </div>
                  </div>
                </div>
                <div className="col app-calendar-content">
                  <div className="card pekstra shadow-none border-0">
                    <div className="card-body">
                    <FullCalendar
  ref={calendarRef}
  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
  initialView="dayGridMonth"
  allDaySlot={false}
  displayEventTime={false}
  eventLimit={false}
  eventDrop={handleEventDrop}
  eventTextColor="#000000"
  headerToolbar={{
    left: 'prev,next today',
    center: 'title',
    right: 'dayGridMonth,listMonth',
  }}
  locale={trLocale}
  events={getFilteredEvents()}
  editable={true}
  selectable={true}
  selectMirror={true}
  dayMaxEvents={false}
  eventClick={handleEventClick} // Bu satırı ekleyin
/>
                      <div className="app-overlay"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Sidebar header={sidebarHeader} visible={visible} position="right" onHide={() => setVisible(false)} className="w-full md:w-20rem lg:w-30rem">
              <div className="offcanvas-body pt-0" data-select2-id="7">
              <form className="event-form pt-0 fv-plugins-bootstrap5 fv-plugins-framework" id="eventForm" onSubmit={handleFormSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Label>Eğitmenler</Form.Label>
                    <select className="form-control" value={eventForm.person} onChange={handleEventFormChange} name="person">
                      <option value="">Eğitmen Seçin</option>
                      <option key="AHMET ALKAC" value="AHMET ALKAC">
                        AHMET ALKAC
                      </option>
                      <option key="ADEM ILKAY TUNCEL" value="ADEM ILKAY TUNCEL">
                        ADEM ILKAY TUNCEL
                      </option>
                      <option key="CAVIT PILAVCIOGLU" value="CAVIT PILAVCIOGLU">
                        CAVIT PILAVCIOGLU
                      </option>
                      <option key="ERAY YAVUZASLAN" value="ERAY YAVUZASLAN">
                        ERAY YAVUZASLAN
                      </option>
                      <option key="ERDEM AGBAS" value="ERDEM AGBAS">
                        ERDEM AGBAS
                      </option>
                      <option key="KENAN ISEN" value="KENAN ISEN">
                        KENAN ISEN
                      </option>
                      <option key="OGUZ SAN" value="OGUZ SAN">
                        OGUZ SAN
                      </option>
                      <option key="OZAN CIKAR" value="OZAN CIKAR">
                        OZAN CIKAR
                      </option>
                      <option key="OMER FARUK ATMACA" value="OMER FARUK ATMACA">
                        OMER FARUK ATMACA
                      </option>
                      <option key="SENCERHAN AVCI" value="SENCERHAN AVCI">
                        SENCERHAN AVCI
                      </option>
                      <option key="SAHIN HACIMINASOGLU" value="SAHIN HACIMINASOGLU">
                        SAHIN HACIMINASOGLU
                      </option>
                    </select>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Görev Açıklaması</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="OMM - Ofis Görevi ..."
                      value={eventForm.title}
                      onChange={handleEventFormChange}
                      name="title"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Başlangıç Tarihi</Form.Label>
                    <Calendar
                      value={eventForm.start}
                      onChange={e => setEventForm(prevState => ({ ...prevState, start: e.value }))}
                      dateFormat="dd/mm/yy"
                      name="start"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Bitiş Tarihi</Form.Label>
                    <Calendar
                      value={eventForm.end}
                      onChange={e => setEventForm(prevState => ({ ...prevState, end: e.value }))}
                      dateFormat="dd/mm/yy"
                      name="end"
                    />
                  </Form.Group>
                  <div className="text-center">
  {isUpdating ? (
   <>
   <Button type="button" label="Güncelle" icon="pi pi-check" onClick={handleUpdateSubmit} />
   <br /> <br /> <br />
   <Button type="button" label="Sil" severity="danger" icon="pi pi-trash" onClick={handleDeleteEvent} />
 </>
  ) : (
    <Button type="submit" label="Kaydet" icon="pi pi-check" />
  )}
</div>
                </form>
              </div>
            </Sidebar>
            <div className="mb-5"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MonthlyPlanning;
