import React, { useState } from 'react';
import * as XLSX from 'xlsx';

function ExcelViewer() {
  const [data, setData] = useState([]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const workbook = XLSX.read(e.target.result, { type: 'binary' });

      // Verileri almak için örnek kod
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      setData(jsonData);
    };
    reader.readAsBinaryString(file);
  };

  const handleValueChange = (rowIndex, columnIndex, newValue) => {
    const updatedData = [...data];
    updatedData[rowIndex][columnIndex] = newValue;
    setData(updatedData);
  };

  return (
    <div>
      <input type="file" onChange={handleFileChange} />
      {data.length > 0 && (
        <table>
          <thead>
            <tr>
              {data[0].map((cell, index) => (
                <th key={index}>{cell}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.slice(1).map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <td key={cellIndex}>
                    <input
                      type="text"
                      value={cell}
                      onChange={(e) =>
                        handleValueChange(rowIndex, cellIndex, e.target.value)
                      }
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default ExcelViewer;
