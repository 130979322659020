import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../components/header';
import Navbar from '../components/Navbar';
import db from '../components/GlobalDb';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown, Modal, Form } from 'react-bootstrap';
import { IconDots, IconTrash, IconEdit, IconEye, IconLoader2, IconCircleArrowUpRightFilled } from '@tabler/icons-react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import 'primereact/resources/themes/saga-blue/theme.css'; // Tema
import 'primereact/resources/primereact.min.css'; // core css
import 'primeicons/primeicons.css'; // İkonlar
import config from '../config';
import { Toast } from 'primereact/toast';
import ReactQuill from 'react-quill';
import { Paginator } from 'primereact/paginator';
import { set } from 'date-fns';
import Flag from 'react-world-flags'
import { Editor } from 'primereact/editor';
import { TabView, TabPanel } from 'primereact/tabview';

const YeniEgitim = () => {
  const { id } = useParams();
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [visibleDialogs, setVisibleDialogs] = useState({});
  const [show, setShow] = useState(false);
  const [baslik, setbaslik] = useState('');
  const [sunumurl, setsunumurl] = useState('');
  const [sunumurleng, setsunumurleng] = useState('');
  const [baslikcat, setbaslikcat] = useState('tur');
  const [egitimAciklama, setEgitimAciklama] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [currentCourse, setCurrentCourse] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [coursesPerPage] = useState(10);
  const indexOfLastCourse = currentPage * coursesPerPage;
  const indexOfFirstCourse = indexOfLastCourse - coursesPerPage;
  const currentCourses = courses ? courses.slice(indexOfFirstCourse, indexOfLastCourse) : [];
  const [dialogShown, setDialogShown] = useState(false); // Dialog gösterim durumu için yeni state
  const toast = useRef(null);
  const [selectedEgitimler, setSelectedEgitimler] = useState([]);
  const [egitimListesi, setEgitimListesi] = useState([]);
  const [egitimIdIsimMap, setEgitimIdIsimMap] = useState({});
  const [difficulty, setDifficulty] = useState(''); // Initialize with an empty string or a default value
  const [soruTipi, setSoruTipi] = useState("klasik"); // Kullanıcının seçtiği soru tipini saklar
  const [secenekler, setSecenekler] = useState(["", "", "", ""]);
  const [soruCozumu, setSoruCozumu] = useState('');
  const [dogruCevap, setDogruCevap] = useState('');


  const handleSoruTipiChange = (value) => {
    setSoruTipi(value);
  };



  // Bir seçeneği günceller
  const handleSeçenekChange = (index, value) => {
    const updatedOptions = [...secenekler];
    updatedOptions[index] = value;
    setSecenekler(updatedOptions); // Corrected to use setSecenekler instead of setsecenekler
  };


  const [text, setText] = useState('');

  const isEmptyContent = (htmlString) => {
    const doc = new DOMParser().parseFromString(htmlString, "text/html");
    return !doc.body.textContent.trim() && !doc.querySelector('img');
  };

  const handleEgitimChange = (e) => {
    // Kullanıcının seçtiği öğeyi al ve state'i güncelle
    // Eğer kullanıcı bir öğe seçerse, yalnızca bu öğeyi içeren bir diziye ayarla
    const selectedValue = e.value[e.value.length - 1] ? [e.value[e.value.length - 1]] : [];
    setSelectedEgitimler(selectedValue);
  };

  useEffect(() => {
    const egitimIdIsimMap = {};
    egitimListesi.forEach(egitim => {
      egitimIdIsimMap[egitim.value] = egitim.label;
    });
    setEgitimIdIsimMap(egitimIdIsimMap);
  }, [egitimListesi]);

  useEffect(() => { // useEffect hook'unu ekliyoruz.
    fetchCourses();
  }, []); // Boş dizi, bu efektin yalnızca bileşen mount edildiğinde çalıştırılması gerektiğini belirtir.

  useEffect(() => {
    fetchEgitimListesi();
  }, []);

  const getKategoriName = (kategoriId) => {
    return egitimIdIsimMap[kategoriId] || 'Bilinmeyen';
  };

  const fetchEgitimListesi = async () => {
    try {
      const tokenObject = await db.tokens.toCollection().first();
      if (!tokenObject) {
        throw new Error('Token bulunamadı.');
      }
      const token = tokenObject.token;
      const response = await fetch(`${config.MY_API}api/sunum-listele`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      });
      const data = await response.json();
      if (!data.success) {
        throw new Error('Eğitim listesi alınırken bir hata oluştu.');
      }
      // Eğitim listesini MultiSelect formatına çevir
      const egitimOptions = data.message.map(egitim => ({
        label: egitim.adi,
        value: egitim.id,
      }));
      setEgitimListesi(egitimOptions);
    } catch (error) {
      console.error('An error occurred:', error.message);
    }
  };


  const fetchCourses = async () => {
    setIsLoading(true);
    try {
      // Öncelikle, token kontrolü yapılıyor
      const tokenObject = await db.tokens.toCollection().first();
      if (!tokenObject) {
        throw new Error('Token bulunamadı.');
      }
      const token = tokenObject.token;
      // Kurs listesini çekiyoruz
      const response = await fetch(`${config.MY_API}api/soru-listele`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      });
      const data = await response.json();
      if (!data.success) {
        throw new Error('Eğitim listesi alınırken bir hata oluştu.');
      }
      if (data.message.length === 0) {
        setCourses([]);
      } else {
        // Sunum adlarını eklemek için ek bir adım gerekiyor
        await fetchEgitimListesi(); // Eğitim listesini güncellemek için çağırıyoruz
        const updatedCourses = data.message.map(course => {
          const relatedSunum = egitimListesi.find(sunum => sunum.value === course.grupCat);
          return {
            ...course,
            sunumAdi: relatedSunum ? relatedSunum.label : 'Bilinmeyen' // Eğer eşleşme bulunursa, sunum adını ekliyoruz
          };
        });
        setCourses(updatedCourses);
      }
    } catch (error) {
      console.error('An error occurred:', error.message);
      setCourses(false);
    } finally {
      setIsLoading(false);
    }
  };


  const handleClose = () => {
    setShow(false);
    // Fetch notes again when the modal is closed
    resetForm();
  };


  const handleShow = () => {
    setShow(true);

  };
  const handleSubmit = async () => {
    if (!baslik.trim()) {
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Title cannot be empty.', life: 3000 });
      return;
    }

    if (selectedEgitimler.length === 0) {
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'At least one training program must be selected.', life: 3000 });
      return; // Fonksiyonu burada sonlandır
    }

    if (!difficulty.trim()) {
      toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Zorluk seviyesi seçimi zorunludur.', life: 3000 });
      return;
    }
  
    // Soru tasarımı boş kontrolü
    if (isEmptyContent(text)) {
      toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Soru tasarımı boş bırakılamaz.', life: 3000 });
      return;
    }
    // Soru çözümü boş kontrolü
    if (isEmptyContent(soruCozumu)) {
      toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Soru çözümü boş bırakılamaz.', life: 3000 });
      return;
    }
    // Doğru cevap boş kontrolü
    if (!dogruCevap.trim()) {
      toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Doğru cevap boş bırakılamaz.', life: 3000 });
      return;
    }

      // Çoktan seçmeli soru tipi için seçeneklerin kontrolü
  if (soruTipi === "coktan-secmeli" && secenekler.some(secenek => !secenek.trim())) {
    toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Çoktan seçmeli soru için tüm seçenekler doldurulmalıdır.', life: 3000 });
    return;
  }


    // Convert selected training IDs to a comma-separated string
    const selectedEgitimlerIds = selectedEgitimler.join(',');

    try {
      const tokenObject = await db.tokens.toCollection().first();
      if (!tokenObject) {
        throw new Error('Token not found.');
      }
      const token = tokenObject.token;
      const response = await fetch(`${config.MY_API}api/soru-kaydet`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        body: JSON.stringify({
          baslik,
          soruCat: selectedEgitimler.join(','),
          difficulty,
          soruTipi,
          soruCozumu,
          dogruCevap,
          soruTxt: text,
          secenekler: soruTipi === 'coktan-secmeli' ? secenekler : undefined, // Burada çoktan seçmeli soru tipi için seçenekleri ekleyin
      
        }),
      });
      const data = await response.json();

      if (!data.success) {
        throw new Error('Error occurred while saving the training.');
      }
      // On success
      setShow(false); // Close the modal
      fetchCourses(); // Refresh the course list
      fetchEgitimListesi();
    } catch (error) {
      console.error('An error occurred:', error.message);
      // Show error message to the user
      toast.current.show({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 });
    }
    resetForm();
  };



  const toggleDialogVisibility = (courseId) => {
    setVisibleDialogs(prevState => ({
      ...prevState,
      [courseId]: !prevState[courseId]
    }));
  };




  const deleteCourse = (baslikId) => {



    confirmDialog({
      message: 'Bu gruplamayı silmek istediğinize emin misiniz?',
      header: 'Silme Onayı',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        performDelete(baslikId);

      },
      reject: () => {
        performclamn(baslikId);
      }
    });

  };



  const performclamn = async (baslikId) => {
    fetchCourses();
    fetchEgitimListesi();



  };

  const performDelete = async (baslikId) => {
    const tokenObject = await db.tokens.toCollection().first();
    if (!tokenObject) {
      throw new Error('Token bulunamadı.');
    }
    const token = tokenObject.token;
    try {

      const response = await fetch(`${config.MY_API}api/soru-silx`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token, // Token'ınızı buraya ekleyin
        },
        body: JSON.stringify({ soruId: baslikId }),
      });
      const data = await response.json();
      if (data.success) {
        // Silme işlemi başarılı
   
        fetchCourses(); // Eğitim listesini yeniden çek
        fetchEgitimListesi();
      } else {
        // Silme işlemi başarısız
        console.error('Eğitim silinirken bir hata oluştu.');
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };


  const handleUpdate = async () => {
    if (!baslik.trim()) {
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Title cannot be empty.', life: 3000 });
      return;
    }

    if (selectedEgitimler.length === 0) {
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'At least one training program must be selected.', life: 3000 });
      return; // Fonksiyonu burada sonlandır
    }

    if (!difficulty.trim()) {
      toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Zorluk seviyesi seçimi zorunludur.', life: 3000 });
      return;
    }
  
    // Soru tasarımı boş kontrolü
    if (isEmptyContent(text)) {
      toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Soru tasarımı boş bırakılamaz.', life: 3000 });
      return;
    }
    // Soru çözümü boş kontrolü
    if (isEmptyContent(soruCozumu)) {
      toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Soru çözümü boş bırakılamaz.', life: 3000 });
      return;
    }
    // Doğru cevap boş kontrolü
    if (!dogruCevap.trim()) {
      toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Doğru cevap boş bırakılamaz.', life: 3000 });
      return;
    }

      // Çoktan seçmeli soru tipi için seçeneklerin kontrolü
  if (soruTipi === "coktan-secmeli" && secenekler.some(secenek => !secenek.trim())) {
    toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Çoktan seçmeli soru için tüm seçenekler doldurulmalıdır.', life: 3000 });
    return;
  }

    // Convert selected training IDs to a comma-separated string for updating
    const selectedEgitimlerIds = selectedEgitimler.join(',');

    try {
      const tokenObject = await db.tokens.toCollection().first();
      if (!tokenObject) {
        throw new Error('Token not found.');
      }
      const token = tokenObject.token;
      const response = await fetch(`${config.MY_API}api/soru-duzenlex`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        body: JSON.stringify({
          baslikId: currentCourse.id,
          baslik,
          soruCat: selectedEgitimler.join(','),
          difficulty,
          soruTipi,
          soruCozumu,
          dogruCevap,
          soruTxt: text,
          secenekler: soruTipi === 'coktan-secmeli' ? secenekler : undefined, // Burada çoktan seçmeli soru tipi için seçenekleri ekleyin
      
        }),
      });
      const data = await response.json();
      if (!data.success) {
        throw new Error('Error occurred while updating the training.');
      }
      setShow(false); // Close the modal
      fetchCourses(); // Refresh the course list
      fetchEgitimListesi();
    } catch (error) {
      console.error('An error occurred:', error.message);
    }
  };

  const handleEdit = (course) => {
    setCurrentCourse(course);
    setIsEdit(true);
    setbaslik(course.adi);
    setsunumurl(course.jsonx);
    setbaslik(course.soruadi); // Başlığı ayarla
    setDifficulty(course.soruzorluk); // Zorluk seviyesini ayarla
    setSoruTipi(course.sorutip); // Soru tipini ayarla
    setText(course.sorutxt); // Soru tasarımını ayarla

    setSoruCozumu(course.sorucozum); // Soru çözümünü ayarla
    setDogruCevap(course.sorucevap); // Doğru cevabı ayarla

    let seceneklerDizisi;
    try {
        // JSON string'i parse ederek JavaScript dizisine dönüştür
        seceneklerDizisi = JSON.parse(course.secenekler);
    } catch(e) {
        // Eğer parse işlemi başarısız olursa veya secenekler boş/undefined ise, varsayılan bir dizi kullan
        seceneklerDizisi = ["", "", "", ""];
    }
    
    // Dönüştürülen veya varsayılan diziyi setSecenekler ile ayarla
    setSecenekler(seceneklerDizisi);





    // 'programlar' değerinin null veya undefined olup olmadığını kontrol et
    // Ve güvenli bir şekilde işle.
    const programIds = course.sorucat ? course.sorucat.split(',') : [];

    setSelectedEgitimler(programIds);
    setShow(true);
  };
  const resetForm = () => {
    setbaslik('');
    setbaslikcat('');
    setSelectedEgitimler([]);
    setsunumurleng('');
    setsunumurl('');
    setEgitimAciklama('');

    setIsEdit(false);
    setCurrentCourse(null);

    setbaslik('');
    setsunumurl('');
    setsunumurleng('');
    setbaslikcat('');
    setEgitimAciklama('');
    setSelectedEgitimler([]);

 
    setDifficulty('');
    setSoruTipi("klasik");
    setSecenekler(["", "", "", ""]);
    setSoruCozumu('');
    setDogruCevap('');
    setText('');










  };
  console.log("selectedEgitimler:", selectedEgitimler);
  console.log("egitimListesi:", egitimListesi);
  return (
    <div id="layout-wrapperx" className="layout-wrapper layout-navbar-full layout-horizontal layout-without-menu">
      <Toast ref={toast} />
      <Navbar />
      <div id="content-wrapperx" className="layout-container">
        <Header />
        <div className="layout-page">
          <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
              <div className="row mb-4 g-4">
                <div className="col-12 col-xl-12 col-md-12">
                  <Card>
                    <div className="card-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <h2>Sorular</h2>
                      <Button className='mb-2' icon="pi pi-plus" severity="secondary" onClick={handleShow} text raised />
                    </div>
                    {isLoading ? (
                      <p>Yükleniyor... &nbsp;&nbsp;<IconLoader2 className="spin" size={20} /></p>
                    ) : courses && courses.length > 0 ? (
                      <div className="table-responsive text-nowrap">
                        <table className="table table-striped">
                          <thead className="table-light">
                            <tr>
                              <th>Başlık</th>
                              <th>Sunum</th>
                              <th><center>İşlemler</center></th>
                            </tr>
                          </thead>
                          <tbody className="table-border-bottom-0">
                            {currentCourses.map(course => (
                              <tr key={course.id}>
                                <td>{course.soruadi}</td>
                                <td>{getKategoriName(course.sorucat)}</td>
                                <td><center>
                                  <Dropdown>
                                    <Dropdown.Toggle variant="link" id="dropdown-basic">
                                      <IconDots size={32} stroke={1} />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      <Dropdown.Item onClick={() => handleEdit(course)}>
                                        <IconEdit size={22} stroke={2} /> Düzenle
                                      </Dropdown.Item>

                                      <Dropdown.Item onClick={() => deleteCourse(course.id)}>
                                        <IconTrash size={22} stroke={2} />  Sil
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </center></td>

                                <ConfirmDialog />
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <Paginator
                          first={indexOfFirstCourse}
                          rows={coursesPerPage}
                          totalRecords={courses ? courses.length : 0}
                          onPageChange={(e) => setCurrentPage(e.page + 1)}
                        />
                      </div>
                    ) : (
                      <p>
                        Eğitim sunumlarınız, kendilerine Hogwarts'tan kabul mektubu gelmiş gibi ansızın ortadan kayboldular! Yeni bir sunum eklemek için + işaretini kullanın.  <IconCircleArrowUpRightFilled size={22} stroke={2} /> </p>
                    )}
                  </Card>
                </div>
              </div>
            </div>
            <div className='mb-5'></div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{isEdit ? 'Soru Düzenleme' : 'Yeni Soru'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              isEdit ? handleUpdate() : handleSubmit();
            }}
          >
            <TabView>
              <TabPanel header="Temel Bilgiler">
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Label>Başlık</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Başlık girin"
                      value={baslik}
                      onChange={(e) => setbaslik(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Dahil olunan sunum</Form.Label><br />
                    <MultiSelect
                      selectionLimit={1}
                      maxSelectedLabels={1}
                      showSelectAll={false}
                      value={selectedEgitimler}
                      options={egitimListesi}
                      optionLabel="label"
                      optionValue="value"
                      onChange={handleEgitimChange}
                      placeholder="Soruyu hangi sunumda göstermek istersiniz?"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Zorluk Seviyesi</Form.Label>
                    <Form.Select
                      value={difficulty}
                      onChange={(e) => setDifficulty(e.target.value)}
                      aria-label="Soru Zorluk Seviyesi Seçimi"
                    >
                      <option value="">Zorluk Seviyesi Seçin</option>
                      <option value="easy">Kolay</option>
                      <option value="medium">Orta</option>
                      <option value="hard">Zor</option>
                    </Form.Select>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Soru Tasarımı</Form.Label><br />
                    <Editor value={text} onTextChange={(e) => setText(e.htmlValue)} style={{ height: '320px' }} />
                  </Form.Group>
                </Form>
              </TabPanel>

              {/* İkinci sekme: Soru Tipi ve secenekler */}

              <TabPanel header="Soru Tipi ve secenekler">
                <Form>
                  {/* Soru Tipi Seçimi */}
                  <Form.Group className="mb-3">
                    <Form.Label>Soru Tipi</Form.Label>
                    <Form.Select
                      value={soruTipi}
                      onChange={(e) => handleSoruTipiChange(e.target.value)}
                      aria-label="Soru Tipini Seçin"
                    >
                      <option value="klasik">Klasik</option>
                      <option value="coktan-secmeli">Çoktan Seçmeli</option>
                    </Form.Select>
                  </Form.Group>

                
                  {
  soruTipi === "coktan-secmeli" && secenekler.map((secenek, index) => (
    <div key={index} className="input-group mb-2">
      <span className="input-group-text">{String.fromCharCode(65 + index)}</span>
      <input
        type="text"
        className="form-control"
        placeholder="Seçenek girin" // Placeholder eklendi
        value={secenek}
        onChange={(e) => handleSeçenekChange(index, e.target.value)}
        aria-label={`Option ${String.fromCharCode(65 + index)}`}
      />
    </div>
  ))
}
                </Form>
              </TabPanel>
              <TabPanel header="Soru Çözümü">
                <Form.Group className="mb-3">
                  <Form.Label>Soru Çözümünü Tasarlayın</Form.Label><br />
                  <Editor value={soruCozumu} onTextChange={(e) => setSoruCozumu(e.htmlValue)} style={{ height: '320px' }} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Sorunun Cevabı</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Çoktan Seçmeli için doğru cevap sıkkını yazın, Klasik için doğru cevabı yazın"
                    value={dogruCevap}
                    onChange={(e) => setDogruCevap(e.target.value)}
                  />
                </Form.Group>
              </TabPanel>
            </TabView>




          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button label="İptal" icon="pi pi-times" severity="danger" onClick={handleClose} raised />

          <Button severity="primary" icon="pi pi-save" label={isEdit ? 'Güncelle' : 'Kaydet'} type="submit" raised onClick={isEdit ? handleUpdate : handleSubmit} />

        </Modal.Footer>
      </Modal>
    </div>
  );


};

export default YeniEgitim;
