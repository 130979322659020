import { useEffect } from 'react';

const useDisableIframePointerEvents = (disable) => {
  useEffect(() => {
    const action = disable ? 'none' : 'auto';
    const iframes = document.querySelectorAll('iframe');
    iframes.forEach(iframe => {
      iframe.style.pointerEvents = action;
    });

    return () => {
      iframes.forEach(iframe => {
        iframe.style.pointerEvents = 'auto';
      });
    };
  }, [disable]);
};

export default useDisableIframePointerEvents;
