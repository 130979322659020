import React, {  useEffect, useState, useRef } from 'react';
import { IconHome, IconBook, IconSchool, IconListCheck, IconMenu2, IconSquareX , IconReplace, IconUsers, IconBook2, IconPointFilled, IconBrandMessenger} from '@tabler/icons-react';
import { scaleRotate as Menuz } from 'react-burger-menu';

import { Menu } from 'primereact/menu';
import { Toast } from 'primereact/toast';
import { Link, useNavigate } from 'react-router-dom';

import 'primeicons/primeicons.css';


const MySidebar = ({ isOpen, handleMenuToggle }) => {
  const [internalIsOpen, setInternalIsOpen] = useState(!!isOpen); // Convert isOpen to boolean
  const navigate = useNavigate();
  useEffect(() => {
    setInternalIsOpen(!!isOpen); // Convert isOpen to boolean
  }, [isOpen]);

  const internalHandleMenuToggle = () => {
    setInternalIsOpen(!internalIsOpen);
    if (handleMenuToggle) {
      handleMenuToggle(!internalIsOpen);
    }
  };


  const toast = useRef(null);
  const items = [
      {
          label: 'Eğitim Yönetimi',
          items: [
              {
                  label: 'Eğitim Programları',
                  icon: 'pi pi-angle-right',
                  command: () => { navigate('/yeni-egitim'); }
              },
              {
                  label: 'Eğitim Başlıkları',
                  icon: 'pi pi-angle-right',
                  command: () => { navigate('/yeni-baslik'); }
              }
          ]
      },
      {
          label: 'Sunum Yönetimi',
          items: [
              {
                  label: 'Sunumlar',
                  icon: 'pi pi-angle-right',
                  command: () => { navigate('/sunumlar'); }
              },
              {
                label: 'Sunum Dökümanları',
                icon: 'pi pi-angle-right',
                command: () => { navigate('/sunum-docs'); }
            },
            {
              label: 'Soru Sistemi',
              icon: 'pi pi-angle-right',
              command: () => { navigate('/soru-sistemi'); }
          },
          
              {
                label: 'Revize Bildirimleri',
                icon: 'pi pi-refresh'
            }
          ]
      },
   
 
    {
      label: 'Sistem Yönetimi',
      items: [
          {
              label: 'Kullanıcı Yönetimi',
              icon: 'pi pi-users',
              command: () => { navigate('/kullanici-yonetimi'); }
          }
       
      ]
  }
  ];



  return (
    <Menuz
      id="sidebarPanel"
      className={`offcanvas offcanvas-start ${internalIsOpen ? 'show' : ''}`}
      isOpen={internalIsOpen}
      customBurgerIcon={false}
      customCrossIcon={false}
      pageWrapId={"content-wrapperx"}
      outerContainerId={"layout-wrapperx"}
      animation="scaleRotate"
      onClose={internalHandleMenuToggle}
    >


      <div className="offcanvas-body bm-open">

        <div className="profileBox">
          <div className="image-wrapper">

          </div>
          <div className="in">
            <strong>LESS Eğitmen Paneli</strong>
            <div className="text-muted">
  <span className="white-text"><b>L</b></span>M <span className="white-text"><b>E</b></span>DUCATION <span className="white-text"><b>S</b></span>YSTEM v1.4
</div>
          </div>
          <span onClick={internalHandleMenuToggle} className='close-sidebar-button close-pointer'>
            <div className="icon-inner">
              <IconSquareX size={32} stroke={2} />
            </div>
          </span>
        </div>


        <div className="flex justify-content-center">
            <Toast ref={toast} />
            <Menu model={items} />
        </div>



      </div>

      <div className="sidebar-buttons">
        <a href="#" className="button">
          <ion-icon name="person-outline"></ion-icon>
        </a>
        <a href="#" className="button">
          <ion-icon name="archive-outline"></ion-icon>
        </a>
        <a href="#" className="button">
          <ion-icon name="settings-outline"></ion-icon>
        </a>
        <a href="#" className="button">
          <ion-icon name="log-out-outline"></ion-icon>
        </a>
      </div>




    </Menuz>
  );
};





const Navbar = () => {
 const [menuOpen, setMenuOpen] = useState(false);
 const handleMenuToggle = (isOpen) => {
  console.log("Menu toggled");
  setMenuOpen(isOpen);
  window.scrollTo({
    top: 0,
    behavior: 'smooth', // Opsiyonel: Animasyonlu bir şekilde kaydırma
  });
};
  useEffect(() => {
    // Ensure the state is updated after the component re-renders
    setMenuOpen(menuOpen);
  }, [menuOpen]);


  return (
    <div>
      <div className="appBottomMenu">
        
  
        <Link className="item" to="/">
          <div className="col">
            <IconHome size={32} />
          </div>
          </Link>
        <a href="#" className="item">
          <div className="col">
            <IconSchool size={32} />

          </div>
        </a>
        <a href="#" className="item">
          <div className="col">
            <IconListCheck size={32} />
            <span className="badge badge-danger">5</span>
          </div>
        </a>
        <Link className="item" to="/aylik" >
        
          <div className="col">
            <IconBook size={32} />
          </div>
    
        </Link>

        <span  className="item" data-bs-toggle="offcanvas">
          <div className="col">
            <button
              className="navbar-toggler border-0 px-0 me-2"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={handleMenuToggle}
            >
              <IconMenu2 size={32} stroke={3} />
            </button>
          </div>
        </span>
      </div>

      <MySidebar isOpen={menuOpen} handleMenuToggle={handleMenuToggle} />

      {/* Content backdrop */}

    </div>
  );
};

export default Navbar;
