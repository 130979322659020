import { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { jwtDecode } from 'jwt-decode';
import db from '../components/GlobalDb';
import { IconSquareRoundedPlusFilled, IconPlus, IconBulb, IconX, IconDots, IconSettings, IconTrash } from '@tabler/icons-react';
import { Modal, Form } from 'react-bootstrap';  // Import Modal and Button from Bootstrap
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import config from '../config';
import 'primereact/resources/themes/saga-blue/theme.css'; // Tema
import 'primereact/resources/primereact.min.css'; // core css
import 'primeicons/primeicons.css'; // İkonlar
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import Flag from 'react-world-flags'




const MySwal = withReactContent(Swal);



export default function CreateClassroom() {
  const [className, setClassName] = useState('');
  const [classCreationError, setClassCreationError] = useState(null);
  const [myCourses, setMyCourses] = useState([]);
  const [showModal, setShowModal] = useState(false);  // State to control the modal visibility
  const [egitimListesi, setEgitimListesi] = useState([]);
  const [selectedEgitimId, setSelectedEgitimId] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [selectedEgitim, setSelectedEgitim] = useState({});
  const [eventForm, setEventForm] = useState({ person: '', title: '', start: '', end: '' });

  const languageOptions = [
    { label: 'Türkçe', value: 'tur' },
    { label: 'İngilizce', value: 'usa' },
    // Diğer dilleri buraya ekleyin
  ];

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const fetchMyCourses = async () => {
    try {
      const tokenObject = await db.tokens.toCollection().first();
      if (tokenObject) {
        const token = tokenObject.token;
  
        const response = await fetch(`${config.MY_API}api/mycourses`, {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });
  
        const responseData = await response.json();
  
        if (response.ok && responseData.success) {
          setMyCourses(responseData.message);
        } else if (response.ok && !responseData.success && responseData.message === "Veritabanında Kayıt Bulunamadı.") {
          // Eğer veritabanında kayıt bulunamadıysa, bu durumu özel ele al
          // Kurs listesini boş bir array olarak ayarla
          setMyCourses([]);
          // İlgili kullanıcı mesajı gösterebilirsiniz veya bir state'i bu duruma özel güncelleyebilirsiniz
        } else {
          // Diğer hataları ele al
          throw new Error(responseData.message || 'Error fetching courses');
        }
      } else {
        throw new Error('No token found');
      }
    } catch (error) {
      setClassCreationError(error.message);
    }
  };
  


  const handleLanguageSelection = (e) => {
    setSelectedLanguage(e.target.value);
  };


  useEffect(() => {
    fetchMyCourses();
    fetchEgitimListesi();
  }, []);

  const fetchEgitimListesi = async () => {
    try {
      const tokenObject = await db.tokens.toCollection().first();
      if (!tokenObject) {
        throw new Error('Token bulunamadı.');
      }
      const token = tokenObject.token;
      const response = await fetch(`${config.MY_API}api/egitim-listele`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      });
      const data = await response.json();
      if (!data.success) {
        throw new Error('Eğitim listesi alınırken bir hata oluştu.');
      }
      // Eğitim listesini MultiSelect formatına çevir
      const egitimOptions = data.message.map(egitim => ({
        label: egitim.adi,
        value: egitim.id,
      }));
      setEgitimListesi(egitimOptions);
    } catch (error) {
      console.error('An error occurred:', error.message);
    }
  };

  const navigate = useNavigate();

  const handleClassroomClick = (classroomId) => {
    // Navigate to the specific classroom based on its ID
    const url = `/manage-course/${classroomId}`;
    navigate(url);
  };

  const handleEgitimSelection = (e) => {
    const selectedValue = e.target.value;
    setSelectedEgitimId(selectedValue); // Bu satır eklendi
  
    const selectedOption = egitimListesi.find(option => option.value === selectedValue);
    setSelectedEgitim(selectedOption || {});
  };

  const handleClassCreation = async () => {
    try {
      const tokenObject = await db.tokens.toCollection().first();
      if (tokenObject) {
        const token = tokenObject.token;

        const response = await fetch(`${config.MY_API}api/newclassroom`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
          body: JSON.stringify({
            egitimId: selectedEgitim.value, // Seçilen eğitimin ID'si
            egitimAdi: selectedEgitim.label, // Seçilen eğitimin adı
   
          }),
        });

        const responseData = await response.json();

        if (response.ok && responseData.success) {
          // Handle successful classroom creation
          console.log('Classroom created successfully');
          // After creating the classroom, fetch the updated list of courses
          fetchMyCourses();
        } else {
          throw new Error(responseData.message || 'Error creating classroom');
        }
      } else {
        throw new Error('No token found');
      }
    } catch (error) {
      setClassCreationError(error.message);
    }
  };


  const handleAddEvent = async () => {
    try {
      const tokenObject = await db.tokens.toCollection().first();
      if (tokenObject) {
        const token = tokenObject.token;
        const response = await fetch(`${config.MY_API}api/events-kaydet`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
          body: JSON.stringify({
            person: eventForm.person,
            title: eventForm.title,
            start: eventForm.start,
            end: eventForm.end,
          }),
        });
  
        const responseData = await response.json();
        if (response.ok && responseData.success) {
          console.log('Event saved successfully:', responseData.message);
          // Fetch events again after saving new event
          fetchMyCourses();
        } else {
          console.error('Error saving event:', responseData.message || 'Unknown error');
        }
      } else {
        throw new Error('No token found');
      }
    } catch (error) {
      console.error('Error adding event:', error);
    }
  };


  const handleClassroomDelete = (classroomId) => {
    MySwal.fire({
      title: 'Emin misiniz?',
      text: 'Eğitimi silmek istediğinize emin misiniz?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Evet',
      cancelButtonText: 'İptal',
    }).then((result) => {
      if (result.isConfirmed) {
        // If user clicks "Evet, sil!", perform the delete action
        performDeleteAction(classroomId);
        
      }
    });
  };


  const performDeleteAction = async (classroomId) => {
    try {
      const tokenObject = await db.tokens.toCollection().first();
      if (tokenObject) {
        const token = tokenObject.token;
  
        const response = await fetch(`${config.MY_API}api/egitimsil`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
          body: JSON.stringify({
            egitimid: classroomId,
          }),
        });
  
        const responseData = await response.json();
  
        if (response.ok && responseData.success) {
          // Burada asenkron fonksiyonlar bekleniyor
          await fetchMyCourses(); // Kurs listesini yeniden çek
          await fetchEgitimListesi(); // Eğitim listesini yeniden çek
        } else {
          throw new Error(responseData.message || 'Error deleting classroom');
        }
      } else {
        throw new Error('No token found');
      }
    } catch (error) {
      setClassCreationError(error.message);
    }
  };
  





  const handleModalSave = async () => {
    handleCloseModal();  // Close the modal
    await handleClassCreation();  // Handle classroom creation after closing the modal
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Ay 0'dan başladığı için +1 ekliyoruz
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
  
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  }



  return (

    <Card>
    <div className="card-header mx-2" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <h2>Devam Eden Eğitimlerim</h2>
      <Button className='mb-2' icon="pi pi-plus" severity="secondary" onClick={handleShowModal} text raised />
    </div>

    {/* Eğer eğitim listesi boşsa gösterilecek mesaj */}
    {myCourses.length === 0 ? (
      <div className="mx-2 my-4">
       
        <p>
Sınıfımız, sıcak bir günde bir çöl kadar boş görünüyor! 🌵☀️</p>
      </div>
    ) : (
      <div className="table-responsive text-nowrap mx-2">
        <table className="table">
          <thead className="table-light">
            <tr>
              <th>Eğitim Programı</th>
       
              <th>İşlemler</th>
            </tr>
          </thead>
          <tbody className="table-border-bottom-0">
            {myCourses.map((course) => (
              <tr key={course.id}>
                <td>{course.programadi}<br />{formatDate(course.createdat)}</td>
              
                <td>
                  <Dropdown>
                    <Dropdown.Toggle variant="link" id="dropdown-basic">
                      <IconDots size={32} stroke={1} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => handleClassroomClick(course.id)}>
                        <IconSettings size={15} stroke={1} /> Eğitim Yönetimi
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => handleClassroomDelete(course.id)}>
                        <IconTrash size={15} stroke={1} /> Eğitimi Sil
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Yeni Eğitim</Modal.Title>
        </Modal.Header>
        <Modal.Body>





          <Form.Group className="mb-3">
            <Form.Label>Eğitim Programı Seçin:</Form.Label>
            <select className="form-control" value={selectedEgitimId} onChange={handleEgitimSelection}>
              <option value="">Eğitim Seçiniz</option>
              {egitimListesi.map((egitim) => (
                <option key={egitim.value} value={egitim.value}>
                  {egitim.label}
                </option>
              ))}
            </select>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Eğitim Dili:</Form.Label>
            <select className="form-control" value={selectedLanguage} onChange={handleLanguageSelection}>
              <option value="">Dil Seçiniz</option>
              {languageOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button label="İptal" icon="pi pi-times" severity="danger" onClick={handleCloseModal} raised />

          <Button severity="primary" icon="pi pi-save" label={'Kaydet'} type="submit" raised onClick={handleModalSave} />

        </Modal.Footer>


      </Modal>
    </Card>

  );
}
