import React, { useRef, useEffect, useState } from 'react';
import { ReactSketchCanvas } from 'react-sketch-canvas';
import useDisableIframePointerEvents from './useDisableIframePointerEvents'; // Custom hook'u import et

const DrawingBoard = ({ isDrawingMode, drawingColor }) => {
  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [visible, setVisible] = useState(isDrawing);
  const [undoStack, setUndoStack] = useState([]);
  const [clearCanvasFlag, setClearCanvasFlag] = useState(false);

  useDisableIframePointerEvents(visible);




  useEffect(() => {
    setVisible(isDrawing);
    if (canvasRef.current) {
      canvasRef.current.clearCanvas();
    }
  }, [isDrawing,isDrawingMode]);

  useEffect(() => {
    if (clearCanvasFlag && canvasRef.current) {
      canvasRef.current.clearCanvas();
      setClearCanvasFlag(false); // Reset the flag after clearing canvas
    }
  }, [clearCanvasFlag]);

  const onStartDrawing = () => {
    setIsDrawing(true);
  };

  const onStopDrawing = () => {
    setIsDrawing(false);
  
  };
  

  const handleCanvasDraw = () => {
    if (canvasRef.current) {
      setUndoStack(prevStack => [...prevStack, canvasRef.current.getPaths()]);
    }
  };

  const handleUndo = () => {
    if (canvasRef.current) {
      canvasRef.current.clearCanvas();
      // Update undoStack when undo operation is performed
    }
  };

  return (
    <>
      <ReactSketchCanvas
  ref={canvasRef}
  width={`${window.innerWidth}px`}
  height={`${window.innerHeight}px`}
  strokeWidth={3}
  strokeColor={drawingColor}
  onTouchStart={onStartDrawing}
  onTouchEnd={onStopDrawing} // onTouchEnd'in doğru şekilde bağlandığından emin olun
  svgStyle={{ fillOpacity: 0 }}
  onMouseDown={onStartDrawing}
  onMouseUp={onStopDrawing} // onMouseUp'ın doğru şekilde bağlandığından emin olun
  onDraw={handleCanvasDraw}
  style={{ position: 'fixed', left: '0', top: '0', pointerEvents: isDrawingMode ? 'all' : 'none' }}
/>
    </>
  );
};

export default DrawingBoard;
