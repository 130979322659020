import React, { useState, useEffect } from 'react';
import Draggable from 'react-draggable';
import { ResizableBox } from 'react-resizable';
import 'react-resizable/css/styles.css';
import { IconArrowsDiagonalMinimize, IconSquareRoundedX, IconArrowsMove } from '@tabler/icons-react';
import { Calculator } from 'react-mac-calculator'




const Calculatorx = ({ show, onHide, modalId}) => {
  const [visible, setVisible] = useState(show);

  useEffect(() => {
    setVisible(show);
  }, [show]);

  if (!visible) return null;

  // Özel yeniden boyutlandırma tutamacını tanımla
  const resizeHandle = (
    <span className="custom-resize-handle" />
  );

  const content = (
    <div style={{ position: 'fixed', top: '20%', left: '20%', zIndex: 1050 }}>
        <div className="modal-header bgmy" >
          <h5 className="title bgmy2" style={{ cursor: 'move' }}><IconArrowsMove  size={15} stroke={2} color="#000" /> Calculator</h5>
          <IconSquareRoundedX onClick={() => onHide(modalId)}  size={15} stroke={2} className='bgmy3 clickable' color="#000" />
        </div> 
        <div className="modal-body custom-bb">
        <Calculator />
        </div>

    </div>
  );

  return <Draggable handle=".title">{content}</Draggable>;
};

export default Calculatorx;
