import React, { useState, useEffect, useRef } from 'react';
import MyPdfViewer from '../components/MyPdfViewer';
import { Modal, Button } from 'react-bootstrap';
import { IconEyeQuestion } from '@tabler/icons-react';
import { toast } from 'react-toastify';
import config from '../config';
import { IconArrowUp } from '@tabler/icons-react';



function Question({ id, namex, classroom, onQuestionUpdate }) {
    const [questionDetails, setQuestionDetails] = useState(null);
    const [error, setError] = useState(null);
    const [show, setShow] = useState(false);
    const [answer, setAnswer] = useState('');
    const [showTime, setShowTime] = useState(null);
    const modalContentRef = useRef(null); // Modal içeriği için bir ref oluştur

    const scrollToTopOfModal = () => {
      if(modalContentRef.current) {
        modalContentRef.current.scrollTop = 0;
      }
    };

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        setShowTime(Date.now()); // Görüntüleme zamanını kaydet
    };

    useEffect(() => {
        const fetchQuestion = async () => {
            try {
                const response = await fetch(`${config.MY_API}api/get-question`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ qid: id }),
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                setQuestionDetails(data.message);
            } catch (error) {
                setError(error.message);
            }
        };

        fetchQuestion();
    }, [id]);

    const saveAnswer = async () => {
        if (answer.trim() === '') {
            toast.error("Please write an answer before saving.", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: 'colored',
            });
            return;
        }

        const timeElapsed = Date.now() - showTime; // Milisaniye cinsinden

        try {
            const response = await fetch(`${config.MY_API}api/save-answer`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    qid: id,
                    cevap: answer,
                    user: namex,
                    classroom,
                    timex: timeElapsed,
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to save answer');
            }

            setShow(false); // Modal'ı kapat
            setAnswer(''); // Cevap input'unu temizle
            onQuestionUpdate();
            toast.success("Your answer has been successfully saved. Follow the instructor for results.", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: 'colored',
            });
        } catch (error) {
            alert(error.message);
        }
    };

    if (!questionDetails) {
        return <div>Loading question details...</div>;
    }

 
    return (
        <>
            <div className="card mb-4">
                <h5 className="card-header mb-2">A new question has been assigned.</h5>
                <div className="card-body">
                    <center>
                        <button type="button" onClick={handleShow} className="btn btn-less btn-lg me-1 mt-5 mb-5">
                            <IconEyeQuestion size={32} stroke={2} color="#fff" />&nbsp;View the Question
                        </button>
                    </center>
                </div>
            </div>
            <Modal className="modal fade modalbox" show={show} onHide={handleClose} keyboard={false}>
                <Modal.Header>
                    <Modal.Title>Answer the Question Below 👇   <button type="button" className="btn btn-sm btn-secondary" style={{ position: 'absolute', right: '1rem', top: '10px' }} onClick={scrollToTopOfModal}>
                    <IconArrowUp size={18} stroke={2} />&nbsp; UP
                    </button></Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ display: 'grid' }}  ref={modalContentRef}> 
                    <div className="d-flex flex-column justify-content-center align-items-center text-center mb-5" style={{ height: '100%' }}>
                        {questionDetails.soru_img && (
                            <img src={questionDetails.soru_img} style={{ maxWidth: '100%', height: 'auto' }} alt="Question" />
                        )}
                        <p className='mt-5 mb-5'><b>{questionDetails.soru_text}</b></p>
                        {questionDetails.cevap_1 === 'direct' ? (
                            <div className="form-group boxed">
                                <div className="input-wrapper">
                                    <label className="form-label" htmlFor="name5"><u>Your Answer:</u></label>
                                    <input type="text" className="form-control" id="name5" placeholder="Enter the answer here and save it."
                                        autoComplete="off"
                                        value={answer}
                                        onChange={(e) => setAnswer(e.target.value)}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="answers-container">
                                <form>
                                    {['1', '2', '3', '4'].map((option) => (
                                        <div key={option} className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="answerOptions"
                                                id={`answerOption-${option}`}
                                                value={option}
                                                checked={answer === option}
                                                onChange={(e) => setAnswer(e.target.value)}
                                            />
                                            <label className="form-check-label" htmlFor={`answerOption-${option}`}>
                                                {questionDetails[`cevap_${option}`]}
                                            </label>
                                        </div>
                                    ))}
                                </form>
                            </div>
                        )}
                        {questionDetails.pdf_desc && (
                            <p className='mt-5 mb-10'><b>{questionDetails.pdf_desc}</b></p>
                        )}
                    </div>
                    {questionDetails.pdf_source && (
                        <MyPdfViewer link={questionDetails.pdf_source} />
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={saveAnswer}>Save My Answer</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Question;
