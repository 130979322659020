import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './view/home'
import Login from './view/login'
import Logout from './view/logout'
import CreateClassroom from './view/CreateClassroom'
import ManageCourse from './view/ManageCourse'
import EntegreLir from './components/EntegreLir'
import InstructorScreen from './view/InstructorScreen';
import UserForm from './view/Connect';
import ExcelViewer from './view/excel';
import NewEducation from './view/NewEducation';
import SunumDocs from './view/SunumDocs';
import SoruSistemi from './view/SoruSistemi';
import NewCat from './view/NewCat';
import KullaniciYonetimi from './view/KullaniciYonetimi';
import Sunumlar from './view/Sunumlar';
import AylikPlanlama from './components/AylikPlanlama';
import { ToastContainer } from 'react-toastify';
import { PrimeReactProvider } from 'primereact/api';
import "primereact/resources/themes/lara-light-cyan/theme.css";

const App = () => {
  return (
    <Router>
           <PrimeReactProvider>
          <ToastContainer />
      <Routes>
  
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/aylik" element={<AylikPlanlama />} />
        <Route path="/entegre" element={<EntegreLir />} />
        <Route path="/excel" element={<ExcelViewer />} />
        <Route path="/yeni-egitim" element={<NewEducation />} />
        <Route path="/yeni-baslik" element={<NewCat />} />
        <Route path="/sunumlar" element={<Sunumlar />} />
        <Route path="/sunum-docs" element={<SunumDocs />} />
        <Route path="/kullanici-yonetimi" element={<KullaniciYonetimi />} />
        <Route path="/soru-sistemi" element={<SoruSistemi />} />
        <Route path="/login" element={<Login />} />
        <Route path="/createclassroom" element={<CreateClassroom />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/manage-course/:id" element={<ManageCourse />} />



        <Route path="/is/:id" element={<InstructorScreen />} />
        <Route path="/connect/:id" element={<UserForm />} />
      </Routes>
      </PrimeReactProvider>
    </Router>
    
  );
};

export default App;
