import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import db from '../components/GlobalDb';
import trainingDetails from '../components/trainingData';
import Header from '../components/header'; // Header bileşenini ekleyin
import Navbar from '../components/Navbar'; // Header bileşenini ekleyin
import { PanelMenu } from 'primereact/panelmenu';
import { Card } from 'primereact/card';
import { Splitter, SplitterPanel } from 'primereact/splitter';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Image } from 'primereact/image';
import config from '../config';

const ManageCourse = () => {
  const [course, setCourse] = useState({});
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedTrainingIndex, setSelectedTrainingIndex] = useState(null);
  const [selectedTrainingLink, setSelectedTrainingLink] = useState('');
  const { id } = useParams();
  const myDomain = config.MY_DOMAIN;
  const [selectedTrainingInfo, setSelectedTrainingInfo] = useState({
    konular: [],
    totalPages: '',
    konuTur: ''
  });
  const [items, setItems] = useState([]);

  useEffect(() => {
    // Kurs bilgilerini ve başlıkları/sunumları çekme
    const fetchCourseDetails = async () => {
      try {
        const tokenObject = await db.tokens.toCollection().first();
        if (tokenObject) {
          const token = tokenObject.token;
        const response = await fetch(`${config.MY_API}api/course-detail`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`, // Varsayım: token değişkeni daha önce tanımlanmış
          },
          body: JSON.stringify({ courseid: id }),
        });

        const data = await response.json();
        if (response.ok && data.success) {
          setCourse(data.message || {});
          // Başlıkları ve sunumları işleyip, items'ı güncelle
          const newItems = data.message.basliklarVeSunumlar.map(baslik => ({
            label: baslik.adi,
            icon: 'pi pi-fw pi-file',
            items: baslik.sunumlar.flatMap(sunum => {
              // Her sunum için bir 'subItems' dizisi oluştur
              const subItems = []; // Bu satır 'subItems' dizisini tanımlar
          
              // Türkçe sunum varsa, 'subItems' dizisine eklenir
              if (sunum.link) {
                subItems.push({
                  label: 'Türkçe',
                  icon: 'pi pi-fw pi-globe',
                  command: () => handleTrainingClick(sunum.link, sunum.id, 'TR') // Türkçe sunum için
                });
              }
              
              // İngilizce sunum varsa, 'subItems' dizisine eklenir
              if (sunum.link2) {
                subItems.push({
                  label: 'İngilizce',
                  icon: 'pi pi-fw pi-globe',
                  command: () => handleTrainingClick(sunum.link2, sunum.id, 'EN') // İngilizce sunum için
                });
              }
          
              // 'subItems' dizisine eklenen öğeleri içeren bir üst öğe döndür
              return subItems.length ? [{
                label: sunum.adi,
                icon: 'pi pi-fw pi-external-link',
                items: subItems // Burada 'subItems' dizisi kullanılır
              }] : [];
            })
          }));
          
          setItems(newItems);
        } else {
          throw new Error(data.message || 'Error fetching course details');
        }
      } else {
        throw new Error('No token found');
      }
      } catch (error) {
        console.error('Error fetching course details:', error);
      }
    };

    fetchCourseDetails();
  }, [id]);

  useEffect(() => {
    // Eğer course.nowpage ve selectedTrainingInfo.konular varsa işlem yap
    if (course.nowpage && selectedTrainingInfo.konular.length > 0) {
      // course.nowpage ile eşleşen training'i bul
      const matchingTraining = selectedTrainingInfo.konular.find(training => Number(training.id) === Number(course.nowpage));
  
      if (matchingTraining) {
        // Eşleşen training'in linkini alıp, tam adresini oluştur
        const absoluteLink = `${myDomain}${matchingTraining.link.startsWith('/') ? '' : '/'}${matchingTraining.link}`;
        // selectedTrainingLink state'ini güncelle
        setSelectedTrainingLink(absoluteLink);
      }
    }
  }, [course.nowpage, selectedTrainingInfo.konular]); // Bağımlılıklar

  const handleSelectChange = (selectedValue) => {
    // Bu örnekte selectedValue, örneğin '330_turkce' gibi bir değer olacak
    setSelectedOption(selectedValue);

    const training = trainingDetails.find(detail => detail.KonuTur === selectedValue);
    const details = training?.konular || [];
    const pages = training?.TotalPage || '';
    const konuTur = training?.KonuTur || '';

    // Seçilen eğitimle ilgili tüm bilgileri tek bir state içinde sakla
    setSelectedTrainingInfo({
      konular: details,
      totalPages: pages,
      konuTur: konuTur
    });
  };

  const handleTrainingClick = async (selectedLink, sunumId, language) => {
    setSelectedTrainingLink(selectedLink); // Seçilen linki state'e kaydet
  
    try {
      const tokenObject = await db.tokens.toCollection().first();
      if (tokenObject) {
        const token = tokenObject.token;
        const response = await fetch(`${config.MY_API}api/remotecontrol`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            id: id, // Kursun ID'si
            sunumId: sunumId, // Seçilen sunumun ID'si
            dil: language, // Seçilen dil
          }),
        });
  
        const data = await response.json();
        if (response.ok && data.success) {
          console.log('POST request successful');
        } else {
          throw new Error(data.message || 'Error in POST request');
        }
      } else {
        throw new Error('No token found');
      }
    } catch (error) {
      console.error('Error in POST request:', error);
    }
  };
  

  const formatStartDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
    return formattedDate;
  };


  const renderContent = () => (
    <Card title="İçerik">
      <Splitter style={{ height: '600px' }}>
        <SplitterPanel className="flex align-items-center justify-content-center" size={100}>
          {selectedTrainingLink ? (
            <iframe src={selectedTrainingLink} style={{ width: '100%', height: '100%' }} title="Eğitim İçeriği"></iframe>
          ) : 'Lütfen bir eğitim seçin.'}
        </SplitterPanel>
      </Splitter>
    </Card>
  );

  const renderTrainingDetails = () => (
    <Card title="İçerik">
    <Splitter style={{ height: '600px' }}>
      <SplitterPanel className="flex align-items-center justify-content-center" size={25} minSize={10}>
        <ScrollPanel style={{ width: '100%', height: '100%' }}>
        <ul className="listview simple-listview">
        {selectedTrainingInfo.konular.map((training, index) => (
  <li
    key={index}
    onClick={() => handleTrainingClick(index)}
    className={
      (index === selectedTrainingIndex || 
      (Number(training.id) === Number(course.nowpage) && selectedTrainingIndex === null))
        ? 'list-group-item-action active'
        : ''
    }
  >
    {training.adi}
  </li>
))}
    </ul>
        </ScrollPanel>
      </SplitterPanel>
      <SplitterPanel className="flex align-items-center justify-content-center" size={75}>
        {selectedTrainingLink ? (
          <Image src={selectedTrainingLink} alt="Training Content" imageStyle={{ maxWidth: '100%', maxHeight: '100%', display: 'block', margin: 'auto' }} preview />
        ) : (
          'Please select a training to view details.'
        )}
      </SplitterPanel>
    </Splitter>
  </Card>
  );

  return (


    <div id="layout-wrapperx" className="layout-wrapper layout-navbar-full layout-horizontal layout-without-menu">
      <Navbar />
      <div id="content-wrapperx" className="layout-container">

        <Header />

        <div className="layout-page">

          <div className="content-wrapper">

            <div className="container-xxl flex-grow-1 container-p-y">







              <div class="row mb-4 g-4">
                <div class="col-12 col-xl-4 col-md-4">
                <Card title="Eğitim Başlıkları">
                    <PanelMenu model={items} className="w-full md:w-20rem" />
                  </Card>
                </div>



                <div class="col-12 col-xl-8 col-md-8">
                  <Card title="Ders Yönetimi">
                    <div className="manage-course">


                      <div>
                        <label for="defaultFormControlInput" class="form-label">Eğitmen Ekranı</label>
                        <input 
  type="text" 
  class="form-control" 
  id="defaultFormControlInput" 
  value={course.kursBilgileri ? myDomain + 'is/' + course.kursBilgileri.sharecode : 'N/A'} 
  aria-describedby="defaultFormControlHelp" 
  readOnly
/>
                      </div>

                      <br />

                      <br />

                      <div>

                      <strong>Eğitim Programı: </strong> {course.kursBilgileri?.programadi || 'N/A'}<br />
                      <strong>Başlangıç Tarihi:</strong> {formatStartDate(course.kursBilgileri?.createdat) || 'N/A'}<br />
                      </div>







                    </div>
                  </Card>
                </div>
                {selectedTrainingLink && renderContent()} 

              </div>
            </div>

            <div className='mb-5'>
            </div>







          </div>




        </div>
      </div>
    </div>

  );
};

export default ManageCourse;
