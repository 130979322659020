import React, { useState, useEffect } from 'react';
import Draggable from 'react-draggable';
import { ResizableBox } from 'react-resizable';
import 'react-resizable/css/styles.css';
import { IconArrowsDiagonalMinimize, IconSquareRoundedX, IconArrowsMove } from '@tabler/icons-react';
import config from '../config';
import useDisableIframePointerEvents from './useDisableIframePointerEvents'; // Custom hook'u import et




const DraggableResizableModal = ({ show, onHide, modalId, imgLink, imgTitle, imgPage }) => {

  const myDomain = config.MY_DOMAIN;
  let absoluteLink = imgLink;

  const [visible, setVisible] = useState(show);
  // Modal açıkken iframe'lerdeki pointer events'ı devre dışı bırak
  useDisableIframePointerEvents(visible);

  useEffect(() => {
    setVisible(show);
  }, [show]);

  if (!visible) return null;

  // Modal içeriği ve diğer kodlar...


  // imgLink zaten mutlak bir URL ise, MY_DOMAIN eklemeyin
  if (!imgLink.startsWith('http://') && !imgLink.startsWith('https://')) {
    const myDomain = config.MY_DOMAIN;
    absoluteLink = `${myDomain}${imgLink.startsWith('/') ? '' : '/'}${imgLink}`;
  }


  // Özel yeniden boyutlandırma tutamacını tanımla
  const resizeHandle = (
    <span className="custom-resize-handle" />
  );

  const content = (
    <div style={{ position: 'fixed', top: '20%', left: '20%', zIndex: 1050 }}>
      <ResizableBox width={300} height={200} resizeHandles={['se']} handle={<div className="custom-resize-handle"><IconArrowsDiagonalMinimize size={22} stroke={2} color="#000" /></div>}>
        <div className="modal-header bgmy" >
          <h5 className="title bgmy2" style={{ cursor: 'move' }}>
            <IconArrowsMove size={15} stroke={2} color="#000" /> {imgTitle} {imgPage && `| Page: ${imgPage}`}
          </h5>
          <IconSquareRoundedX onClick={() => onHide(modalId)} size={15} stroke={2} className='bgmy3 clickable' color="#000" />
        </div>
        <div className="modal-body custom-bb">
          <img src={absoluteLink} alt={imgTitle} style={{ width: '100%', height: 'auto' }} />
        </div>
      </ResizableBox>
    </div>
  );

  return <Draggable handle=".title">{content}</Draggable>;
};

export default DraggableResizableModal;
