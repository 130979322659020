const trainingDetails = [
    {
      "KonuTur": "330_turkce",
      "TotalPage": "32",
      "toolbox_1_name": "WBM Minified for Latches",
      "toolbox_1_pdf": "https://drive.google.com/file/d/1ZCx22qLNfYBVrxmnS1wpQRQUwlTm9sKO/preview",
      "toolbox_2_name": "WBM PAGE 1088 - DESCRIPTION OF THE NUMBERING SYSTEM",
      "toolbox_2_pdf": "https://drive.google.com/file/d/12CEkk6ww4WUsri5-J4q8ujmxCXTNWjig/preview",
      "toolbox_3_name": "SIMPLIFIED LATCH FAILURE TABLE",
      "toolbox_3_pdf": "https://drive.google.com/file/d/1p89SAmAzIDMRIja1HJGOLlCxObqdwxSL/preview",
      "konular": [
        {
          "id": 1,
          "adi": "Giriş",
          "aciklama": "safsafa",
          "link": "edu/330/kilitler/1.JPG",
          "wbm1_title": "",
          "wbm1_link": "",
          "wbm1_page": "",
          "wbm2_title": "",
          "wbm2_link": "",
          "wbm2_page": "",
          "wbm3_title": "",
          "wbm3_link": "",
          "wbm3_page": "",
          "wbm4_title": "",
          "wbm4_link": "",
          "wbm4_page": "",
          "wbm5_title": "",
          "wbm5_link": "",
          "wbm5_page": "",
          "wbm6_title": "",
          "wbm6_link": "",
          "wbm6_page": "",
          "wbm7_title": "",
          "wbm7_link": "",
          "wbm7_page": "",
          "wbm8_title": "",
          "wbm8_link": "",
          "wbm8_page": "",
          "wbm9_title": "",
          "wbm9_link": "",
          "wbm9_page": "",
          "wbm10_title": "",
          "wbm10_link": "",
          "wbm10_page": ""
         
        },
        {
          "id": 2,
          "adi": "Kilitlerin işlevsel yön sınıflandırması",
          "aciklama": "safsafa",
          "link": "edu/330/kilitler/2.JPG",
          "wbm1_title": "",
          "wbm1_link": "",
          "wbm1_page": "",
          "wbm2_title": "",
          "wbm2_link": "",
          "wbm2_page": "",
          "wbm3_title": "",
          "wbm3_link": "",
          "wbm3_page": "",
          "wbm4_title": "",
          "wbm4_link": "",
          "wbm4_page": "",
          "wbm5_title": "",
          "wbm5_link": "",
          "wbm5_page": "",
          "wbm6_title": "",
          "wbm6_link": "",
          "wbm6_page": "",
          "wbm7_title": "",
          "wbm7_link": "",
          "wbm7_page": "",
          "wbm8_title": "",
          "wbm8_link": "",
          "wbm8_page": "",
          "wbm9_title": "",
          "wbm9_link": "",
          "wbm9_page": "",
          "wbm10_title": "",
          "wbm10_link": "",
          "wbm10_page": ""
         
        },
        {
          "id": 3,
          "adi": "Kilit çeşitleri",
          "aciklama": "safsafa",
          "link": "edu/330/kilitler/3.JPG",
          "wbm1_title": "",
          "wbm1_link": "",
          "wbm1_page": "",
          "wbm2_title": "",
          "wbm2_link": "",
          "wbm2_page": "",
          "wbm3_title": "",
          "wbm3_link": "",
          "wbm3_page": "",
          "wbm4_title": "",
          "wbm4_link": "",
          "wbm4_page": "",
          "wbm5_title": "",
          "wbm5_link": "",
          "wbm5_page": "",
          "wbm6_title": "",
          "wbm6_link": "",
          "wbm6_page": "",
          "wbm7_title": "",
          "wbm7_link": "",
          "wbm7_page": "",
          "wbm8_title": "",
          "wbm8_link": "",
          "wbm8_page": "",
          "wbm9_title": "",
          "wbm9_link": "",
          "wbm9_page": "",
          "wbm10_title": "",
          "wbm10_link": "",
          "wbm10_page": ""
         
        },
        {
          "id": 4,
          "adi": "Lower Deck | Genel Bakış",
          "aciklama": "safsafa",
          "link": "edu/330/kilitler/4.JPG",
          "wbm1_title": "",
          "wbm1_link": "",
          "wbm1_page": "",
          "wbm2_title": "",
          "wbm2_link": "",
          "wbm2_page": "",
          "wbm3_title": "",
          "wbm3_link": "",
          "wbm3_page": "",
          "wbm4_title": "",
          "wbm4_link": "",
          "wbm4_page": "",
          "wbm5_title": "",
          "wbm5_link": "",
          "wbm5_page": "",
          "wbm6_title": "",
          "wbm6_link": "",
          "wbm6_page": "",
          "wbm7_title": "",
          "wbm7_link": "",
          "wbm7_page": "",
          "wbm8_title": "",
          "wbm8_link": "",
          "wbm8_page": "",
          "wbm9_title": "",
          "wbm9_link": "",
          "wbm9_page": "",
          "wbm10_title": "",
          "wbm10_link": "",
          "wbm10_page": ""
         
        },
        {
          "id": 5,
          "adi": "Lower Deck FWD | K Size",
          "aciklama": "safsafa",
          "link": "edu/330/kilitler/5.JPG",
          "wbm1_title": "COMP. 1 XZ Single - XZ Double - YZ Latch (Door Sill)",
          "wbm1_link": "edu/330/content/ld-k-01.png",
          "wbm1_page": "238",
          "wbm2_title": "COMP. 1 YZ Latch - YZ Single Latch - XZ Latch (End Stop)",
          "wbm2_link": "edu/330/content/ld-k-02.png",
          "wbm2_page": "239",
          "wbm3_title": "COMP. 2 XZ Single Latch - XZ Double Latch",
          "wbm3_link": "edu/330/content/ld-k-03.png",
          "wbm3_page": "247",
          "wbm4_title": "COMP. 2 YZ Latch - YZ Single Latch - XZ Latch (End Stop)",
          "wbm4_link": "edu/330/content/ld-k-04.png",
          "wbm4_page": "248",
          "wbm5_title": "QR - K Size",
          "wbm5_link": "edu/330/content/qr-01.png",
          "wbm5_page": "QR - K Size",
          "wbm6_title": "",
          "wbm6_link": "",
          "wbm6_page": "",
          "wbm7_title": "",
          "wbm7_link": "",
          "wbm7_page": "",
          "wbm8_title": "",
          "wbm8_link": "",
          "wbm8_page": "",
          "wbm9_title": "",
          "wbm9_link": "",
          "wbm9_page": "",
          "wbm10_title": "",
          "wbm10_link": "",
          "wbm10_page": ""
         
        },
        {
          "id": 6,
          "adi": "Lower Deck FWD | L Size",
          "aciklama": "safsafa",
          "link": "edu/330/kilitler/6.JPG",
          "wbm1_title": "COMP. 1 XZ Single Latch - XZ DOUBLE LATCH",
          "wbm1_link": "edu/330/content/ld-k-05.png",
          "wbm1_page": "253",
          "wbm2_title": "COMP. 1 YZ LATCH DOOR SILL - YZ LATCH - XZ LATCH (END STOP)",
          "wbm2_link": "edu/330/content/ld-k-06.png",
          "wbm2_page": "254",
          "wbm3_title": "COMP 2. XZ Single Latch",
          "wbm3_link": "edu/330/content/ld-k-07.png",
          "wbm3_page": "261",
          "wbm4_title": "COMP 2. XZ DOUBLE LATCH - YZ LATCH - XZ LATCH (ENDSTOP)",
          "wbm4_link": "edu/330/content/ld-k-08.png",
          "wbm4_page": "262",
          "wbm5_title": "QR - L Size",
          "wbm5_link": "edu/330/content/qr-02.png",
          "wbm5_page": "QR - L Size",
          "wbm6_title": "",
          "wbm6_link": "",
          "wbm6_page": "",
          "wbm7_title": "",
          "wbm7_link": "",
          "wbm7_page": "",
          "wbm8_title": "",
          "wbm8_link": "",
          "wbm8_page": "",
          "wbm9_title": "",
          "wbm9_link": "",
          "wbm9_page": "",
          "wbm10_title": "",
          "wbm10_link": "",
          "wbm10_page": ""
         
        },
        {
          "id": 7,
          "adi": "Lower Deck FWD | A Size",
          "aciklama": "safsafa",
          "link": "edu/330/kilitler/7.JPG",
          "wbm1_title": "COMP. 1 XZ Single Latch - XZ DOUBLE LATCH",
          "wbm1_link": "edu/330/content/ld-k-09.png",
          "wbm1_page": "269",
          "wbm2_title": "COMP. 1 YZ LATCH",
          "wbm2_link": "edu/330/content/ld-k-10.png",
          "wbm2_page": "270",
          "wbm3_title": "COMP 2. XZ Double Latch - YZ LATCH - XZ LATCH (End Stop)",
          "wbm3_link": "edu/330/content/ld-k-11.png",
          "wbm3_page": "277",
          "wbm4_title": "QR - A Size",
          "wbm4_link": "edu/330/content/qr-03.png",
          "wbm4_page": "QR - A Size",
          "wbm5_title": "",
          "wbm5_link": "",
          "wbm5_page": "",
          "wbm6_title": "",
          "wbm6_link": "",
          "wbm6_page": "",
          "wbm7_title": "",
          "wbm7_link": "",
          "wbm7_page": "",
          "wbm8_title": "",
          "wbm8_link": "",
          "wbm8_page": "",
          "wbm9_title": "",
          "wbm9_link": "",
          "wbm9_page": "",
          "wbm10_title": "",
          "wbm10_link": "",
          "wbm10_page": ""
         
        },
        {
          "id": 8,
          "adi": "Lower Deck FWD | M Size",
          "aciklama": "safsafa",
          "link": "edu/330/kilitler/8.JPG",
          "wbm1_title": "COMP. 1 XZ DOUBLE LATCH - YZ LATCH (DOOR SILL)",
          "wbm1_link": "edu/330/content/ld-k-12.png",
          "wbm1_page": "283",
          "wbm2_title": "COMP. 1 YZ LATCH",
          "wbm2_link": "edu/330/content/ld-k-13.png",
          "wbm2_page": "284",
          "wbm3_title": "COMP 2. XZ Double Latch - YZ LATCH - YZ LATCH (End Stop)",
          "wbm3_link": "edu/330/content/ld-k-14.png",
          "wbm3_page": "291",
          "wbm4_title": "QR - M Size",
          "wbm4_link": "edu/330/content/qr-04.png",
          "wbm4_page": "QR - M Size",
          "wbm5_title": "",
          "wbm5_link": "",
          "wbm5_page": "",
          "wbm6_title": "",
          "wbm6_link": "",
          "wbm6_page": "",
          "wbm7_title": "",
          "wbm7_link": "",
          "wbm7_page": "",
          "wbm8_title": "",
          "wbm8_link": "",
          "wbm8_page": "",
          "wbm9_title": "",
          "wbm9_link": "",
          "wbm9_page": "",
          "wbm10_title": "",
          "wbm10_link": "",
          "wbm10_page": ""
         
        },
        {
          "id": 9,
          "adi": "Lower Deck AFT TYPE 1 | K Size",
          "aciklama": "safsafa",
          "link": "edu/330/kilitler/9.JPG",
          "wbm1_title": "COMP. 3 XZ Single Latch",
          "wbm1_link": "edu/330/content/ld-k-15.png",
          "wbm1_page": "341",
          "wbm2_title": "COMP. 3 XZ Triple Latch - YZ Latch - YZ Single Latch - XZ Latch (End Stop)",
          "wbm2_link": "edu/330/content/ld-k-16.png",
          "wbm2_page": "342",
          "wbm3_title": "COMP 4. XZ Single Latch - XZ Double LATCH - XZ Triple Latch - YZ Latch",
          "wbm3_link": "edu/330/content/ld-k-17.png",
          "wbm3_page": "364",
          "wbm4_title": "COMP 4. YZ Single Latch - YZ Latch (Door Sill) - XZ Latch (Endstop)",
          "wbm4_link": "edu/330/content/ld-k-18.png",
          "wbm4_page": "365",
          "wbm5_title": "QR - K Size",
          "wbm5_link": "edu/330/content/qr-05.png",
          "wbm5_page": "QR - K Size",
          "wbm6_title": "",
          "wbm6_link": "",
          "wbm6_page": "",
          "wbm7_title": "",
          "wbm7_link": "",
          "wbm7_page": "",
          "wbm8_title": "",
          "wbm8_link": "",
          "wbm8_page": "",
          "wbm9_title": "",
          "wbm9_link": "",
          "wbm9_page": "",
          "wbm10_title": "",
          "wbm10_link": "",
          "wbm10_page": ""
         
        },
        {
          "id": 10,
          "adi": "Lower Deck AFT TYPE 2 | K Size",
          "aciklama": "safsafa",
          "link": "edu/330/kilitler/10.JPG",
          "wbm1_title": "COMP. 3 XZ Single Latch - XZ Triple Latch",
          "wbm1_link": "edu/330/content/ld-k-19.png",
          "wbm1_page": "351",
          "wbm2_title": "COMP. 3 YZ Latch - YZ Single Latch - XZ Latch (Endstop)",
          "wbm2_link": "edu/330/content/ld-k-20.png",
          "wbm2_page": "352",
          "wbm3_title": "COMP 4. XZ Single Latch - XZ Triple Latch - YZ Latch- YZ Latch (Door Sill) - YZ Single Latch",
          "wbm3_link": "edu/330/content/ld-k-21.png",
          "wbm3_page": "371",
          "wbm4_title": "COMP 4. XZ Latch (Endstop)",
          "wbm4_link": "edu/330/content/ld-k-22.png",
          "wbm4_page": "365",
          "wbm5_title": "QR - K Size",
          "wbm5_link": "edu/330/content/qr-05.png",
          "wbm5_page": "QR - K Size",
          "wbm6_title": "",
          "wbm6_link": "",
          "wbm6_page": "",
          "wbm7_title": "",
          "wbm7_link": "",
          "wbm7_page": "",
          "wbm8_title": "",
          "wbm8_link": "",
          "wbm8_page": "",
          "wbm9_title": "",
          "wbm9_link": "",
          "wbm9_page": "",
          "wbm10_title": "",
          "wbm10_link": "",
          "wbm10_page": ""
         
        },
        {
          "id": 11,
          "adi": "Lower Deck AFT TYPE 1 | L Size",
          "aciklama": "safsafa",
          "link": "edu/330/kilitler/11.JPG",
          "wbm1_title": "COMP. 3 XZ Single Latch - XZ Triple Latch",
          "wbm1_link": "edu/330/content/ld-k-23.png",
          "wbm1_page": "377",
          "wbm2_title": "COMP. 3 YZ Latch - XZ Latch (Endstop)",
          "wbm2_link": "edu/330/content/ld-k-24.png",
          "wbm2_page": "378",
          "wbm3_title": "COMP 4. XZ Single Latch - XZ Double Latch - XZ Triple Latch - YZ Latch - YZ Latch (Door Sill) - XZ Latch (EndStop)",
          "wbm3_link": "edu/330/content/ld-k-25.png",
          "wbm3_page": "394",
          "wbm4_title": "QR - L Size",
          "wbm4_link": "edu/330/content/qr-06.png",
          "wbm4_page": "QR - L Size",
          "wbm5_title": "",
          "wbm5_link": "",
          "wbm5_page": "",
          "wbm6_title": "",
          "wbm6_link": "",
          "wbm6_page": "",
          "wbm7_title": "",
          "wbm7_link": "",
          "wbm7_page": "",
          "wbm8_title": "",
          "wbm8_link": "",
          "wbm8_page": "",
          "wbm9_title": "",
          "wbm9_link": "",
          "wbm9_page": "",
          "wbm10_title": "",
          "wbm10_link": "",
          "wbm10_page": ""
         
        },
        {
          "id": 12,
          "adi": "Lower Deck AFT TYPE 2 | L Size",
          "aciklama": "safsafa",
          "link": "edu/330/kilitler/12.JPG",
          "wbm1_title": "COMP. 3 XZ Single Latch",
          "wbm1_link": "edu/330/content/ld-k-26.png",
          "wbm1_page": "387",
          "wbm2_title": "COMP. 3 XZ Triple Latch - YZ Latch - XZ Latch (End Stop)",
          "wbm2_link": "edu/330/content/ld-k-27.png",
          "wbm2_page": "388",
          "wbm3_title": "COMP 4. XZ Single Latch - XZ Triple Latch - YZ Latch - YZ Latch (Door Sill) - XZ Latch (EndStop)",
          "wbm3_link": "edu/330/content/ld-k-28.png",
          "wbm3_page": "398",
          "wbm4_title": "COMP 4. XZ Latch (End Stop)",
          "wbm4_link": "edu/330/content/ld-k-29.png",
          "wbm4_page": "399",
          "wbm5_title": "QR - L Size",
          "wbm5_link": "edu/330/content/qr-06.png",
          "wbm5_page": "QR - L Size",
          "wbm6_title": "",
          "wbm6_link": "",
          "wbm6_page": "",
          "wbm7_title": "",
          "wbm7_link": "",
          "wbm7_page": "",
          "wbm8_title": "",
          "wbm8_link": "",
          "wbm8_page": "",
          "wbm9_title": "",
          "wbm9_link": "",
          "wbm9_page": "",
          "wbm10_title": "",
          "wbm10_link": "",
          "wbm10_page": ""
         
        },
        {
          "id": 13,
          "adi": "Lower Deck AFT TYPE 1 | A Size",
          "aciklama": "safsafa",
          "link": "edu/330/kilitler/13.JPG",
          "wbm1_title": "COMP. 3 XZ Double Latch - XZ Triple Latch",
          "wbm1_link": "edu/330/content/ld-k-30.png",
          "wbm1_page": "409",
          "wbm2_title": "COMP. 3 YZ Latch - XZ Latch (End Stop)",
          "wbm2_link": "edu/330/content/ld-k-31.png",
          "wbm2_page": "410",
          "wbm3_title": "COMP 4. XZ Single Latch - XZ Double Latch - XZ Triple Latch",
          "wbm3_link": "edu/330/content/ld-k-32.png",
          "wbm3_page": "423",
          "wbm4_title": "COMP 4. YZ Latch - YZ Latch (Door Sill)",
          "wbm4_link": "edu/330/content/ld-k-33.png",
          "wbm4_page": "424",
          "wbm5_title": "QR - A Size",
          "wbm5_link": "edu/330/content/qr-07.png",
          "wbm5_page": "QR - A Size",
          "wbm6_title": "",
          "wbm6_link": "",
          "wbm6_page": "",
          "wbm7_title": "",
          "wbm7_link": "",
          "wbm7_page": "",
          "wbm8_title": "",
          "wbm8_link": "",
          "wbm8_page": "",
          "wbm9_title": "",
          "wbm9_link": "",
          "wbm9_page": "",
          "wbm10_title": "",
          "wbm10_link": "",
          "wbm10_page": ""
         
        },
        {
          "id": 14,
          "adi": "Lower Deck AFT TYPE 2 | A Size",
          "aciklama": "safsafa",
          "link": "edu/330/kilitler/14.JPG",
          "wbm1_title": "COMP. 3 XZ Double Latch",
          "wbm1_link": "edu/330/content/ld-k-34.png",
          "wbm1_page": "417",
          "wbm2_title": "COMP. 3 XZ Triple Latch - YZ Latch - XZ Latch (End Stop)",
          "wbm2_link": "edu/330/content/ld-k-35.png",
          "wbm2_page": "418",
          "wbm3_title": "COMP 4. XZ Single Latch - XZ Triple Latch",
          "wbm3_link": "edu/330/content/ld-k-36.png",
          "wbm3_page": "427",
          "wbm4_title": "COMP 4. YZ Latch - YZ Latch (Door Sill)",
          "wbm4_link": "edu/330/content/ld-k-37.png",
          "wbm4_page": "428",
          "wbm5_title": "QR - A Size",
          "wbm5_link": "edu/330/content/qr-07.png",
          "wbm5_page": "QR - A Size",
          "wbm6_title": "",
          "wbm6_link": "",
          "wbm6_page": "",
          "wbm7_title": "",
          "wbm7_link": "",
          "wbm7_page": "",
          "wbm8_title": "",
          "wbm8_link": "",
          "wbm8_page": "",
          "wbm9_title": "",
          "wbm9_link": "",
          "wbm9_page": "",
          "wbm10_title": "",
          "wbm10_link": "",
          "wbm10_page": ""
         
        },
        {
          "id": 15,
          "adi": "Lower Deck AFT TYPE 1 | M Size",
          "aciklama": "safsafa",
          "link": "edu/330/kilitler/15.JPG",
          "wbm1_title": "COMP. 3 XZ Double Latch - XZ Triple Latch",
          "wbm1_link": "edu/330/content/ld-k-38.png",
          "wbm1_page": "442",
          "wbm2_title": "COMP. 3 YZ Latch - XZ Latch (End Stop)",
          "wbm2_link": "edu/330/content/ld-k-39.png",
          "wbm2_page": "443",
          "wbm3_title": "COMP. 4 XZ Single Latch - XZ Double Latch - XZ Triple Latch",
          "wbm3_link": "edu/330/content/ld-k-40.png",
          "wbm3_page": "457",
          "wbm4_title": "COMP. 4  YZ Latch - YZ Latch (Door Sill) - XZ Latch (Endstop)",
          "wbm4_link": "edu/330/content/ld-k-41.png",
          "wbm4_page": "458",
          "wbm5_title": "QR - M Size",
          "wbm5_link": "edu/330/content/qr-08.png",
          "wbm5_page": "QR - M Size",
          "wbm6_title": "",
          "wbm6_link": "",
          "wbm6_page": "",
          "wbm7_title": "",
          "wbm7_link": "",
          "wbm7_page": "",
          "wbm8_title": "",
          "wbm8_link": "",
          "wbm8_page": "",
          "wbm9_title": "",
          "wbm9_link": "",
          "wbm9_page": "",
          "wbm10_title": "",
          "wbm10_link": "",
          "wbm10_page": ""
         
        },
        {
          "id": 16,
          "adi": "Lower Deck AFT TYPE 2 | M Size",
          "aciklama": "safsafa",
          "link": "edu/330/kilitler/16.JPG",
          "wbm1_title": "COMP. 3 XZ Double Latch - XZ Triple Latch",
          "wbm1_link": "edu/330/content/ld-k-42.png",
          "wbm1_page": "451",
          "wbm2_title": "COMP. 3 YZ Latch - XZ Latch (End Stop)",
          "wbm2_link": "edu/330/content/ld-k-43.png",
          "wbm2_page": "452",
          "wbm3_title": "COMP. 4 XZ Single Latch - XZ Triple Latch",
          "wbm3_link": "edu/330/content/ld-k-44.png",
          "wbm3_page": "461",
          "wbm4_title": "COMP. 4  YZ Latch - YZ Latch (Door Sill) - XZ Latch (Endstop)",
          "wbm4_link": "edu/330/content/ld-k-45.png",
          "wbm4_page": "462",
          "wbm5_title": "QR - M Size",
          "wbm5_link": "edu/330/content/qr-08.png",
          "wbm5_page": "QR - M Size",
          "wbm6_title": "",
          "wbm6_link": "",
          "wbm6_page": "",
          "wbm7_title": "",
          "wbm7_link": "",
          "wbm7_page": "",
          "wbm8_title": "",
          "wbm8_link": "",
          "wbm8_page": "",
          "wbm9_title": "",
          "wbm9_link": "",
          "wbm9_page": "",
          "wbm10_title": "",
          "wbm10_link": "",
          "wbm10_page": ""
         
        },
        {
          "id": 17,
          "adi": "Main Deck | A Size Single",
          "aciklama": "safsafa",
          "link": "edu/330/kilitler/17.JPG",
          "wbm1_title": "A - F XZ Single Latch - XZ Double Latch",
          "wbm1_link": "edu/330/content/ld-k-46.png",
          "wbm1_page": "537",
          "wbm2_title": "A - F YZ Latch",
          "wbm2_link": "edu/330/content/ld-k-47.png",
          "wbm2_page": "538",
          "wbm3_title": "A - F XZ Latch EndStop",
          "wbm3_link": "edu/330/content/ld-k-48.png",
          "wbm3_page": "539",
          "wbm4_title": "G - L XZ Single Latch - XZ Double Latch",
          "wbm4_link": "edu/330/content/ld-k-49.png",
          "wbm4_page": "541",
          "wbm5_title": "G - L  YZ Latch",
          "wbm5_link": "edu/330/content/ld-k-50.png",
          "wbm5_page": "542",
          "wbm6_title": "G - L  YZ Latch",
          "wbm6_link": "edu/330/content/ld-k-51.png",
          "wbm6_page": "543",
          "wbm7_title": "M - U XZ Single Latch - XZ Double Latch - XZ Triple Latch",
          "wbm7_link": "edu/330/content/ld-k-52.png",
          "wbm7_page": "544",
          "wbm8_title": "M - U YZ Latch",
          "wbm8_link": "edu/330/content/ld-k-53.png",
          "wbm8_page": "545",
          "wbm9_title": "M - U XZ Latch Endstop",
          "wbm9_link": "edu/330/content/ld-k-54.png",
          "wbm9_page": "546",
          "wbm10_title": "QR - A Size",
          "wbm10_link": "edu/330/content/qr-09.png",
          "wbm10_page": "QR - A Size"
         
        },
        {
          "id": 18,
          "adi": "Main Deck | M Size Single",
          "aciklama": "safsafa",
          "link": "edu/330/kilitler/18.JPG",
          "wbm1_title": "AA - EE XZ Single Latch - XZ Double Latch",
          "wbm1_link": "edu/330/content/ld-k-55.png",
          "wbm1_page": "548",
          "wbm2_title": "AA - EE YZ Latch",
          "wbm2_link": "edu/330/content/ld-k-56.png",
          "wbm2_page": "549",
          "wbm3_title": "AA - EE XZ Latch EndStop",
          "wbm3_link": "edu/330/content/ld-k-57.png",
          "wbm3_page": "550",
          "wbm4_title": "FF - KK XZ Single Latch - XZ Double Latch",
          "wbm4_link": "edu/330/content/ld-k-58.png",
          "wbm4_page": "552",
          "wbm5_title": "FF - KK  YZ Latch",
          "wbm5_link": "edu/330/content/ld-k-59.png",
          "wbm5_page": "553",
          "wbm6_title": "FF - KK  YZ Latch",
          "wbm6_link": "edu/330/content/ld-k-60.png",
          "wbm6_page": "554",
          "wbm7_title": "LL - TT XZ Single Latch - XZ Double Latch - XZ Triple Latch",
          "wbm7_link": "edu/330/content/ld-k-61.png",
          "wbm7_page": "555",
          "wbm8_title": "LL - TT YZ Latch",
          "wbm8_link": "edu/330/content/ld-k-62.png",
          "wbm8_page": "556",
          "wbm9_title": "LL - TT XZ Latch Endstop",
          "wbm9_link": "edu/330/content/ld-k-63.png",
          "wbm9_page": "557",
          "wbm10_title": "QR - M Size",
          "wbm10_link": "edu/330/content/qr-10.png",
          "wbm10_page": "QR - M Size"
         
        },
        {
          "id": 19,
          "adi": "Main Deck | M Size Center",
          "aciklama": "safsafa",
          "link": "edu/330/kilitler/19.JPG",
          "wbm1_title": "AB - EF XZ Single Latch",
          "wbm1_link": "edu/330/content/ld-k-64.png",
          "wbm1_page": "559",
          "wbm2_title": "AB - EF YZ Latch",
          "wbm2_link": "edu/330/content/ld-k-65.png",
          "wbm2_page": "560",
          "wbm3_title": "AB - EF XZ Latch EndStop",
          "wbm3_link": "edu/330/content/ld-k-66.png",
          "wbm3_page": "561",
          "wbm4_title": "FH - KM XZ Single Latch",
          "wbm4_link": "edu/330/content/ld-k-67.png",
          "wbm4_page": "562",
          "wbm5_title": "FH - KM  XZ Double Latch",
          "wbm5_link": "edu/330/content/ld-k-68.png",
          "wbm5_page": "563",
          "wbm6_title": "FH - KM  YZ Latch",
          "wbm6_link": "edu/330/content/ld-k-69.png",
          "wbm6_page": "564",
          "wbm7_title": "FH - KM YZ Latch",
          "wbm7_link": "edu/330/content/ld-k-70.png",
          "wbm7_page": "565",
          "wbm8_title": "MP ALL Latch",
          "wbm8_link": "edu/330/content/ld-k-71.png",
          "wbm8_page": "566",
          "wbm9_title": "QR - M Size",
          "wbm9_link": "edu/330/content/qr-11.png",
          "wbm9_page": "QR - M Size",
          "wbm10_title": "",
          "wbm10_link": "",
          "wbm10_page": ""
         
        },
        {
          "id": 20,
          "adi": "Main Deck | A Size Side by Side",
          "aciklama": "safsafa",
          "link": "edu/330/kilitler/20.JPG",
          "wbm1_title": "AB - EF XZ Single Latch",
          "wbm1_link": "edu/330/content/ld-k-72.png",
          "wbm1_page": "575",
          "wbm2_title": "AB - EF YZ Latch",
          "wbm2_link": "edu/330/content/ld-k-73.png",
          "wbm2_page": "576",
          "wbm3_title": "AB - EF YZ Single Latch - XZ Latch (End Stop)",
          "wbm3_link": "edu/330/content/ld-k-74.png",
          "wbm3_page": "577",
          "wbm4_title": "FH - KM XZ Single Latch",
          "wbm4_link": "edu/330/content/ld-k-75.png",
          "wbm4_page": "579",
          "wbm5_title": "FH - KM  XZ Double Latch",
          "wbm5_link": "edu/330/content/ld-k-76.png",
          "wbm5_page": "580",
          "wbm6_title": "FH - KM  YZ Latch",
          "wbm6_link": "edu/330/content/ld-k-77.png",
          "wbm6_page": "581",
          "wbm7_title": "FH - KM YZ Latch",
          "wbm7_link": "edu/330/content/ld-k-78.png",
          "wbm7_page": "582",
          "wbm8_title": "MP PR XZ Single Latch - XZ Double Latch",
          "wbm8_link": "edu/330/content/ld-k-79.png",
          "wbm8_page": "584",
          "wbm9_title": "MP PR YZ Latch - YZ Single Latch - XZ Latch (Endstop)",
          "wbm9_link": "edu/330/content/ld-k-80.png",
          "wbm9_page": "585",
          "wbm10_title": "QR - A Size",
          "wbm10_link": "edu/330/content/qr-12.png",
          "wbm10_page": "QR - A Size"
         
        },
        {
          "id": 21,
          "adi": "Main Deck | M Size Side by Side",
          "aciklama": "safsafa",
          "link": "edu/330/kilitler/21.JPG",
          "wbm1_title": "AB - EF XZ Single Latch",
          "wbm1_link": "edu/330/content/ld-k-81.png",
          "wbm1_page": "587",
          "wbm2_title": "AB - EF YZ Latch",
          "wbm2_link": "edu/330/content/ld-k-82.png",
          "wbm2_page": "588",
          "wbm3_title": "AB - EF YZ Single Latch - XZ Latch (End Stop)",
          "wbm3_link": "edu/330/content/ld-k-83.png",
          "wbm3_page": "589",
          "wbm4_title": "FH - KM XZ Single Latch",
          "wbm4_link": "edu/330/content/ld-k-84.png",
          "wbm4_page": "591",
          "wbm5_title": "FH - KM  XZ Double Latch",
          "wbm5_link": "edu/330/content/ld-k-85.png",
          "wbm5_page": "592",
          "wbm6_title": "FH - KM  YZ Latch",
          "wbm6_link": "edu/330/content/ld-k-86.png",
          "wbm6_page": "593",
          "wbm7_title": "FH - KM YZ Single Latch",
          "wbm7_link": "edu/330/content/ld-k-87.png",
          "wbm7_page": "594",
          "wbm8_title": "MP XZ Single Latch",
          "wbm8_link": "edu/330/content/ld-k-88.png",
          "wbm8_page": "595",
          "wbm9_title": "MP YZ Latch - YZ Single Latch",
          "wbm9_link": "edu/330/content/ld-k-89.png",
          "wbm9_page": "596",
          "wbm10_title": "QR - M Size",
          "wbm10_link": "edu/330/content/qr-13.png",
          "wbm10_page": "QR - M Size"
         
        },
        {
          "id": 22,
          "adi": "Main Deck | R Size Center",
          "aciklama": "safsafa",
          "link": "edu/330/kilitler/22.JPG",
          "wbm1_title": "CFR YZ Latch",
          "wbm1_link": "edu/330/content/ld-k-90.png",
          "wbm1_page": "567",
          "wbm2_title": "CFR - XYZ Latch",
          "wbm2_link": "edu/330/content/ld-k-91.png",
          "wbm2_page": "568",
          "wbm3_title": "FJR - JLR XYZ Latch",
          "wbm3_link": "edu/330/content/ld-k-92.png",
          "wbm3_page": "569",
          "wbm4_title": "LPR XYZ Latch",
          "wbm4_link": "edu/330/content/ld-k-93.png",
          "wbm4_page": "570",
          "wbm5_title": "QR - R Size",
          "wbm5_link": "edu/330/content/qr-14.png",
          "wbm5_page": "QR - R Size",
          "wbm6_title":"",
          "wbm6_link": "",
          "wbm6_page": "",
          "wbm7_title": "",
          "wbm7_link": "",
          "wbm7_page": "",
          "wbm8_title": "",
          "wbm8_link": "",
          "wbm8_page": "",
          "wbm9_title": "",
          "wbm9_link": "",
          "wbm9_page": "",
          "wbm10_title": "",
          "wbm10_link": "",
          "wbm10_page": ""
         
        },
        {
          "id": 23,
          "adi": "Main Deck | G Size Center",
          "aciklama": "safsafa",
          "link": "edu/330/kilitler/23.JPG",
          "wbm1_title": "CFG - YZ Latch - XYZ Latch",
          "wbm1_link": "edu/330/content/ld-k-94.png",
          "wbm1_page": "571",
          "wbm2_title": "FJG - JLG - XYZ Latch",
          "wbm2_link": "edu/330/content/ld-k-95.png",
          "wbm2_page": "573",
          "wbm3_title": "QR - G Size",
          "wbm3_link": "edu/330/content/qr-15.png",
          "wbm3_page": "QR - G Size",
          "wbm4_title": "",
          "wbm4_link": "",
          "wbm4_page": "",
          "wbm5_title": "",
          "wbm5_link": "",
          "wbm5_page": "",
          "wbm6_title":"",
          "wbm6_link": "",
          "wbm6_page": "",
          "wbm7_title": "",
          "wbm7_link": "",
          "wbm7_page": "",
          "wbm8_title": "",
          "wbm8_link": "",
          "wbm8_page": "",
          "wbm9_title": "",
          "wbm9_link": "",
          "wbm9_page": "",
          "wbm10_title": "",
          "wbm10_link": "",
          "wbm10_page": ""
         
        },
        {
          "id": 24,
          "adi": "Kilit Arızaları | Genel Kurallar 1",
          "aciklama": "safsafa",
          "link": "edu/330/kilitler/24.JPG",
          "wbm1_title": "",
          "wbm1_link": "",
          "wbm1_page": "",
          "wbm2_title": "",
          "wbm2_link": "",
          "wbm2_page": "",
          "wbm3_title": "",
          "wbm3_link": "",
          "wbm3_page": "",
          "wbm4_title": "",
          "wbm4_link": "",
          "wbm4_page": "",
          "wbm5_title": "",
          "wbm5_link": "",
          "wbm5_page": "",
          "wbm6_title": "",
          "wbm6_link": "",
          "wbm6_page": "",
          "wbm7_title": "",
          "wbm7_link": "",
          "wbm7_page": "",
          "wbm8_title": "",
          "wbm8_link": "",
          "wbm8_page": "",
          "wbm9_title": "",
          "wbm9_link": "",
          "wbm9_page": "",
          "wbm10_title": "",
          "wbm10_link": "",
          "wbm10_page": ""
         
        },
        {
          "id": 25,
          "adi": "Kilit Arızaları | Genel Kurallar 2",
          "aciklama": "safsafa",
          "link": "edu/330/kilitler/25.JPG",
          "wbm1_title": "",
          "wbm1_link": "",
          "wbm1_page": "",
          "wbm2_title": "",
          "wbm2_link": "",
          "wbm2_page": "",
          "wbm3_title": "",
          "wbm3_link": "",
          "wbm3_page": "",
          "wbm4_title": "",
          "wbm4_link": "",
          "wbm4_page": "",
          "wbm5_title": "",
          "wbm5_link": "",
          "wbm5_page": "",
          "wbm6_title": "",
          "wbm6_link": "",
          "wbm6_page": "",
          "wbm7_title": "",
          "wbm7_link": "",
          "wbm7_page": "",
          "wbm8_title": "",
          "wbm8_link": "",
          "wbm8_page": "",
          "wbm9_title": "",
          "wbm9_link": "",
          "wbm9_page": "",
          "wbm10_title": "",
          "wbm10_link": "",
          "wbm10_page": ""
         
        },
        {
          "id": 26,
          "adi": "Kilit Arızaları | Genel Kurallar 3",
          "aciklama": "safsafa",
          "link": "edu/330/kilitler/26.JPG",
          "wbm1_title": "",
          "wbm1_link": "",
          "wbm1_page": "",
          "wbm2_title": "",
          "wbm2_link": "",
          "wbm2_page": "",
          "wbm3_title": "",
          "wbm3_link": "",
          "wbm3_page": "",
          "wbm4_title": "",
          "wbm4_link": "",
          "wbm4_page": "",
          "wbm5_title": "",
          "wbm5_link": "",
          "wbm5_page": "",
          "wbm6_title": "",
          "wbm6_link": "",
          "wbm6_page": "",
          "wbm7_title": "",
          "wbm7_link": "",
          "wbm7_page": "",
          "wbm8_title": "",
          "wbm8_link": "",
          "wbm8_page": "",
          "wbm9_title": "",
          "wbm9_link": "",
          "wbm9_page": "",
          "wbm10_title": "",
          "wbm10_link": "",
          "wbm10_page": ""
         
        },
        {
          "id": 27,
          "adi": "Kilit Arızaları | Genel Kurallar 4",
          "aciklama": "safsafa",
          "link": "edu/330/kilitler/27.JPG",
          "wbm1_title": "",
          "wbm1_link": "",
          "wbm1_page": "",
          "wbm2_title": "",
          "wbm2_link": "",
          "wbm2_page": "",
          "wbm3_title": "",
          "wbm3_link": "",
          "wbm3_page": "",
          "wbm4_title": "",
          "wbm4_link": "",
          "wbm4_page": "",
          "wbm5_title": "",
          "wbm5_link": "",
          "wbm5_page": "",
          "wbm6_title": "",
          "wbm6_link": "",
          "wbm6_page": "",
          "wbm7_title": "",
          "wbm7_link": "",
          "wbm7_page": "",
          "wbm8_title": "",
          "wbm8_link": "",
          "wbm8_page": "",
          "wbm9_title": "",
          "wbm9_link": "",
          "wbm9_page": "",
          "wbm10_title": "",
          "wbm10_link": "",
          "wbm10_page": ""
         
        },
        {
          "id": 28,
          "adi": "Simplified Latch Table 1",
          "aciklama": "safsafa",
          "link": "edu/330/kilitler/28.JPG",
          "wbm1_title": "",
          "wbm1_link": "",
          "wbm1_page": "",
          "wbm2_title": "",
          "wbm2_link": "",
          "wbm2_page": "",
          "wbm3_title": "",
          "wbm3_link": "",
          "wbm3_page": "",
          "wbm4_title": "",
          "wbm4_link": "",
          "wbm4_page": "",
          "wbm5_title": "",
          "wbm5_link": "",
          "wbm5_page": "",
          "wbm6_title": "",
          "wbm6_link": "",
          "wbm6_page": "",
          "wbm7_title": "",
          "wbm7_link": "",
          "wbm7_page": "",
          "wbm8_title": "",
          "wbm8_link": "",
          "wbm8_page": "",
          "wbm9_title": "",
          "wbm9_link": "",
          "wbm9_page": "",
          "wbm10_title": "",
          "wbm10_link": "",
          "wbm10_page": ""
         
        },
        {
          "id": 29,
          "adi": "Simplified Latch Table 2",
          "aciklama": "safsafa",
          "link": "edu/330/kilitler/29.JPG",
          "wbm1_title": "",
          "wbm1_link": "",
          "wbm1_page": "",
          "wbm2_title": "",
          "wbm2_link": "",
          "wbm2_page": "",
          "wbm3_title": "",
          "wbm3_link": "",
          "wbm3_page": "",
          "wbm4_title": "",
          "wbm4_link": "",
          "wbm4_page": "",
          "wbm5_title": "",
          "wbm5_link": "",
          "wbm5_page": "",
          "wbm6_title": "",
          "wbm6_link": "",
          "wbm6_page": "",
          "wbm7_title": "",
          "wbm7_link": "",
          "wbm7_page": "",
          "wbm8_title": "",
          "wbm8_link": "",
          "wbm8_page": "",
          "wbm9_title": "",
          "wbm9_link": "",
          "wbm9_page": "",
          "wbm10_title": "",
          "wbm10_link": "",
          "wbm10_page": ""
         
        },
        {
          "id": 30,
          "adi": "Simplified Latch Table 3",
          "aciklama": "safsafa",
          "link": "edu/330/kilitler/30.JPG",
          "wbm1_title": "",
          "wbm1_link": "",
          "wbm1_page": "",
          "wbm2_title": "",
          "wbm2_link": "",
          "wbm2_page": "",
          "wbm3_title": "",
          "wbm3_link": "",
          "wbm3_page": "",
          "wbm4_title": "",
          "wbm4_link": "",
          "wbm4_page": "",
          "wbm5_title": "",
          "wbm5_link": "",
          "wbm5_page": "",
          "wbm6_title": "",
          "wbm6_link": "",
          "wbm6_page": "",
          "wbm7_title": "",
          "wbm7_link": "",
          "wbm7_page": "",
          "wbm8_title": "",
          "wbm8_link": "",
          "wbm8_page": "",
          "wbm9_title": "",
          "wbm9_link": "",
          "wbm9_page": "",
          "wbm10_title": "",
          "wbm10_link": "",
          "wbm10_page": ""
         
        },
        {
          "id": 31,
          "adi": "Simplified Latch Table 4",
          "aciklama": "safsafa",
          "link": "edu/330/kilitler/31.JPG",
          "wbm1_title": "",
          "wbm1_link": "",
          "wbm1_page": "",
          "wbm2_title": "",
          "wbm2_link": "",
          "wbm2_page": "",
          "wbm3_title": "",
          "wbm3_link": "",
          "wbm3_page": "",
          "wbm4_title": "",
          "wbm4_link": "",
          "wbm4_page": "",
          "wbm5_title": "",
          "wbm5_link": "",
          "wbm5_page": "",
          "wbm6_title": "",
          "wbm6_link": "",
          "wbm6_page": "",
          "wbm7_title": "",
          "wbm7_link": "",
          "wbm7_page": "",
          "wbm8_title": "",
          "wbm8_link": "",
          "wbm8_page": "",
          "wbm9_title": "",
          "wbm9_link": "",
          "wbm9_page": "",
          "wbm10_title": "",
          "wbm10_link": "",
          "wbm10_page": ""
         
        },
        {
          "id": 32,
          "adi": "Simplified Latch Table 5",
          "aciklama": "safsafa",
          "link": "edu/330/kilitler/32.JPG",
          "wbm1_title": "",
          "wbm1_link": "",
          "wbm1_page": "",
          "wbm2_title": "",
          "wbm2_link": "",
          "wbm2_page": "",
          "wbm3_title": "",
          "wbm3_link": "",
          "wbm3_page": "",
          "wbm4_title": "",
          "wbm4_link": "",
          "wbm4_page": "",
          "wbm5_title": "",
          "wbm5_link": "",
          "wbm5_page": "",
          "wbm6_title": "",
          "wbm6_link": "",
          "wbm6_page": "",
          "wbm7_title": "",
          "wbm7_link": "",
          "wbm7_page": "",
          "wbm8_title": "",
          "wbm8_link": "",
          "wbm8_page": "",
          "wbm9_title": "",
          "wbm9_link": "",
          "wbm9_page": "",
          "wbm10_title": "",
          "wbm10_link": "",
          "wbm10_page": ""
         
        },
        
      ]
    },
    {
      "KonuTur": "330_english",
      "konular": [
        {
          "id": 1,
          "adi": "1. konu",
          "aciklama": "aciklama",
          "soru_e": "325",
          "soru_m": "325325",
          "soru_d": "325325",
          "link": "ss",
          "anket": "anket",
          "referans1": "anket",
          "referans1_aciklama": "anket",
          "referans1_link": "anket",
          "referans2": "anket",
          "referans2_aciklama": "anket",
          "referans2_link": "anket",
          "referans3": "anket",
          "referans3_aciklama": "anket",
          "referans23_link": "anket"
        },
        {
          "id": 2,
          "adi": "2. konu",
          "aciklama": "aciklama",
          "soru_e": "soru:",
          "soru_m": "soru:",
          "soru_d": "soru:",
          "link": "soru:",
          "anket": "anket",
          "referans1": "anket",
          "referans1_aciklama": "anket",
          "referans1_link": "anket",
          "referans2": "anket",
          "referans2_aciklama": "anket",
          "referans2_link": "anket",
          "referans3": "anket",
          "referans3_aciklama": "anket",
          "referans23_link": "anket"
        }
      ]
    }
  ];
  
  export default trainingDetails;
  