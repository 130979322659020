import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './assets/css/demo.css';
import './assets/vendor/libs/node-waves/node-waves.css';
import './assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.css';
import './assets/vendor/libs/typeahead-js/typeahead.css';
import './assets/vendor/libs/@form-validation/form-validation.css';
import './assets/vendor/css/rtl/core.css';
import './assets/vendor/css/rtl/theme-default.css';
import './assets/vendor/css/pages/page-auth.css';
import './assets/vendor/fonts/fontawesome.css';
import './assets/css/ek.css';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
 
    <App />
  
);