import { useState, useEffect } from 'react';
import {  useNavigate } from 'react-router-dom';
import db from '../components/GlobalDb';

import { BlockUI } from "ns-react-block-ui";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from '../config';





export default function Login() {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [blocking, setBlocking] = useState(false); // New state for blocking UI

  const usernameHandler = (event) => {
    setUsername(event.target.value);
  };

  const passwordHandler = (event) => {
    setPassword(event.target.value);
  };

  const submitHandler = (event) => {
    event.preventDefault();
    loginRequest();
    setBlocking(true); // Activate NSBlockUI
  };

  async function loginRequest() {
    try {
      const response = await fetch(`${config.MY_API}api/login`, {
        method: 'POST',
        body: JSON.stringify({
          username: username,
          password: password,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();

        if (data.status) {
          // Store token in Dexie
          await db.tokens.add({ token: data.status, name: data.name });

          // Redirect to home page
          navigate('/');
          setBlocking(false); 
        } else {
          // Display alert for unsuccessful login
          toast.error(data.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

  
          setBlocking(false); 
        }
      } else {
        throw new Error('Error');
        
      }
    } catch (error) {
      console.log(error.message);
      setBlocking(false); 
    }
  }



  useEffect(() => {
    // Check if user is already logged in (has a token stored)
    const checkToken = async () => {
      const tokens = await db.tokens.toArray();
      if (tokens.length > 0) {
        navigate('/');
      }
    };

    checkToken();
  }, [navigate]);

  return (
    <>

      <div className="container-xxl">
        <div className="authentication-wrapper authentication-basic container-p-y">
          <div className="authentication-inner py-4">
          <BlockUI blocking={blocking} loader="default">
            <div className="card">
              <div className="card-body">
                <div className="app-brand justify-content-center mb-4 mt-2">
                  <a href="index.html" className="app-brand-link gap-2">
                    <span className="app-brand-text demo text-body fw-bold ms-1">LES<pre style={{ fontSize: '9px', verticalAlign: 'super', overflow: 'hidden' }}>PRO MAX</pre></span>
                  </a>
                </div>
                <h4 className="mb-1 pt-2">Welcome to LES! 👋</h4>
                <p className="mb-4">Please sign-in to your account and start the adventure</p>
                <form id="formAuthentication" className="mb-3" onSubmit={submitHandler}>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">Username</label>

                    <input type="text" value={username} onChange={usernameHandler} className="form-control" placeholder="Enter your username" autoFocus />
                  </div>
                  <div className="mb-3 form-password-toggle">
                    <div className="d-flex justify-content-between">
                      <label className="form-label" htmlFor="password">Password</label>
                    </div>
                    <div className="input-group input-group-merge">

                      <input type="password" value={password} className="form-control" onChange={passwordHandler} aria-describedby="password" placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;" />
                      <span className="input-group-text cursor-pointer"><i className="ti ti-eye-off"></i></span>
                    </div>
                  </div>
                  <div className="mb-3">
                    <button className="btn btn-primary d-grid w-100">Sign in</button>
                  </div>
                </form>
                <div className="divider my-4"></div>
              </div>

            </div>
            </BlockUI>
          </div>
        </div>
      </div>
  
    </>


  );
}