import React, { useState, useEffect } from 'react';
import db from '../components/GlobalDb';
import { IconSchool, IconUserCheck, IconUserX, IconAlertTriangleFilled, IconEyeQuestion } from '@tabler/icons-react';
import { useParams } from 'react-router-dom';
import { Modal, Button, Collapse } from 'react-bootstrap';
import MyPdfViewer from '../components/MyPdfViewer'
import Question from '../components/Question'
import io from 'socket.io-client';
import config from '../config';
const socket = io(`${config.MY_SOCKET}`);




const UserForm = () => {
    const [name, setName] = useState('');
    const [namex, setNamex] = useState('');
    const [isNameSet, setIsNameSet] = useState(false);
    const { id } = useParams();
    const [showQuestion, setShowQuestion] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [isConnected, setIsConnected] = useState(false); // Socket.IO bağlantı durumunu takip eden state
    const [soruEkrani, setSoruEkrani] = useState('');
    const [instructor, setInstructor] = useState(''); // Eğitmen adı için durum
    const [courseName, setCourseName] = useState(''); // Kurs adı için durum


    // Soru güncellendiğinde çağrılacak fonksiyon
    const handleQuestionUpdate = () => {
        setRefresh(prev => !prev); // refresh state'ini değiştirerek useEffect'i tetikle
    };



    useEffect(() => {
        // Socket.IO bağlantısını başlat
        const socket = io(`${config.MY_SOCKET}`);

        socket.on('connect', () => {
            setIsConnected(true); // Bağlantı kurulduğunda state'i güncelle
        });

        socket.on('disconnect', () => {
            setIsConnected(false); // Bağlantı kesildiğinde state'i güncelle
        });

        return () => {
            socket.off('connect');
            socket.off('disconnect');
            socket.close(); // Komponent unmount edildiğinde socket bağlantısını kapat
        };
    }, []);


    useEffect(() => {
        const codex = id;
        const userId = name; // Bu satır aslında gereksiz, direkt name kullanılabilir

        // Kullanıcı bilgisini ve ders kodunu sunucuya gönder
        socket.emit('user-info', { userId: name, codex });

        socket.on('guncelleme', ({ soruekrani }) => {
            console.log('Yeni değerler:', soruekrani);
            setSoruEkrani(soruekrani);
        });

        return () => {
            socket.off('guncelleme');
        };
    }, [id, name]);



    useEffect(() => {
        const fetchCourseInfo = async () => {
            try {
                const response = await fetch(`${config.MY_API}api/check-info`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        classroom: id,
                    }),
                });
                const data = await response.json();
                if (data.success) {
                    // API'den gelen yanıtı doğru şekilde işle
                    setInstructor(data.message.instructer || 'Unknown Instructor'); // `instructer` yazım hatasını kontrol et
                    setCourseName(data.message.name); // Kurs adını ayarla
                }
            } catch (error) {
                console.error("API sorgusu sırasında bir hata oluştu:", error);
            }
        };
        fetchCourseInfo();
    }, [id]);



    const questionid = soruEkrani;


    useEffect(() => {
        const checkQuestionStatus = async () => {
            // API isteğini yapacak fonksiyon
            try {
              
                const response = await fetch(`${config.MY_API}api/check-my-question`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        qid: questionid,
                        user: name,
                        classroom: id,
                    }),
                });

                const data = await response.json();

                if (data.success && data.message === "bu soru cevaplanmamış") {
                    // Eğer API'den gelen yanıt koşulları sağlıyorsa, soruyu göster
                    setShowQuestion(true);
                
                } else {
                    // Koşullar sağlanmıyorsa, soruyu gösterme
                    setShowQuestion(false);
                 
                }
            } catch (error) {
                console.error("API sorgusu sırasında bir hata oluştu:", error);
                // Hata durumunda soruyu göstermeme
                setShowQuestion(false);
            }
        };

        checkQuestionStatus();
    }, [id, name, questionid, refresh]); // Bağımlılık listesi

    useEffect(() => {
        // EduId'ye göre info tablosunda name değerini kontrol et ve ayarla
        const checkAndSetName = async () => {
            const matchingRecords = await db.info.where({ eduId: id }).toArray();
            if (matchingRecords.length > 0) {
                const latestRecord = matchingRecords[matchingRecords.length - 1]; // En son kaydedilen kaydı al
                if (latestRecord.name) { // Eğer name alanı doluysa
                    setName(latestRecord.name);
                    setIsNameSet(true);
                }
            }
        };

        checkAndSetName();
    }, [id,namex,isNameSet]); // id değişkenini bağımlılık listesine ekleyin

    const handleNameSubmit = async () => {
        // Kullanıcı ismini info tablosuna kaydet
        await db.info.add({ name: namex.toUpperCase(), eduId: id });
        setIsNameSet(true);
       
        // Opsiyonel: Kayıt sonrası kullanıcı adını tekrar ayarlayabilirsiniz
        // Ancak burada gerekli değil, çünkü zaten `name` durumu doğru değeri içeriyor
    };



    return (
        <div>


            <div class="container-xxl container-p-y">
                <div class="misc-wrapper">



                    <div class="card mb-4">
                        <div class="card-body bg-less">
                            <h5 class="card-title">Welcome to <small>{courseName}</small> <IconSchool className='mb-1' size={32} stroke={2} color="#000" /></h5>

                            <p class="card-text">
                                Instructor: <b>{instructor}</b>
                            </p>
                            {
                                isNameSet ? (
                                    <><p class="card-text">
                                        Name: <b>{name}</b> <IconUserCheck className='mb-2' size={18} stroke={2} color="#000" />
                                    </p><p class="card-text">
                                            Status: <b>There is no action required, follow the training...</b>
                                        </p></>
                                ) : (


                                    <><p class="card-text">
                                        Name: <b>Guest</b> <IconUserX className='mb-2' size={18} stroke={2} color="#000" />
                                    </p>
                                        <p class="card-text">
                                            Status: <b>Need to Register 👇</b>
                                        </p></>
                                )
                            }

                            <p class="card-text">
                                Connection: <b>Online</b>
                            </p>
                            <p class="card-text">
                                <IconAlertTriangleFilled className='mb-1' size={32} stroke={2} color="#c9c500" /> Ensure you have the necessary documents for the training ready on your mobile device or computer to be able to answer the questions!
                            </p>
                        </div>
                    </div>

                    {!isNameSet && (
                        <div class="card mb-4">
                            <h5 class="card-header mb-2">Join the training</h5>
                            <div class="card-body">

                                <div>
                                    <div class="mb-3 row">
                                        <label for="html5-text-input" class="col-md-2 col-form-label">Name</label>
                                        <div class="col-md-10">
                                            <input class="form-control" type="text" placeholder="John Doe"
                                                value={namex}
                                                onChange={(e) => setNamex(e.target.value.toUpperCase())}
                                            />
                                        </div>
                                    </div>
                                    <div id="defaultFormControlHelp" class="form-text">
                                        Please enter your first and last name in the section above and click on the "Join" button to answer the questions assigned by the instructor. <br />

                                        If you are experiencing any issues, please inform the instructor.
                                    </div>
                                    <center>  <button type="button" onClick={handleNameSubmit} class="btn btn-success waves-effect waves-light mt-2">Join</button> </center>

                                </div>

                            </div>

                        </div>

                    )}









                    {showQuestion && questionid && isNameSet && <Question id={questionid} namex={name} classroom={id} onQuestionUpdate={handleQuestionUpdate} />}


















                </div>
            </div>





        </div>

    );

};

export default UserForm;
