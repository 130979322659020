import React from 'react';


const MyPdfViewer = (link) => {

  return (
    <div className='fhidden'>
    <iframe
      className="fullscreen-iframe" // Özel stil sınıfı
      src={link.link}
      allow="autoplay"
    ></iframe>
    </div>
  );
};

export default MyPdfViewer;
