import React, { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import db from '../components/GlobalDb';
import { Link, useNavigate } from 'react-router-dom';

const NameAbbreviation = ({ name }) => {
  if (!name) {
    return null; // eğer isim null ise, bileşeni render etme
  }

  const abbreviateName = (fullName) => {
    const words = fullName.split(' ');
    return words.map(word => word.slice(0, 1)).join('').toUpperCase();
  };

  return (
    <div>
   {abbreviateName(name)}
    </div>
  );
};

const Header = () => {
  
  const [token, setToken] = useState(null);
  const [namex, setnamex] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch token from Dexie on component mount
    const fetchToken = async () => {
      const tokens = await db.tokens.toArray();
      if (tokens.length > 0) {
        setToken(tokens[0].token);
        setnamex(tokens[0].name);
      } else {
        // Token not found, navigate to /login
        navigate('/login');
      }
    };
    fetchToken();
  }, [navigate]); 

  

  const handleLogout = async () => {
    // Remove all items from Dexie
    await db.tokens.clear();
    setToken(null);
  };

  
  return (

        <nav className="layout-navbar navbar navbar-expand-xl align-items-center bg-navbar-theme" id="layout-navbar">
          <div className="container-xxl">
            <div className="navbar-brand app-brand demo d-flex py-0 py-lg-2 me-4">
       
              <Link className="app-brand-link" to="/">
                <span className="app-brand-text demo menu-text fw-bold mt-2 ps-1">LES<pre style={{ fontSize: '9px', verticalAlign: 'super', overflow: 'hidden' }}>PRO MAX</pre></span>
                </Link>
              <a href="javascript:void(0);" className="layout-menu-toggle menu-link text-large ms-auto d-xl-none"></a>
            </div>

            <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
              <ul className="navbar-nav flex-row align-items-center ms-auto">

                {/* User */}
                <li className="nav-item navbar-dropdown dropdown-user dropdown" style={{ marginTop: '1px'}}>
      <Dropdown>
        <Dropdown.Toggle variant="link" className="nav-link dropdown-toggle hide-arrow" id="dropdown-basic">
        <div className="avatar avatar-md me-2" style={{ marginBottom: 15 }}>
            <span className="avatar-initial rounded-circle bg-primary"><NameAbbreviation name={namex} /></span>
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-menu-end" style={{ width: 'auto' }}>
          <Dropdown.Item href="#" >
          {namex}
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item href="#">
           Profil
          </Dropdown.Item>

          <Dropdown.Divider />
          <Dropdown.Item>
          <Link to="/logout" onClick={handleLogout}>
          Çıkış Yap
                  </Link>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </li>
  
                {/*/ User */}
              </ul>
            </div>
          </div>
        </nav>

    
  );
};

export default Header;
