import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../components/header';
import Navbar from '../components/Navbar';
import db from '../components/GlobalDb';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown, Modal, Form } from 'react-bootstrap';
import { IconDots, IconTrash, IconEdit, IconEye, IconLoader2, IconCircleArrowUpRightFilled } from '@tabler/icons-react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import 'primereact/resources/themes/saga-blue/theme.css'; // Tema
import 'primereact/resources/primereact.min.css'; // core css
import 'primeicons/primeicons.css'; // İkonlar
import config from '../config';
import { Toast } from 'primereact/toast';
import ReactQuill from 'react-quill';
import { Paginator } from 'primereact/paginator';
import { set } from 'date-fns';
import Flag from 'react-world-flags'
import { Editor } from 'primereact/editor';

const YeniEgitim = () => {
  const { id } = useParams();
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [visibleDialogs, setVisibleDialogs] = useState({});
  const [show, setShow] = useState(false);
  const [baslik, setbaslik] = useState('');
  const [sunumurl, setsunumurl] = useState('');
  const [sunumurleng, setsunumurleng] = useState('');
  const [baslikcat, setbaslikcat] = useState('tur');
  const [egitimAciklama, setEgitimAciklama] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [currentCourse, setCurrentCourse] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [coursesPerPage] = useState(10);
  const indexOfLastCourse = currentPage * coursesPerPage;
  const indexOfFirstCourse = indexOfLastCourse - coursesPerPage;
  const currentCourses = courses ? courses.slice(indexOfFirstCourse, indexOfLastCourse) : [];
  const [dialogShown, setDialogShown] = useState(false); // Dialog gösterim durumu için yeni state
  const toast = useRef(null);
  const [selectedEgitimler, setSelectedEgitimler] = useState([]);
  const [egitimListesi, setEgitimListesi] = useState([]);
  

  const isEmptyContent = (htmlString) => {
    const doc = new DOMParser().parseFromString(htmlString, "text/html");
    return !doc.body.textContent.trim() && !doc.querySelector('img');
  };


  useEffect(() => { // useEffect hook'unu ekliyoruz.
    fetchCourses();
  }, []); // Boş dizi, bu efektin yalnızca bileşen mount edildiğinde çalıştırılması gerektiğini belirtir.

  useEffect(() => {
    fetchEgitimListesi();
  }, []); 

  const fetchEgitimListesi = async () => {
    try {
      const tokenObject = await db.tokens.toCollection().first();
      if (!tokenObject) {
        throw new Error('Token bulunamadı.');
      }
      const token = tokenObject.token;
      const response = await fetch(`${config.MY_API}api/baslik-listele`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      });
      const data = await response.json();
      if (!data.success) {
        throw new Error('Eğitim listesi alınırken bir hata oluştu.');
      }
      // Eğitim listesini MultiSelect formatına çevir
      const egitimOptions = data.message.map(egitim => ({
        label: egitim.adi,
        value: egitim.id,
      }));
      setEgitimListesi(egitimOptions);
    } catch (error) {
      console.error('An error occurred:', error.message);
    }
  };
  

  const fetchCourses = async () => {
    setIsLoading(true);
    try {
      const tokenObject = await db.tokens.toCollection().first();
      if (!tokenObject) {
        throw new Error('Token bulunamadı.'); // Token yoksa hata fırlat
      }
      const token = tokenObject.token;
      const response = await fetch(`${config.MY_API}api/sunum-listele`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      });
      const data = await response.json();
      if (!data.success) {
        throw new Error('Eğitim listesi alınırken bir hata oluştu.');
      }
      if (data.message.length === 0) {
        setCourses([]); // courses'i boş bir dizi olarak ayarla
      } else {
        setCourses(data.message);
      }
    } catch (error) {
      console.error('An error occurred:', error.message);
      setCourses(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setShow(false);
    // Fetch notes again when the modal is closed
    resetForm();
  };


  const handleShow = () => {
    setShow(true);

  };
  const handleSubmit = async () => {
    if (!baslik.trim()) {
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Title cannot be empty.', life: 3000 });
      return;
    }

    if (selectedEgitimler.length === 0) {
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'At least one training program must be selected.', life: 3000 });
      return; // Fonksiyonu burada sonlandır
    }
  
    // Convert selected training IDs to a comma-separated string
    const selectedEgitimlerIds = selectedEgitimler.join(',');
  
    try {
      const tokenObject = await db.tokens.toCollection().first();
      if (!tokenObject) {
        throw new Error('Token not found.');
      }
      const token = tokenObject.token;
      const response = await fetch(`${config.MY_API}api/sunum-kaydet`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        body: JSON.stringify({
          sunumAdi: baslik,
          baslikCat: selectedEgitimlerIds,
          sunumLink: sunumurl,
          sunumLink2: sunumurleng,
          sunumDesc: egitimAciklama
        }),
      });
      const data = await response.json();
  
      if (!data.success) {
        throw new Error('Error occurred while saving the training.');
      }
      // On success
      setShow(false); // Close the modal
      fetchCourses(); // Refresh the course list
    } catch (error) {
      console.error('An error occurred:', error.message);
      // Show error message to the user
      toast.current.show({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 });
    }
    resetForm();
  };



  const toggleDialogVisibility = (courseId) => {
    setVisibleDialogs(prevState => ({
      ...prevState,
      [courseId]: !prevState[courseId]
    }));
  };



  
  const deleteCourse = (baslikId) => {
   
    

      confirmDialog({
        message: 'Bu eğitim başlığını silmek istediğinize emin misiniz?',
        header: 'Silme Onayı',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          performDelete(baslikId);
    
        },
        reject: () => {
          performclamn(baslikId);
        }
      });
    
  };


  
  const performclamn = async (baslikId) => {
    fetchCourses(); 


  
};

  const performDelete = async (baslikId) => {
    const tokenObject = await db.tokens.toCollection().first();
    if (!tokenObject) {
      throw new Error('Token bulunamadı.');
    }
    const token = tokenObject.token;
    try {

      const response = await fetch(`${config.MY_API}api/sunum-silx`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token, // Token'ınızı buraya ekleyin
        },
        body: JSON.stringify({ baslikId }),
      });
      const data = await response.json();
      if (data.success) {
        // Silme işlemi başarılı
        console.log('Eğitim başarıyla silindi.');
        fetchCourses(); // Eğitim listesini yeniden çek
      } else {
        // Silme işlemi başarısız
        console.error('Eğitim silinirken bir hata oluştu.');
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };


  const handleUpdate = async () => {
    if (!baslik.trim()) {
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Title cannot be empty.', life: 3000 });
      return;
    }

    if (selectedEgitimler.length === 0) {
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'At least one training program must be selected.', life: 3000 });
      return; // Fonksiyonu burada sonlandır
    }
    // Convert selected training IDs to a comma-separated string for updating
    const selectedEgitimlerIds = selectedEgitimler.join(',');
  
    try {
      const tokenObject = await db.tokens.toCollection().first();
      if (!tokenObject) {
        throw new Error('Token not found.');
      }
      const token = tokenObject.token;
      const response = await fetch(`${config.MY_API}api/sunum-duzenlex`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        body: JSON.stringify({
          baslikId: currentCourse.id,
          sunumAdi: baslik,
          baslikCat: selectedEgitimlerIds,
          sunumLink: sunumurl,
          sunumLink2: sunumurleng,
          sunumDesc: egitimAciklama
        }),
      });
      const data = await response.json();
      if (!data.success) {
        throw new Error('Error occurred while updating the training.');
      }
      setShow(false); // Close the modal
      fetchCourses(); // Refresh the course list
    } catch (error) {
      console.error('An error occurred:', error.message);
    }
  };

  const handleEdit = (course) => {
    setCurrentCourse(course);
    setIsEdit(true);
    setbaslik(course.adi);
    setsunumurl(course.link);
    setsunumurleng(course.link2);
    setEgitimAciklama(course.aciklama);
  
    // 'programlar' değerinin null veya undefined olup olmadığını kontrol et
    // Ve güvenli bir şekilde işle.
    const programIds = course.baslik ? course.baslik.split(',') : [];
  
    setSelectedEgitimler(programIds);
    setShow(true);
  };
  const resetForm = () => {
    setbaslik('');
    setbaslikcat('');
    setSelectedEgitimler([]);
    setsunumurleng('');
    setsunumurl('');
    setEgitimAciklama('');

    setIsEdit(false);
    setCurrentCourse(null);
  };
  console.log("selectedEgitimler:", selectedEgitimler);
  console.log("egitimListesi:", egitimListesi);
  return (
    <div id="layout-wrapperx" className="layout-wrapper layout-navbar-full layout-horizontal layout-without-menu">
       <Toast ref={toast} />
      <Navbar />
      <div id="content-wrapperx" className="layout-container">
        <Header />
        <div className="layout-page">
          <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
              <div className="row mb-4 g-4">
                <div className="col-12 col-xl-12 col-md-12">
                <Card>
    <div className="card-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <h2>Eğitim Sunumları</h2>
      <Button className='mb-2' icon="pi pi-plus" severity="secondary" onClick={handleShow} text raised />
    </div>
    {isLoading ? (
      <p>Yükleniyor... &nbsp;&nbsp;<IconLoader2 className="spin" size={20} /></p>
    ) : courses && courses.length > 0 ? (
      <div className="table-responsive text-nowrap">
        <table className="table table-striped">
          <thead className="table-light">
            <tr>
              <th>Eğitim Başlığı</th>
              <th><center>Mevcut Diller</center></th>
              <th><center>İşlemler</center></th>
            </tr>
          </thead>
          <tbody className="table-border-bottom-0">
            {currentCourses.map(course => (
              <tr key={course.id}>
                <td>{course.adi}</td>
                         <td><center>
              {course.link && (
               <Flag code="tur" height="16"  />
              )}
              {course.link2 && (
                <Flag code="usa" height="16" style={{ marginLeft: '5px' }} />
              )}</center>
            </td>
                <td><center>
                  <Dropdown>
                    <Dropdown.Toggle variant="link" id="dropdown-basic">
                      <IconDots size={32} stroke={1} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => handleEdit(course)}>
                        <IconEdit size={22} stroke={2} /> Düzenle
                      </Dropdown.Item>
           
                      <Dropdown.Item onClick={() => deleteCourse(course.id)}>
                        <IconTrash size={22} stroke={2} />  Sil
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </center></td>
           
                <ConfirmDialog />
              </tr>
            ))}
          </tbody>
        </table>
        <Paginator
          first={indexOfFirstCourse}
          rows={coursesPerPage}
          totalRecords={courses ? courses.length : 0}
          onPageChange={(e) => setCurrentPage(e.page + 1)}
        />
      </div>
    ) : (
      <p>
      Eğitim sunumlarınız, kendilerine Hogwarts'tan kabul mektubu gelmiş gibi ansızın ortadan kayboldular! Yeni bir sunum eklemek için + işaretini kullanın.  <IconCircleArrowUpRightFilled size={22} stroke={2} /> </p>
    )}
  </Card>
                </div>
              </div>
            </div>
            <div className='mb-5'></div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{isEdit ? 'Sunum Düzenle' : 'Yeni Sunum'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form
    onSubmit={(e) => {
      e.preventDefault();
      isEdit ? handleUpdate() : handleSubmit();
    }}
  >
            <Form.Group className="mb-3">
              <Form.Label>Sunum Başlığı</Form.Label>
              <Form.Control
                type="text"
                placeholder="Sunumlarınızı gruplandırmak için bir başlık girin."
                value={baslik}
                onChange={(e) => setbaslik(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Türkçe Google Sunumları URL'si</Form.Label>
              <Form.Control
                type="text"
                placeholder="ÖRN: https://docs.google.com/presentation/d/15cRl6moBZSvD15cMkhZbLmXZ8uR2DqjP/preview"
                value={sunumurl}
                onChange={(e) => setsunumurl(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>İngilizce Google Sunumları URL'si</Form.Label>
              <Form.Control
                type="text"
                placeholder="ÖRN: https://docs.google.com/presentation/d/15cRl6moBZSvD15cMkhZbLmXZ8uR2DqjP/preview"
                value={sunumurleng}
                onChange={(e) => setsunumurleng(e.target.value)}
              />
            </Form.Group>
            
            <Form.Group className="mb-3">
              <Form.Label>Dahil olunan eğitim başlığı</Form.Label><br />
              <MultiSelect
  value={selectedEgitimler} // Seçili olan öğelerin değerlerini içeren state'i kullanın
  options={egitimListesi} // Eğitim listesini options olarak kullanın
  optionLabel="label" // options içindeki her bir öğenin label alanını belirtin
  optionValue="value" // options içindeki her bir öğenin value alanını belirtin
  onChange={(e) => setSelectedEgitimler(e.value)}
  placeholder="Eğitim programlarını seçin"
/>

            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Sunum Açıklaması</Form.Label>

                <Editor  value={egitimAciklama} onTextChange={(e) => setEgitimAciklama(e.htmlValue)} style={{ height: '320px' }} />
            </Form.Group>
       
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button label="İptal" icon="pi pi-times" severity="danger" onClick={handleClose}  raised />
      
          <Button severity="primary" icon="pi pi-save" label={isEdit ? 'Güncelle' : 'Kaydet'} type="submit" raised onClick={isEdit ? handleUpdate : handleSubmit} />

        </Modal.Footer>
      </Modal>
    </div>
  );


};

export default YeniEgitim;
