import { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { jwtDecode } from 'jwt-decode';
import db from '../components/GlobalDb';
import { Modal, Form } from 'react-bootstrap';  // Import Modal and Button from Bootstrap
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import config from '../config';
import 'primereact/resources/themes/saga-blue/theme.css'; // Tema
import 'primereact/resources/primereact.min.css'; // core css
import 'primeicons/primeicons.css'; // İkonlar
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import ReactQuill from 'react-quill';
import { formatRelative, parse, set } from 'date-fns';
import trLocale from 'date-fns/locale/tr';
import { IconSquareRoundedPlusFilled, IconPlus, IconBulb, IconX, IconDots, IconSettings, IconTrash, IconArrowLeft, IconArrowRight, IconDeviceFloppy, IconSquareRoundedMinus, IconRefresh, IconLoader2, IconNote } from '@tabler/icons-react';
import { Editor } from 'primereact/editor';
import { Paginator } from 'primereact/paginator';




const MySwal = withReactContent(Swal);



export default function CreateClassroom() {
    const [className, setClassName] = useState('');
    const [classCreationError, setClassCreationError] = useState(null);
  
    const [showModal, setShowModal] = useState(false);  // State to control the modal visibility
    const [importance, setImportance] = useState('normal'); // Default importance

    const [newTitle, setNewTitle] = useState('');

    const [selectedNote, setSelectedNote] = useState(null); // New state to track selected note
  
    const [text, setText] = useState('');
    const [myCourses, setMyCourses] = useState([]);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(5); // Display 5 items per page

   

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);
    const fetchMyCourses = async () => {
        try {
            const tokenObject = await db.tokens.toCollection().first();
            if (tokenObject) {
                const token = tokenObject.token;

                const response = await fetch(`${config.MY_API}api/notlar-listele`, {
                    headers: {
                        Authorization: 'Bearer ' + token,
                    },
                });

                const responseData = await response.json();

                if (response.ok && responseData.success) {
                    setMyCourses(responseData.message);
                } else {
                    throw new Error(responseData.message || 'Error fetching courses');
                }
            } else {
                throw new Error('No token found');
            }
        } catch (error) {
            setClassCreationError(error.message);
        }
    };

    const onPaginate = (event) => {
        setFirst(event.first);
        setRows(event.rows);
    };

    // Calculating the courses to display based on pagination
    const displayedCourses = myCourses.slice(first, first + rows);

    useEffect(() => {
        fetchMyCourses();

    }, []);


    const navigate = useNavigate();

   

    const handleClassCreation = async () => {
        try {
            const tokenObject = await db.tokens.toCollection().first();
            if (tokenObject) {
                const token = tokenObject.token;

                const response = await fetch(`${config.MY_API}api/notlar-kaydet`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token,
                    },
                    body: JSON.stringify({
                        importance: selectedNote?.importance, // Seçilen eğitimin ID'si
                        title: newTitle, // Seçilen eğitimin adı
                        content: text, // Seçilen dil
                    }),
                });

                const responseData = await response.json();

                if (response.ok && responseData.success) {
                    // Handle successful classroom creation
                    console.log('Classroom created successfully');
                    // After creating the classroom, fetch the updated list of courses
                    fetchMyCourses();
                } else {
                    throw new Error(responseData.message || 'Error creating classroom');
                }
            } else {
                throw new Error('No token found');
            }
        } catch (error) {
            setClassCreationError(error.message);
        }
    };





    const performDeleteAction = async (classroomId) => {
        try {
            const tokenObject = await db.tokens.toCollection().first();
            if (tokenObject) {
                const token = tokenObject.token;

                const response = await fetch(`${config.MY_API}api/notlar-silx`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token,
                    },
                    body: JSON.stringify({
                        notId: classroomId,
                    }),
                });

                const responseData = await response.json();

                if (response.ok && responseData.success) {
                    // Handle successful classroom deletion
                    console.log('Classroom deleted successfully');
                    // After deleting the classroom, fetch the updated list of courses
                    setShowModal(false);
                    fetchMyCourses();
                } else {
                    throw new Error(responseData.message || 'Error deleting classroom');
                }
            } else {
                throw new Error('No token found');
            }
        } catch (error) {
            setClassCreationError(error.message);
        }
    };




    const handleModalSave = async () => {
        // Check if we are updating an existing note or creating a new one
        const endpoint = selectedNote?.id ? `${config.MY_API}api/notlar-duzenlex` : `${config.MY_API}api/notlar-kaydet`;
        const method = selectedNote?.id ? 'POST' : 'POST'; // Assuming 'PUT' for updates in your API
    
        try {
            const tokenObject = await db.tokens.toCollection().first();
            if (tokenObject) {
                const token = tokenObject.token;
                const response = await fetch(endpoint, {
                    method: method,
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token,
                    },
                    body: JSON.stringify({
                        id: selectedNote?.id, // Send note ID for update
                        importance: importance, // Use the state directly
                        title: newTitle,
                        content: text,
                    }),
                });
    
                const responseData = await response.json();
    
                if (response.ok && responseData.success) {
                    // Handle success
                    console.log('Operation successful');
                    fetchMyCourses(); // Refresh notes list
                    handleCloseModal(); // Close the modal
                } else {
                    throw new Error(responseData.message || 'Error performing operation');
                }
            } else {
                throw new Error('No token found');
            }
        } catch (error) {
            setClassCreationError(error.message);
        }
    };

    const resetModalState = () => {
        setSelectedNote(null); // Clear the selected note
        setNewTitle(''); // Reset the title
        setText(''); // Clear the text content
        
    };
    

    function formatDate(dateString) {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Ay 0'dan başladığı için +1 ekliyoruz
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');

        return `${day}-${month}-${year} ${hours}:${minutes}`;
    }


    const getImportanceClassName = (importance) => {

        switch (importance) {
            case 'yüksek':
                return 'danger'; // Use 'bg-danger' for high importance
            case 'normal':
                return 'warning'; // Use 'bg-warning' for normal importance
            case 'düşük':
                return 'success'; // Use 'bg-success' for low importance
            default:
                return 'warning'; // Default to 'bg-warning'
        }
    };

    const handleEdit = (note) => {
        setSelectedNote(note); // Set the currently selected note
        setImportance(note.importance); // Set importance for editing
        setNewTitle(note.title); // Set title for editing
        setText(note.content); // Set content for editing
        setShowModal(true); // Open the modal in edit mode
    };
  
// Helper function to parse 'YYYY-MM-DD HH:mm:ss' format
const parseDateString = (dateString) => {
    const [date, time] = dateString.split(' ');
    const [year, month, day] = date.split('-').map(val => parseInt(val, 10));
    const [hours, minutes, seconds] = time.split(':').map(val => parseInt(val, 10));
    // Note: months are 0-based in JavaScript Date
    return new Date(year, month - 1, day, hours, minutes, seconds);
};

const formatDateRelative = (timestamp) => {
    if (!timestamp) return '';

    // Use the helper function to parse your custom date-time format
    const date = parseDateString(timestamp);

    return formatRelative(date, new Date(), { locale: trLocale });
};

    return (

        <Card>



            <div className="card-header mx-2" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h2>Kişisel Notlarım</h2>
                <Button className='mb-2' icon="pi pi-plus" severity="secondary" onClick={() => { resetModalState(); handleShowModal(); }} text raised />
            </div>








          
            <div class="list-group">
  {myCourses.length > 0 ? (
    <div>
      {displayedCourses.map((course) => (
        <span className="list-group-item list-group-item-action d-flex justify-content-between" key={course.id} onClick={() => handleEdit(course)}>
          <div className="li-wrapper d-flex justify-content-start align-items-center">
            <div className="avatar avatar-sm me-3">
              <span className={`avatar-initial rounded-circle bg-label-${getImportanceClassName(course.importance)}`}><IconNote size={18} stroke={1} /></span>
            </div>
            <div className="list-content">
              <h6 className="mb-1">{course.title}</h6>
              <small className="text-muted" dangerouslySetInnerHTML={{ __html: course.content ? course.content.slice(0, 80) + (course.content.length > 80 ? '...' : '') : '' }}></small>
            </div>
          </div>
          <small>{formatDateRelative(course.createdat)}</small>
        </span>
      ))}
      <Paginator first={first} rows={rows} totalRecords={myCourses.length} onPageChange={onPaginate}></Paginator>
    </div>
  ) : (
    <div className="text-center mt-2" style={{minHeight:400}}>
      <p>Görünüşe göre hiç notunuz yok. Belki biraz dinlenmenin zamanı gelmiştir, ya da yeni bir şeyler öğrenmeye başlama zamanı. Yeni bir şeyler bulduğunda + işaretini kullan...</p>
    </div>
  )}
</div>
           
            <Modal show={showModal} onHide={handleCloseModal} size="lg">
    <Modal.Header closeButton>
        <Modal.Title>{selectedNote ? 'Düzenle' : 'Yeni Eğitim'}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <div className="mb-3">
            <Form.Group controlId="formTitle">
                <Form.Label className='form-label'>Başlık</Form.Label>
                <Form.Control
                    className='form-control'
                    type="text"
                    placeholder="Not başlığını girin"
                    value={newTitle}
                    onChange={(e) => setNewTitle(e.target.value)}
                />
            </Form.Group>
        </div>

        <div className="mb-5">
            <Form.Group className="mb-3">
                <Form.Label>İçerik</Form.Label><br />
                <Editor value={text} onTextChange={(e) => setText(e.htmlValue)} style={{ height: '320px' }} />
            </Form.Group>
        </div>

        <Form.Group controlId="formImportance">
            <Form.Label>Önem Derecesi</Form.Label>
            <Form.Control as="select" value={importance} onChange={(e) => setImportance(e.target.value)}>
    <option value="yüksek">Yüksek</option>
    <option value="normal">Normal</option>
    <option value="düşük">Düşük</option>
</Form.Control>
        </Form.Group>
    </Modal.Body>
    <Modal.Footer>
    {selectedNote ? (
        <Button label="Sil" icon="pi pi-trash" severity="danger" onClick={() => performDeleteAction(selectedNote.id)} raised />
    ) : (
        <Button label="İptal" icon="pi pi-times" severity="danger" onClick={handleCloseModal} raised />
    )}
    <Button severity="primary" icon="pi pi-save" label={selectedNote ? 'Güncelle' : 'Kaydet'} type="submit" raised onClick={handleModalSave} />
</Modal.Footer>
</Modal>

        </Card>

    );
}
