import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../components/header';
import Navbar from '../components/Navbar';
import db from '../components/GlobalDb';

import { Dropdown, Modal, Form } from 'react-bootstrap';
import { IconDots, IconTrash, IconEdit, IconEye, IconLoader2, IconCircleArrowUpRightFilled } from '@tabler/icons-react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import 'primereact/resources/themes/saga-blue/theme.css'; // Tema
import 'primereact/resources/primereact.min.css'; // core css
import 'primeicons/primeicons.css'; // İkonlar
import config from '../config';
import Flag from 'react-world-flags'
import { Toast } from 'primereact/toast';
import ReactQuill from 'react-quill';
import { Paginator } from 'primereact/paginator';
import { Editor } from 'primereact/editor';


const YeniEgitim = () => {
  const { id } = useParams();
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [visibleDialogs, setVisibleDialogs] = useState({});
  const [show, setShow] = useState(false);
  const [egitimAdi, setEgitimAdi] = useState('');
  const [egitimDili, setEgitimDili] = useState('tur');
  const [egitimAciklama, setEgitimAciklama] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [currentCourse, setCurrentCourse] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [coursesPerPage] = useState(10);
  const indexOfLastCourse = currentPage * coursesPerPage;
  const indexOfFirstCourse = indexOfLastCourse - coursesPerPage;
  const currentCourses = courses ? courses.slice(indexOfFirstCourse, indexOfLastCourse) : [];
  const [dialogShown, setDialogShown] = useState(false); // Dialog gösterim durumu için yeni state
  const toast = useRef(null);


  const isEmptyContent = (htmlString) => {
    const doc = new DOMParser().parseFromString(htmlString, "text/html");
    return !doc.body.textContent.trim() && !doc.querySelector('img');
  };


  useEffect(() => { // useEffect hook'unu ekliyoruz.
    fetchCourses();
  }, []); // Boş dizi, bu efektin yalnızca bileşen mount edildiğinde çalıştırılması gerektiğini belirtir.

  const fetchCourses = async () => {
    setIsLoading(true);
    try {
      const tokenObject = await db.tokens.toCollection().first();
      if (!tokenObject) {
        throw new Error('Token bulunamadı.'); // Token yoksa hata fırlat
      }
      const token = tokenObject.token;
      const response = await fetch(`${config.MY_API}api/egitim-listele`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      });
      const data = await response.json();
      if (!data.success) {
        throw new Error('Eğitim listesi alınırken bir hata oluştu.');
      }
      if (data.message.length === 0) {
        setCourses([]); // courses'i boş bir dizi olarak ayarla
      } else {
        setCourses(data.message);
      }
    } catch (error) {
      console.error('An error occurred:', error.message);
      setCourses(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setShow(false);
    // Fetch notes again when the modal is closed
    resetForm();
  };


  const handleShow = () => {
    setShow(true);

  };

  const handleSubmit = async () => {
    if (!egitimAdi.trim()) {
      toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Eğitim adı boş bırakılamaz.', life: 3000 });
      return;
    }
    if (isEmptyContent(egitimAciklama)) {
      toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Eğitim açıklaması boş bırakılamaz.', life: 3000 });
      return;
    }
    try {

      
      const tokenObject = await db.tokens.toCollection().first();
      if (!tokenObject) {
        throw new Error('Token bulunamadı.');
      }
      const token = tokenObject.token;
      const response = await fetch(`${config.MY_API}api/egitim-kaydet`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        body: JSON.stringify({
          egitimAdi,
          egitimDili,
          egitimAciklama,
        }),
      });
      const data = await response.json();
    
      if (!data.success) {
        throw new Error('Eğitim kaydedilirken bir hata oluştu.');
      }
      // İşlem başarılı ise
      setShow(false); // Modalı kapat
      fetchCourses(); // Eğitim listesini yenile
    } catch (error) {
      console.error('An error occurred:', error.message);
      // Hata mesajını kullanıcıya göster
      toast.current.show({ severity: 'error', summary: 'Hata', detail: error.message, life: 3000 });
    }
    resetForm();
  };
  



  const toggleDialogVisibility = (courseId) => {
    setVisibleDialogs(prevState => ({
      ...prevState,
      [courseId]: !prevState[courseId]
    }));
  };



  
  const deleteCourse = (egitimId) => {
   
    

      confirmDialog({
        message: 'Bu eğitimi silmek istediğinize emin misiniz?',
        header: 'Silme Onayı',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          performDelete(egitimId);
    
        },
        reject: () => {
          performclamn(egitimId);
        }
      });
    
  };


  
  const performclamn = async (egitimId) => {
    fetchCourses(); 


  
};

  const performDelete = async (egitimId) => {
    const tokenObject = await db.tokens.toCollection().first();
    if (!tokenObject) {
      throw new Error('Token bulunamadı.');
    }
    const token = tokenObject.token;
    try {

      const response = await fetch(`${config.MY_API}api/egitim-silx`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token, // Token'ınızı buraya ekleyin
        },
        body: JSON.stringify({ egitimId }),
      });
      const data = await response.json();
      if (data.success) {
        // Silme işlemi başarılı
        console.log('Eğitim başarıyla silindi.');
        fetchCourses(); // Eğitim listesini yeniden çek
      } else {
        // Silme işlemi başarısız
        console.error('Eğitim silinirken bir hata oluştu.');
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };


  const handleUpdate = async () => {
    if (!egitimAdi.trim()) {
      toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Eğitim adı boş bırakılamaz.', life: 3000 });
      return;
    }
    if (isEmptyContent(egitimAciklama)) {
      toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Eğitim açıklaması boş bırakılamaz.', life: 3000 });
      return;
    }
    try {
      const tokenObject = await db.tokens.toCollection().first();
      if (!tokenObject) {
        throw new Error('Token bulunamadı.');
      }
      const token = tokenObject.token;
      const response = await fetch(`${config.MY_API}api/egitim-duzenlex`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        body: JSON.stringify({
          egitimId: currentCourse.id,
          egitimAdi,
          egitimDili,
          egitimAciklama,
        }),
      });
      const data = await response.json();
      if (!data.success) {
        throw new Error('Eğitim güncellenirken bir hata oluştu.');
      }
      setShow(false); // Modalı kapat
      fetchCourses(); // Eğitim listesini yenile
    } catch (error) {
      console.error('An error occurred:', error.message);
    }
  };
  const handleEdit = (course) => {
    setCurrentCourse(course);
    setIsEdit(true);
    setEgitimAdi(course.adi);
    setEgitimDili(course.icon);
    setEgitimAciklama(course.aciklama);
    setShow(true);

  };

  const resetForm = () => {
    setEgitimAdi('');
    setEgitimDili('tur');
    setEgitimAciklama('');
    setIsEdit(false);
    setCurrentCourse(null);
  };

  return (
    <div id="layout-wrapperx" className="layout-wrapper layout-navbar-full layout-horizontal layout-without-menu">
       <Toast ref={toast} />
      <Navbar />
      <div id="content-wrapperx" className="layout-container">
        <Header />
        <div className="layout-page">
          <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
              <div className="row mb-4 g-4">
                <div className="col-12 col-xl-12 col-md-12">
                <Card>
    <div className="card-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <h2>Eğitim Programları</h2>
      <Button className='mb-2' icon="pi pi-plus" severity="secondary" onClick={handleShow} text raised />
    </div>
    {isLoading ? (
      <p>Yükleniyor... &nbsp;&nbsp;<IconLoader2 className="spin" size={20} /></p>
    ) : courses && courses.length > 0 ? (
      <div className="table-responsive text-nowrap">
        <table className="table table-striped">
          <thead className="table-light">
            <tr>
              <th>Eğitim Programı</th>
              <th>Dil</th>
              <th><center>İşlemler</center></th>
            </tr>
          </thead>
          <tbody className="table-border-bottom-0">
            {currentCourses.map(course => (
              <tr key={course.id}>
                <td>{course.adi}</td>
                <td><Flag code={course.icon} height="16" /></td>
                <td><center>
                  <Dropdown>
                    <Dropdown.Toggle variant="link" id="dropdown-basic">
                      <IconDots size={32} stroke={1} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => handleEdit(course)}>
                        <IconEdit size={22} stroke={2} /> Düzenle
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => toggleDialogVisibility(course.id)}>
                        <IconEye size={22} stroke={2} />  Görüntüle
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => deleteCourse(course.id)}>
                        <IconTrash size={22} stroke={2} />  Sil
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </center></td>
                <Dialog header={course.adi} visible={!!visibleDialogs[course.id]} style={{ width: '50vw' }} onHide={() => toggleDialogVisibility(course.id)}>
                  <div dangerouslySetInnerHTML={{ __html: course.aciklama }}></div>
                </Dialog>
                <ConfirmDialog />
              </tr>
            ))}
          </tbody>
        </table>
        <Paginator
          first={indexOfFirstCourse}
          rows={coursesPerPage}
          totalRecords={courses ? courses.length : 0}
          onPageChange={(e) => setCurrentPage(e.page + 1)}
        />
      </div>
    ) : (
      <p>Şu anda burada gösterilecek bir eğitim programı yok. Belki de tüm veriler mezun oldu ya da tatile çıktılar! 🎓🏖️ Yeni bir şeyler eklemeye ne dersin?    <IconCircleArrowUpRightFilled size={22} stroke={2} /> </p>
    )}
  </Card>
                </div>
              </div>
            </div>
            <div className='mb-5'></div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{isEdit ? 'Eğitim Programını Düzenle' : 'Yeni Eğitim Programı'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form
    onSubmit={(e) => {
      e.preventDefault();
      isEdit ? handleUpdate() : handleSubmit();
    }}
  >
            <Form.Group className="mb-3">
              <Form.Label>Eğitim Programı Adı</Form.Label>
              <Form.Control
                type="text"
                placeholder="Eğitim programı adı girin"
                value={egitimAdi}
                onChange={(e) => setEgitimAdi(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Eğitim Dili</Form.Label>
              <Form.Control
                as="select"
                value={egitimDili}
                onChange={(e) => setEgitimDili(e.currentTarget.value)}
              >
                <option value="tur">Türkçe</option>
                <option value="usa">English</option>
                {/* Diğer dilleri buraya ekleyin */}
              </Form.Control>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Eğitim Açıklaması</Form.Label>
              <Editor 
                        value={egitimAciklama}
                onTextChange={(e) => setEgitimAciklama(e.htmlValue)}
                style={{ height: '320px' }} />
            </Form.Group>
       
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button label="İptal" icon="pi pi-times" severity="danger" onClick={handleClose}  raised />
      
          <Button severity="primary" icon="pi pi-save" label={isEdit ? 'Güncelle' : 'Kaydet'} type="submit" raised onClick={isEdit ? handleUpdate : handleSubmit} />

        </Modal.Footer>
      </Modal>
    </div>
  );


};

export default YeniEgitim;
