
import React from 'react';
import '../assets/css/330_lir.css';

const EntegreLir = () => {
  return (
    <div className='basexx'>
     
<table className="pagtable" >
   <tr>
      <td id="POS_A">
         <div id="POS_A" className="singlepag" >
            <div id="POS_A" className="drop nesz">
               <div id="positioname" className="positionname ">A</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtype"></div>
               <div id="uldno" className="uldno"></div>
               <div id="uldkg" className="uldkg"></div>
               <div  className="nexx" >
                  <div className="arrowrightsingle"></div>
                  <div id="arrowup" className="arrowupsingle" ></div>
                  <div className="arrowleftsingle"></div>
                  <div className="arrowdownsingle"></div>
                  <div id="maxkg" className="maxkgsinglepag">2826</div>
               </div>
               <div id="dginfo" className="dginfo"></div>
            </div>
         </div>
      </td>
      <td id="POS_B">
         <div id="POS_B" className="singlepag" >
            <div id="POS_B" className="drop nesz">
               <div id="positioname" className="positionname ">B</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtype"></div>
               <div id="uldno" className="uldno"></div>
               <div id="uldkg" className="uldkg"></div>
               <div  className="nexx" >
                  <div className="arrowrightsingle"></div>
                  <div id="arrowup" className="arrowupsingle" ></div>
                  <div className="arrowleftsingle"></div>
                  <div className="arrowdownsingle"></div>
                  <div id="maxkg" className="maxkgsinglepag">3123</div>
               </div>
               <div id="dginfo" className="dginfo"></div>
            </div>
         </div>
      </td>
      <td id="POS_C">
         <div id="POS_C" className="singlepag" >
            <div id="POS_C" className="drop nesz">
               <div id="positioname" className="positionname ">C</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtype"></div>
               <div id="uldno" className="uldno"></div>
               <div id="uldkg" className="uldkg"></div>
               <div  className="nexx" >
                  <div className="arrowrightsingle"></div>
                  <div id="arrowup" className="arrowupsingle" ></div>
                  <div className="arrowleftsingle"></div>
                  <div className="arrowdownsingle"></div>
                  <div id="maxkg" className="maxkgsinglepag">3391</div>
               </div>
               <div id="dginfo" className="dginfo"></div>
            </div>
         </div>
      </td>
      <td id="POS_D">
         <div id="POS_D" className="singlepag" >
            <div id="POS_D" className="drop nesz">
               <div id="positioname" className="positionname ">D</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtype"></div>
               <div id="uldno" className="uldno"></div>
               <div id="uldkg" className="uldkg"></div>
               <div  className="nexx" >
                  <div className="arrowrightsingle"></div>
                  <div id="arrowup" className="arrowupsingle" ></div>
                  <div className="arrowleftsingle"></div>
                  <div className="arrowdownsingle"></div>
                  <div id="maxkg" className="maxkgsinglepag">3391</div>
               </div>
               <div id="dginfo" className="dginfo"></div>
            </div>
         </div>
      </td>
      <td id="POS_E">
         <div id="POS_E" className="singlepag" >
            <div id="POS_E" className="drop nesz">
               <div id="positioname" className="positionname ">E</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtype"></div>
               <div id="uldno" className="uldno"></div>
               <div id="uldkg" className="uldkg"></div>
               <div  className="nexx" >
                  <div className="arrowrightsingle"></div>
                  <div id="arrowup" className="arrowupsingle" ></div>
                  <div className="arrowleftsingle"></div>
                  <div className="arrowdownsingle"></div>
                  <div id="maxkg" className="maxkgsinglepag">4687</div>
               </div>
               <div id="dginfo" className="dginfo"></div>
            </div>
         </div>
      </td>
      <td id="POS_F">
         <div id="POS_F" className="singlepag" >
            <div id="POS_F" className="drop nesz">
               <div id="positioname" className="positionname ">F</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtype"></div>
               <div id="uldno" className="uldno"></div>
               <div id="uldkg" className="uldkg"></div>
               <div  className="nexx" >
                  <div className="arrowrightsingle"></div>
                  <div id="arrowup" className="arrowupsingle" ></div>
                  <div className="arrowleftsingle"></div>
                  <div className="arrowdownsingle"></div>
                  <div id="maxkg" className="maxkgsinglepag">6033</div>
               </div>
               <div id="dginfo" className="dginfo"></div>
            </div>
         </div>
      </td>
      <td id="POS_G">
         <div id="POS_G" className="singlepag" >
            <div id="POS_G" className="drop nesz">
               <div id="positioname" className="positionname ">G</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtype"></div>
               <div id="uldno" className="uldno"></div>
               <div id="uldkg" className="uldkg"></div>
               <div  className="nexx" >
                  <div className="arrowrightsingle"></div>
                  <div id="arrowup" className="arrowupsingle" ></div>
                  <div className="arrowleftsingle"></div>
                  <div className="arrowdownsingle"></div>
                  <div id="maxkg" className="maxkgsinglepag">6033</div>
               </div>
               <div id="dginfo" className="dginfo"></div>
            </div>
         </div>
      </td>
      <td id="POS_H">
         <div id="POS_H" className="singlepag" >
            <div id="POS_H" className="drop nesz">
               <div id="positioname" className="positionname ">H</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtype"></div>
               <div id="uldno" className="uldno"></div>
               <div id="uldkg" className="uldkg"></div>
               <div  className="nexx" >
                  <div className="arrowrightsingle"></div>
                  <div id="arrowup" className="arrowupsingle" ></div>
                  <div className="arrowleftsingle"></div>
                  <div className="arrowdownsingle"></div>
                  <div id="maxkg" className="maxkgsinglepag">6033</div>
               </div>
               <div id="dginfo" className="dginfo"></div>
            </div>
         </div>
      </td>
      <td id="POS_J">
         <div id="POS_J" className="singlepag" >
            <div id="POS_J" className="drop nesz">
               <div id="positioname" className="positionname ">J</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtype"></div>
               <div id="uldno" className="uldno"></div>
               <div id="uldkg" className="uldkg"></div>
               <div  className="nexx" >
                  <div className="arrowrightsingle"></div>
                  <div id="arrowup" className="arrowupsingle" ></div>
                  <div className="arrowleftsingle"></div>
                  <div className="arrowdownsingle"></div>
                  <div id="maxkg" className="maxkgsinglepag">6033</div>
               </div>
               <div id="dginfo" className="dginfo"></div>
            </div>
         </div>
      </td>
      <td id="POS_K">
         <div id="POS_K" className="singlepag" >
            <div id="POS_K" className="drop nesz">
               <div id="positioname" className="positionname ">K</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtype"></div>
               <div id="uldno" className="uldno"></div>
               <div id="uldkg" className="uldkg"></div>
               <div  className="nexx" >
                  <div className="arrowrightsingle"></div>
                  <div id="arrowup" className="arrowupsingle" ></div>
                  <div className="arrowleftsingle"></div>
                  <div className="arrowdownsingle"></div>
                  <div id="maxkg" className="maxkgsinglepag">5945</div>
               </div>
               <div id="dginfo" className="dginfo"></div>
            </div>
         </div>
      </td>
      <td id="POS_L">
         <div id="POS_L" className="singlepag" >
            <div id="POS_L" className="drop nesz">
               <div id="positioname" className="positionname ">L</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtype"></div>
               <div id="uldno" className="uldno"></div>
               <div id="uldkg" className="uldkg"></div>
               <div  className="nexx" >
                  <div className="arrowrightsingle"></div>
                  <div id="arrowup" className="arrowupsingle" ></div>
                  <div className="arrowleftsingle"></div>
                  <div className="arrowdownsingle"></div>
                  <div id="maxkg" className="maxkgsinglepag">4037</div>
               </div>
               <div id="dginfo" className="dginfo"></div>
            </div>
         </div>
      </td>
      <td id="POS_M">
         <div id="POS_M" className="singlepag" >
            <div id="POS_M" className="drop nesz">
               <div id="positioname" className="positionname ">M</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtype"></div>
               <div id="uldno" className="uldno"></div>
               <div id="uldkg" className="uldkg"></div>
               <div  className="nexx" >
                  <div className="arrowrightsingle"></div>
                  <div id="arrowup" className="arrowupsingle" ></div>
                  <div className="arrowleftsingle"></div>
                  <div className="arrowdownsingle"></div>
                  <div id="maxkg" className="maxkgsinglepag">4037</div>
               </div>
               <div id="dginfo" className="dginfo"></div>
            </div>
         </div>
      </td>
      <td id="POS_P">
         <div id="POS_P" className="singlepag" >
            <div id="POS_P" className="drop nesz">
               <div id="positioname" className="positionname ">P</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtype"></div>
               <div id="uldno" className="uldno"></div>
               <div id="uldkg" className="uldkg"></div>
               <div  className="nexx" >
                  <div className="arrowrightsingle"></div>
                  <div id="arrowup" className="arrowupsingle" ></div>
                  <div className="arrowleftsingle"></div>
                  <div className="arrowdownsingle"></div>
                  <div id="maxkg" className="maxkgsinglepag">3725</div>
               </div>
               <div id="dginfo" className="dginfo"></div>
            </div>
         </div>
      </td>
      <td id="POS_R">
         <div id="POS_R" className="singlepag rpag" >
            <div id="POS_R" className="drop nesz">
               <div id="positioname" className="positionname ">R</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtype"></div>
               <div id="uldno" className="uldno"></div>
               <div id="uldkg" className="uldkg"></div>
               <div  className="nexx" >
                  <div className="arrowrightsingle"></div>
                  <div id="arrowup" className="arrowupsingle" ></div>
                  <div className="arrowleftsingle"></div>
                  <div className="arrowdownsingle"></div>
                  <div id="maxkg" className="maxkgsinglepag">3714</div>
               </div>
               <div id="dginfo" className="dginfo"></div>
            </div>
         </div>
      </td>
      <td id="POS_S">
         <div id="POS_S" className="singlepag" >
            <div id="POS_S" className="drop nesz">
               <div id="positioname" className="positionname ">S</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtype"></div>
               <div id="uldno" className="uldno"></div>
               <div id="uldkg" className="uldkg"></div>
               <div  className="nexx" >
                  <div className="arrowrightsingle"></div>
                  <div id="arrowup" className="arrowupsingle" ></div>
                  <div className="arrowleftsingle"></div>
                  <div className="arrowdownsingle"></div>
                  <div id="maxkg" className="maxkgsinglepag">3714</div>
               </div>
               <div id="dginfo" className="dginfo"></div>
            </div>
         </div>
      </td>
      <td id="POS_T">
         <div id="POS_T" className="singlepag tpag" >
            <div id="POS_T" className="drop nesz">
               <div id="positioname" className="positionname ">T</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtype"></div>
               <div id="uldno" className="uldno"></div>
               <div id="uldkg" className="uldkg"></div>
               <div  className="nexx" >
                  <div className="arrowrightsingle"></div>
                  <div id="arrowup" className="arrowupsingle" ></div>
                  <div className="arrowleftsingle"></div>
                  <div className="arrowdownsingle"></div>
                  <div id="maxkg" className="maxkgsinglepag">3059</div>
               </div>
               <div id="dginfo" className="dginfo"></div>
            </div>
         </div>
      </td>
      <td id="POS_U">
         <div id="POS_U" className="singlepag" >
            <div id="POS_U" className="drop nesz">
               <div id="positioname" className="positionname ">U</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtype"></div>
               <div id="uldno" className="uldno"></div>
               <div id="uldkg" className="uldkg"></div>
               <div  className="nexx" >
                  <div className="arrowrightsingle"></div>
                  <div id="arrowup" className="arrowupsingle" ></div>
                  <div className="arrowleftsingle"></div>
                  <div className="arrowdownsingle"></div>
                  <div id="maxkg" className="maxkgsinglepag">2541</div>
               </div>
               <div id="dginfo" className="dginfo"></div>
            </div>
         </div>
      </td>
   </tr>
</table>
<table className="pmctable">
   <tr>
      <td id="POS_AA">
         <div id="POS_AA" className="singlepmc" >
            <div id="POS_AA" className="drop nesz">
               <div id="positioname" className="positionname ">AA</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtype"></div>
               <div id="uldno" className="uldno"></div>
               <div id="uldkg" className="uldkgpmc"></div>
               <div  className="nexx" >
                  <div className="arrowrightsingle2"></div>
                  <div id="arrowup" className="arrowupsingle2" ></div>
                  <div className="arrowleftsingle2"></div>
                  <div className="arrowdownsingle2"></div>
                  <div id="maxkg" className="maxkgsinglepmc">3080</div>
               </div>
               <div id="dginfo" className="dginfo"></div>
            </div>
         </div>
      </td>
      <td id="POS_BB">
         <div id="POS_BB" className="singlepmc" >
            <div id="POS_BB" className="drop nesz">
               <div id="positioname" className="positionname ">BB</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtype"></div>
               <div id="uldno" className="uldno"></div>
               <div id="uldkg" className="uldkgpmc"></div>
               <div  className="nexx" >
                  <div className="arrowrightsingle2"></div>
                  <div id="arrowup" className="arrowupsingle2" ></div>
                  <div className="arrowleftsingle2"></div>
                  <div className="arrowdownsingle2"></div>
                  <div id="maxkg" className="maxkgsinglepmc">3428</div>
               </div>
               <div id="dginfo" className="dginfo"></div>
            </div>
         </div>
      </td>
      <td id="POS_CC">
         <div id="POS_CC" className="singlepmc" >
            <div id="POS_CC" className="drop nesz">
               <div id="positioname" className="positionname ">CC</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtype"></div>
               <div id="uldno" className="uldno"></div>
               <div id="uldkg" className="uldkgpmc"></div>
               <div  className="nexx" >
                  <div className="arrowrightsingle2"></div>
                  <div id="arrowup" className="arrowupsingle2" ></div>
                  <div className="arrowleftsingle2"></div>
                  <div className="arrowdownsingle2"></div>
                  <div id="maxkg" className="maxkgsinglepmc">3696</div>
               </div>
               <div id="dginfo" className="dginfo"></div>
            </div>
         </div>
      </td>
      <td id="POS_DD">
         <div id="POS_DD" className="singlepmc" >
            <div id="POS_DD" className="drop nesz">
               <div id="positioname" className="positionname ">DD</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtype"></div>
               <div id="uldno" className="uldno"></div>
               <div id="uldkg" className="uldkgpmc"></div>
               <div  className="nexx" >
                  <div className="arrowrightsingle2"></div>
                  <div id="arrowup" className="arrowupsingle2" ></div>
                  <div className="arrowleftsingle2"></div>
                  <div className="arrowdownsingle2"></div>
                  <div id="maxkg" className="maxkgsinglepmc">3696</div>
               </div>
               <div id="dginfo" className="dginfo"></div>
            </div>
         </div>
      </td>
      <td id="POS_EE">
         <div id="POS_EE" className="singlepmc" >
            <div id="POS_EE" className="drop nesz">
               <div id="positioname" className="positionname ">EE</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtype"></div>
               <div id="uldno" className="uldno"></div>
               <div id="uldkg" className="uldkgpmc"></div>
               <div  className="nexx" >
                  <div className="arrowrightsingle2"></div>
                  <div id="arrowup" className="arrowupsingle2" ></div>
                  <div className="arrowleftsingle2"></div>
                  <div className="arrowdownsingle2"></div>
                  <div id="maxkg" className="maxkgsinglepmc">5670</div>
               </div>
               <div id="dginfo" className="dginfo"></div>
            </div>
         </div>
      </td>
      <td id="POS_FF">
         <div id="POS_FF" className="singlepmc" >
            <div id="POS_FF" className="drop nesz">
               <div id="positioname" className="positionname ">FF</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtype"></div>
               <div id="uldno" className="uldno"></div>
               <div id="uldkg" className="uldkgpmc"></div>
               <div  className="nexx" >
                  <div className="arrowrightsingle2"></div>
                  <div id="arrowup" className="arrowupsingle2" ></div>
                  <div className="arrowleftsingle2"></div>
                  <div className="arrowdownsingle2"></div>
                  <div id="maxkg" className="maxkgsinglepmc">5670</div>
               </div>
               <div id="dginfo" className="dginfo"></div>
            </div>
         </div>
      </td>
      <td id="POS_GG">
         <div id="POS_GG" className="singlepmc" >
            <div id="POS_GG" className="drop nesz">
               <div id="positioname" className="positionname ">GG</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtype"></div>
               <div id="uldno" className="uldno"></div>
               <div id="uldkg" className="uldkgpmc"></div>
               <div  className="nexx" >
                  <div className="arrowrightsingle2"></div>
                  <div id="arrowup" className="arrowupsingle2" ></div>
                  <div className="arrowleftsingle2"></div>
                  <div className="arrowdownsingle2"></div>
                  <div id="maxkg" className="maxkgsinglepmc">5670</div>
               </div>
               <div id="dginfo" className="dginfo"></div>
            </div>
         </div>
      </td>
      <td id="POS_HH">
         <div id="POS_HH" className="singlepmc" >
            <div id="POS_HH" className="drop nesz">
               <div id="positioname" className="positionname ">HH</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtype"></div>
               <div id="uldno" className="uldno"></div>
               <div id="uldkg" className="uldkgpmc"></div>
               <div  className="nexx" >
                  <div className="arrowrightsingle2"></div>
                  <div id="arrowup" className="arrowupsingle2" ></div>
                  <div className="arrowleftsingle2"></div>
                  <div className="arrowdownsingle2"></div>
                  <div id="maxkg" className="maxkgsinglepmc">5670</div>
               </div>
               <div id="dginfo" className="dginfo"></div>
            </div>
         </div>
      </td>
      <td id="POS_JJ">
         <div id="POS_JJ" className="singlepmc" >
            <div id="POS_JJ" className="drop nesz">
               <div id="positioname" className="positionname ">JJ</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtype"></div>
               <div id="uldno" className="uldno"></div>
               <div id="uldkg" className="uldkgpmc"></div>
               <div  className="nexx" >
                  <div className="arrowrightsingle2"></div>
                  <div id="arrowup" className="arrowupsingle2" ></div>
                  <div className="arrowleftsingle2"></div>
                  <div className="arrowdownsingle2"></div>
                  <div id="maxkg" className="maxkgsinglepmc">5670</div>
               </div>
               <div id="dginfo" className="dginfo"></div>
            </div>
         </div>
      </td>
      <td id="POS_KK">
         <div id="POS_KK" className="singlepmc" >
            <div id="POS_KK" className="drop nesz">
               <div id="positioname" className="positionname ">KK</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtype"></div>
               <div id="uldno" className="uldno"></div>
               <div id="uldkg" className="uldkgpmc"></div>
               <div  className="nexx" >
                  <div className="arrowrightsingle2"></div>
                  <div id="arrowup" className="arrowupsingle2" ></div>
                  <div className="arrowleftsingle2"></div>
                  <div className="arrowdownsingle2"></div>
                  <div id="maxkg" className="maxkgsinglepmc">4822</div>
               </div>
               <div id="dginfo" className="dginfo"></div>
            </div>
         </div>
      </td>
      <td id="POS_LL">
         <div id="POS_LL" className="singlepmc" >
            <div id="POS_LL" className="drop nesz">
               <div id="positioname" className="positionname ">LL</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtype"></div>
               <div id="uldno" className="uldno"></div>
               <div id="uldkg" className="uldkgpmc"></div>
               <div  className="nexx" >
                  <div className="arrowrightsingle2"></div>
                  <div id="arrowup" className="arrowupsingle2" ></div>
                  <div className="arrowleftsingle2"></div>
                  <div className="arrowdownsingle2"></div>
                  <div id="maxkg" className="maxkgsinglepmc">4400</div>
               </div>
               <div id="dginfo" className="dginfo"></div>
            </div>
         </div>
      </td>
      <td id="POS_MM">
         <div id="POS_MM" className="singlepmc" >
            <div id="POS_MM" className="drop nesz">
               <div id="positioname" className="positionname ">MM</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtype"></div>
               <div id="uldno" className="uldno"></div>
               <div id="uldkg" className="uldkgpmc"></div>
               <div  className="nexx" >
                  <div className="arrowrightsingle2"></div>
                  <div id="arrowup" className="arrowupsingle2" ></div>
                  <div className="arrowleftsingle2"></div>
                  <div className="arrowdownsingle2"></div>
                  <div id="maxkg" className="maxkgsinglepmc">4091</div>
               </div>
               <div id="dginfo" className="dginfo"></div>
            </div>
         </div>
      </td>
      <td id="POS_PP">
         <div id="POS_PP" className="singlepmc" >
            <div id="POS_PP" className="drop nesz">
               <div id="positioname" className="positionname ">PP</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtype"></div>
               <div id="uldno" className="uldno"></div>
               <div id="uldkg" className="uldkgpmc"></div>
               <div  className="nexx" >
                  <div className="arrowrightsingle2"></div>
                  <div id="arrowup" className="arrowupsingle2" ></div>
                  <div className="arrowleftsingle2"></div>
                  <div className="arrowdownsingle2"></div>
                  <div id="maxkg" className="maxkgsinglepmc">4048</div>
               </div>
               <div id="dginfo" className="dginfo"></div>
            </div>
         </div>
      </td>
      <td id="POS_RR">
         <div id="POS_RR" className="singlepmc" >
            <div id="POS_RR" className="drop nesz">
               <div id="positioname" className="positionname ">RR</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtype"></div>
               <div id="uldno" className="uldno"></div>
               <div id="uldkg" className="uldkgpmc"></div>
               <div  className="nexx" >
                  <div className="arrowrightsingle2"></div>
                  <div id="arrowup" className="arrowupsingle2" ></div>
                  <div className="arrowleftsingle2"></div>
                  <div className="arrowdownsingle2"></div>
                  <div id="maxkg" className="maxkgsinglepmc">4048</div>
               </div>
               <div id="dginfo" className="dginfo"></div>
            </div>
         </div>
      </td>
      <td id="POS_SS">
         <div id="POS_SS" className="singlepmc" >
            <div id="POS_SS" className="drop nesz">
               <div id="positioname" className="positionname ">SS</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtype"></div>
               <div id="uldno" className="uldno"></div>
               <div id="uldkg" className="uldkgpmc"></div>
               <div  className="nexx" >
                  <div className="arrowrightsingle2"></div>
                  <div id="arrowup" className="arrowupsingle2" ></div>
                  <div className="arrowleftsingle2"></div>
                  <div className="arrowdownsingle2"></div>
                  <div id="maxkg" className="maxkgsinglepmc">3389</div>
               </div>
               <div id="dginfo" className="dginfo"></div>
            </div>
         </div>
      </td>
      <td id="POS_TT">
         <div id="POS_TT" className="singlepmc" >
            <div id="POS_TT" className="drop nesz">
               <div id="positioname" className="positionname ">TT</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtype"></div>
               <div id="uldno" className="uldno"></div>
               <div id="uldkg" className="uldkgpmc"></div>
               <div  className="nexx" >
                  <div className="arrowrightsingle2"></div>
                  <div id="arrowup" className="arrowupsingle2" ></div>
                  <div className="arrowleftsingle2"></div>
                  <div className="arrowdownsingle2"></div>
                  <div id="maxkg" className="maxkgsinglepmc">2809</div>
               </div>
               <div id="dginfo" className="dginfo"></div>
            </div>
         </div>
      </td>
   </tr>
</table>
<table className="centertable">
   <tr>
      <td id="POS_AB">
         <div id="POS_AB" className="centerpmc" >
            <div id="POS_AB" className="drop nesz">
               <div id="positioname" className="positionname ">AB</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypesbs"></div>
               <div id="uldno" className="uldnosbs"></div>
               <div id="uldkg" className="uldkgsbs"></div>
               <div  className="nexx" >
                  <div className="arrowrightsbs"></div>
                  <div id="arrowup" className="arrowupsbs" ></div>
                  <div className="arrowleftsbs"></div>
                  <div className="arrowdownsbs"></div>
                  <div id="maxkg" className="maxkgsbs">3429</div>
               </div>
               <div id="dginfo" className="dginfosbs"></div>
            </div>
         </div>
      </td>
      <td id="POS_BC">
         <div id="POS_BC" className="centerpmc" >
            <div id="POS_BC" className="drop nesz">
               <div id="positioname" className="positionname ">BC</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypesbs"></div>
               <div id="uldno" className="uldnosbs"></div>
               <div id="uldkg" className="uldkgsbs"></div>
               <div  className="nexx" >
                  <div className="arrowrightsbs"></div>
                  <div id="arrowup" className="arrowupsbs" ></div>
                  <div className="arrowleftsbs"></div>
                  <div className="arrowdownsbs"></div>
                  <div id="maxkg" className="maxkgsbs">3941</div>
               </div>
               <div id="dginfo" className="dginfosbs"></div>
            </div>
         </div>
      </td>
      <td id="POS_CE">
         <div id="POS_CE" className="centerpmc" >
            <div id="POS_CE" className="drop nesz">
               <div id="positioname" className="positionname ">CE</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypesbs"></div>
               <div id="uldno" className="uldnosbs"></div>
               <div id="uldkg" className="uldkgsbs"></div>
               <div  className="nexx" >
                  <div className="arrowrightsbs"></div>
                  <div id="arrowup" className="arrowupsbs" ></div>
                  <div className="arrowleftsbs"></div>
                  <div className="arrowdownsbs"></div>
                  <div id="maxkg" className="maxkgsbs">4001</div>
               </div>
               <div id="dginfo" className="dginfosbs"></div>
            </div>
         </div>
      </td>
      <td id="POS_EF">
         <div id="POS_EF" className="centerpmc" >
            <div id="POS_EF" className="drop nesz">
               <div id="positioname" className="positionname ">EF</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypesbs"></div>
               <div id="uldno" className="uldnosbs"></div>
               <div id="uldkg" className="uldkgsbs"></div>
               <div  className="nexx" >
                  <div className="arrowrightsbs"></div>
                  <div id="arrowup" className="arrowupsbs" ></div>
                  <div className="arrowleftsbs"></div>
                  <div className="arrowdownsbs"></div>
                  <div id="maxkg" className="maxkgsbs">6804</div>
               </div>
               <div id="dginfo" className="dginfosbs"></div>
            </div>
         </div>
      </td>
      <td id="POS_FH">
         <div id="POS_FH" className="centerpmc" >
            <div id="POS_FH" className="drop nesz">
               <div id="positioname" className="positionname ">FH</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypesbs"></div>
               <div id="uldno" className="uldnosbs"></div>
               <div id="uldkg" className="uldkgsbs"></div>
               <div  className="nexx" >
                  <div className="arrowrightsbs"></div>
                  <div id="arrowup" className="arrowupsbs" ></div>
                  <div className="arrowleftsbs"></div>
                  <div className="arrowdownsbs"></div>
                  <div id="maxkg" className="maxkgsbs">6804</div>
               </div>
               <div id="dginfo" className="dginfosbs"></div>
            </div>
         </div>
      </td>
      <td id="POS_HJ">
         <div id="POS_HJ" className="centerpmc" >
            <div id="POS_HJ" className="drop nesz">
               <div id="positioname" className="positionname ">HJ</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypesbs"></div>
               <div id="uldno" className="uldnosbs"></div>
               <div id="uldkg" className="uldkgsbs"></div>
               <div  className="nexx" >
                  <div className="arrowrightsbs"></div>
                  <div id="arrowup" className="arrowupsbs" ></div>
                  <div className="arrowleftsbs"></div>
                  <div className="arrowdownsbs"></div>
                  <div id="maxkg" className="maxkgsbs">6804</div>
               </div>
               <div id="dginfo" className="dginfosbs"></div>
            </div>
         </div>
      </td>
      <td id="POS_JK">
         <div id="POS_JK" className="centerpmc" >
            <div id="POS_JK" className="drop nesz">
               <div id="positioname" className="positionname ">JK</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypesbs"></div>
               <div id="uldno" className="uldnosbs"></div>
               <div id="uldkg" className="uldkgsbs"></div>
               <div  className="nexx" >
                  <div className="arrowrightsbs"></div>
                  <div id="arrowup" className="arrowupsbs" ></div>
                  <div className="arrowleftsbs"></div>
                  <div className="arrowdownsbs"></div>
                  <div id="maxkg" className="maxkgsbs">6804</div>
               </div>
               <div id="dginfo" className="dginfosbs"></div>
            </div>
         </div>
      </td>
      <td id="POS_KM">
         <div id="POS_KM" className="centerpmc" >
            <div id="POS_KM" className="drop nesz">
               <div id="positioname" className="positionname ">KM</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypesbs"></div>
               <div id="uldno" className="uldnosbs"></div>
               <div id="uldkg" className="uldkgsbs"></div>
               <div  className="nexx" >
                  <div className="arrowrightsbs"></div>
                  <div id="arrowup" className="arrowupsbs" ></div>
                  <div className="arrowleftsbs"></div>
                  <div className="arrowdownsbs"></div>
                  <div id="maxkg" className="maxkgsbs">4868</div>
               </div>
               <div id="dginfo" className="dginfosbs"></div>
            </div>
         </div>
      </td>
      <td id="POS_MP">
         <div id="POS_MP" className="centerpmc" >
            <div id="POS_MP" className="drop nesz">
               <div id="positioname" className="positionname ">MP</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypesbs"></div>
               <div id="uldno" className="uldnosbs"></div>
               <div id="uldkg" className="uldkgsbs"></div>
               <div  className="nexx" >
                  <div className="arrowrightsbs"></div>
                  <div id="arrowup" className="arrowupsbs" ></div>
                  <div className="arrowleftsbs"></div>
                  <div className="arrowdownsbs"></div>
                  <div id="maxkg" className="maxkgsbs">4445</div>
               </div>
               <div id="dginfo" className="dginfosbs"></div>
            </div>
         </div>
      </td>
   </tr>
   <tr>
      <td id="POS_CFG">
         <div id="POS_CFG" className="centercfg" >
            <div id="POS_CFG" className="drop nesz">
               <div id="positioname" className="positionname ">CFG</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypepra"></div>
               <div id="uldno" className="uldnopra"></div>
               <div id="uldkg" className="uldkgpra"></div>
               <div  className="nexx" >
                  <div className="arrowrightpga"></div>
                  <div id="arrowup" className="arrowuppga" ></div>
                  <div className="arrowleftpga"></div>
                  <div className="arrowdownpga"></div>
                  <div id="maxkg" className="maxkgpga">10602</div>
               </div>
               <div id="dginfo" className="dginfocenter"></div>
            </div>
         </div>
      </td>
      <td id="POS_FJG">
         <div id="POS_FJG" className="centerfjg" >
            <div id="POS_FJG" className="drop nesz">
               <div id="positioname" className="positionname ">FJG</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypepra"></div>
               <div id="uldno" className="uldnopra"></div>
               <div id="uldkg" className="uldkgpra"></div>
               <div  className="nexx" >
                  <div className="arrowrightpga"></div>
                  <div id="arrowup" className="arrowuppga" ></div>
                  <div className="arrowleftpga"></div>
                  <div className="arrowdownpga"></div>
                  <div className="floating"></div>
                  <div id="maxkg" className="maxkgpga">11340</div>
               </div>
               <div id="dginfo" className="dginfocenter"></div>
            </div>
         </div>
      </td>
      <td id="POS_JLG">
         <div id="POS_JLG" className="centerjlg" >
            <div id="POS_JLG" className="drop nesz">
               <div id="positioname" className="positionname ">JLG</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypepra"></div>
               <div id="uldno" className="uldnopra"></div>
               <div id="uldkg" className="uldkgpra"></div>
               <div  className="nexx" >
                  <div className="arrowrightpga"></div>
                  <div id="arrowup" className="arrowuppga" ></div>
                  <div className="arrowleftpga"></div>
                  <div className="arrowdownpga"></div>
                  <div className="floating"></div>
                  <div id="maxkg" className="maxkgpga">11340</div>
               </div>
               <div id="dginfo" className="dginfocenter"></div>
            </div>
         </div>
      </td>
   </tr>
   <tr className="sbstable sbstablez">
      <td id="POS_CFR">
         <div id="POS_CFR" className="centercfr" >
            <div id="POS_CFR" className="drop nesz">
               <div id="positioname" className="positionname ">CFR</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypepra"></div>
               <div id="uldno" className="uldnopra"></div>
               <div id="uldkg" className="uldkgpra"></div>
               <div  className="nexx" >
                  <div className="arrowrightpra"></div>
                  <div id="arrowup" className="arrowuppra" ></div>
                  <div className="arrowleftpra"></div>
                  <div className="arrowdownpra"></div>
                  <div id="maxkg" className="maxkgcenter">7729</div>
               </div>
               <div id="dginfo" className="dginfocenter"></div>
            </div>
         </div>
      </td>
      <td id="POS_FJR">
         <div id="POS_FJR" className="centerfjr" >
            <div id="POS_FJR" className="drop nesz">
               <div id="positioname" className="positionname ">FJR</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypepra"></div>
               <div id="uldno" className="uldnopra"></div>
               <div id="uldkg" className="uldkgpra"></div>
               <div  className="nexx" >
                  <div className="arrowrightpra"></div>
                  <div id="arrowup" className="arrowuppra" ></div>
                  <div className="arrowleftpra"></div>
                  <div className="arrowdownpra"></div>
                  <div className="floating"></div>
                  <div id="maxkg" className="maxkgcenter">10668</div>
               </div>
               <div id="dginfo" className="dginfocenter"></div>
            </div>
         </div>
      </td>
      <td id="POS_JLR">
         <div id="POS_JLR" className="centerjlr" >
            <div id="POS_JLR" className="drop nesz">
               <div id="positioname" className="positionname ">JLR</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypepra"></div>
               <div id="uldno" className="uldnopra"></div>
               <div id="uldkg" className="uldkgpra"></div>
               <div  className="nexx" >
                  <div className="arrowrightpra"></div>
                  <div id="arrowup" className="arrowuppra" ></div>
                  <div className="arrowleftpra"></div>
                  <div className="arrowdownpra"></div>
                  <div className="floating"></div>
                  <div id="maxkg" className="maxkgcenter">10668</div>
               </div>
               <div id="dginfo" className="dginfocenter"></div>
            </div>
         </div>
      </td>
      <td id="POS_LPR">
         <div id="POS_LPR" className="centerlpr" >
            <div id="POS_LPR" className="drop nesz">
               <div id="positioname" className="positionname ">LPR</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypepra"></div>
               <div id="uldno" className="uldnopra"></div>
               <div id="uldkg" className="uldkgpra"></div>
               <div  className="nexx" >
                  <div className="arrowrightpra"></div>
                  <div id="arrowup" className="arrowuppra" ></div>
                  <div className="arrowleftpra"></div>
                  <div className="arrowdownpra"></div>
                  <div id="maxkg" className="maxkgcenter">6236</div>
               </div>
               <div id="dginfo" className="dginfocenter"></div>
            </div>
         </div>
      </td>
   </tr>
</table>
<table className="sbstable  czx">
   <tr>
      <td id="POS_ABR">
         <div id="POS_ABR" className="sbsregular" >
            <div id="POS_ABR" className="drop nesz">
               <div id="positioname" className="positionname ">ABR</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypesbs"></div>
               <div id="uldno" className="uldnosbs"></div>
               <div id="uldkg" className="uldkgsbs"></div>
               <div  className="nexx" >
                  <div className="arrowrightsbs"></div>
                  <div id="arrowup" className="arrowupsbs" ></div>
                  <div className="arrowleftsbs"></div>
                  <div className="arrowdownsbs"></div>
                  <div id="maxkg" className="maxkgsbs">2000</div>
               </div>
               <div id="dginfo" className="dginfosbs"></div>
            </div>
         </div>
      </td>
      <td id="POS_BCR">
         <div id="POS_BCR" className="sbsregular" >
            <div id="POS_BCR" className="drop nesz">
               <div id="positioname" className="positionname ">BCR</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypesbs"></div>
               <div id="uldno" className="uldnosbs"></div>
               <div id="uldkg" className="uldkgsbs"></div>
               <div  className="nexx" >
                  <div className="arrowrightsbs"></div>
                  <div id="arrowup" className="arrowupsbs" ></div>
                  <div className="arrowleftsbs"></div>
                  <div className="arrowdownsbs"></div>
                  <div id="maxkg" className="maxkgsbs">2358</div>
               </div>
               <div id="dginfo" className="dginfosbs"></div>
            </div>
         </div>
      </td>
      <td id="POS_CER">
         <div id="POS_CER" className="sbsregular" >
            <div id="POS_CER" className="drop nesz">
               <div id="positioname" className="positionname ">CER</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypesbs"></div>
               <div id="uldno" className="uldnosbs"></div>
               <div id="uldkg" className="uldkgsbs"></div>
               <div  className="nexx" >
                  <div className="arrowrightsbs"></div>
                  <div id="arrowup" className="arrowupsbs" ></div>
                  <div className="arrowleftsbs"></div>
                  <div className="arrowdownsbs"></div>
                  <div id="maxkg" className="maxkgsbs">2400</div>
               </div>
               <div id="dginfo" className="dginfosbs"></div>
            </div>
         </div>
      </td>
      <td id="POS_EFR">
         <div id="POS_EFR" className="sbsregular" >
            <div id="POS_EFR" className="drop nesz">
               <div id="positioname" className="positionname ">EFR</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypesbs"></div>
               <div id="uldno" className="uldnosbs"></div>
               <div id="uldkg" className="uldkgsbs"></div>
               <div  className="nexx" >
                  <div className="arrowrightsbs"></div>
                  <div id="arrowup" className="arrowupsbs" ></div>
                  <div className="arrowleftsbs"></div>
                  <div className="arrowdownsbs"></div>
                  <div id="maxkg" className="maxkgsbs">3825</div>
               </div>
               <div id="dginfo" className="dginfosbs"></div>
            </div>
         </div>
      </td>
      <td id="POS_FHR">
         <div id="POS_FHR" className="sbsregular" >
            <div id="POS_FHR" className="drop nesz">
               <div id="positioname" className="positionname ">FHR</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypesbs"></div>
               <div id="uldno" className="uldnosbs"></div>
               <div id="uldkg" className="uldkgsbs"></div>
               <div  className="nexx" >
                  <div className="arrowrightsbs"></div>
                  <div id="arrowup" className="arrowupsbs" ></div>
                  <div className="arrowleftsbs"></div>
                  <div className="arrowdownsbs"></div>
                  <div id="maxkg" className="maxkgsbs">4321</div>
               </div>
               <div id="dginfo" className="dginfosbs"></div>
            </div>
         </div>
      </td>
      <td id="POS_HJR">
         <div id="POS_HJR" className="sbsregular" >
            <div id="POS_HJR" className="drop nesz">
               <div id="positioname" className="positionname ">HJR</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypesbs"></div>
               <div id="uldno" className="uldnosbs"></div>
               <div id="uldkg" className="uldkgsbs"></div>
               <div  className="nexx" >
                  <div className="arrowrightsbs"></div>
                  <div id="arrowup" className="arrowupsbs" ></div>
                  <div className="arrowleftsbs"></div>
                  <div className="arrowdownsbs"></div>
                  <div id="maxkg" className="maxkgsbs">4321</div>
               </div>
               <div id="dginfo" className="dginfosbs"></div>
            </div>
         </div>
      </td>
      <td id="POS_JKR">
         <div id="POS_JKR" className="sbsregular" >
            <div id="POS_JKR" className="drop nesz">
               <div id="positioname" className="positionname ">JKR</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypesbs"></div>
               <div id="uldno" className="uldnosbs"></div>
               <div id="uldkg" className="uldkgsbs"></div>
               <div  className="nexx" >
                  <div className="arrowrightsbs"></div>
                  <div id="arrowup" className="arrowupsbs" ></div>
                  <div className="arrowleftsbs"></div>
                  <div className="arrowdownsbs"></div>
                  <div id="maxkg" className="maxkgsbs">4321</div>
               </div>
               <div id="dginfo" className="dginfosbs"></div>
            </div>
         </div>
      </td>
      <td id="POS_KMR">
         <div id="POS_KMR" className="sbsregular" >
            <div id="POS_KMR" className="drop nesz">
               <div id="positioname" className="positionname ">KMR</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypesbs"></div>
               <div id="uldno" className="uldnosbs"></div>
               <div id="uldkg" className="uldkgsbs"></div>
               <div  className="nexx" >
                  <div className="arrowrightsbs"></div>
                  <div id="arrowup" className="arrowupsbs" ></div>
                  <div className="arrowleftsbs"></div>
                  <div className="arrowdownsbs"></div>
                  <div id="maxkg" className="maxkgsbs">2964</div>
               </div>
               <div id="dginfo" className="dginfosbs"></div>
            </div>
         </div>
      </td>
      <td id="POS_MPR">
         <div id="POS_MPR" className="sbsregular" >
            <div id="POS_MPR" className="drop nesz">
               <div id="positioname" className="positionname ">MPR</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypesbs"></div>
               <div id="uldno" className="uldnosbs"></div>
               <div id="uldkg" className="uldkgsbs"></div>
               <div  className="nexx" >
                  <div className="arrowrightsbs"></div>
                  <div id="arrowup" className="arrowupsbs" ></div>
                  <div className="arrowleftsbs"></div>
                  <div className="arrowdownsbs"></div>
                  <div id="maxkg" className="maxkgsbs">2756</div>
               </div>
               <div id="dginfo" className="dginfosbs"></div>
            </div>
         </div>
      </td>
      <td id="POS_PRR">
         <div id="POS_PRR" className="sbspag" >
            <div id="POS_PRR" className="drop nesz">
               <div id="positioname" className="positionname ">PRR</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypesbs"></div>
               <div id="uldno" className="uldnosbs"></div>
               <div id="uldkg" className="uldkgsbs"></div>
               <div  className="nexx" >
                  <div className="arrowrightsbs"></div>
                  <div id="arrowup" className="arrowupsbs" ></div>
                  <div className="arrowleftsbs"></div>
                  <div className="arrowdownsbs"></div>
                  <div id="maxkg" className="maxkgsbs">2629</div>
               </div>
               <div id="dginfo" className="dginfosbs"></div>
            </div>
         </div>
      </td>
   </tr>
</table>
<table className="tablesbs">
   <tr>
      <td id="POS_ABL">
         <div id="POS_ABL" className="sbsregular" >
            <div id="POS_ABL" className="drop nesz">
               <div id="positioname" className="positionname ">ABL</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypesbs"></div>
               <div id="uldno" className="uldnosbs"></div>
               <div id="uldkg" className="uldkgsbs"></div>
               <div  className="nexx" >
                  <div className="arrowrightsbs"></div>
                  <div id="arrowup" className="arrowupsbs" ></div>
                  <div className="arrowleftsbs"></div>
                  <div className="arrowdownsbs"></div>
                  <div id="maxkg" className="maxkgsbs">2000</div>
               </div>
               <div id="dginfo" className="dginfosbs"></div>
            </div>
         </div>
      </td>
      <td id="POS_BCL">
         <div id="POS_BCL" className="sbsregular" >
            <div id="POS_BCL" className="drop nesz">
               <div id="positioname" className="positionname ">BCL</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypesbs"></div>
               <div id="uldno" className="uldnosbs"></div>
               <div id="uldkg" className="uldkgsbs"></div>
               <div  className="nexx" >
                  <div className="arrowrightsbs"></div>
                  <div id="arrowup" className="arrowupsbs" ></div>
                  <div className="arrowleftsbs"></div>
                  <div className="arrowdownsbs"></div>
                  <div id="maxkg" className="maxkgsbs">2358</div>
               </div>
               <div id="dginfo" className="dginfosbs"></div>
            </div>
         </div>
      </td>
      <td id="POS_CEL">
         <div id="POS_CEL" className="sbsregular" >
            <div id="POS_CEL" className="drop nesz">
               <div id="positioname" className="positionname ">CEL</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypesbs"></div>
               <div id="uldno" className="uldnosbs"></div>
               <div id="uldkg" className="uldkgsbs"></div>
               <div  className="nexx" >
                  <div className="arrowrightsbs"></div>
                  <div id="arrowup" className="arrowupsbs" ></div>
                  <div className="arrowleftsbs"></div>
                  <div className="arrowdownsbs"></div>
                  <div id="maxkg" className="maxkgsbs">2400</div>
               </div>
               <div id="dginfo" className="dginfosbs"></div>
            </div>
         </div>
      </td>
      <td id="POS_EFL">
         <div id="POS_EFL" className="sbsregular" >
            <div id="POS_EFL" className="drop nesz">
               <div id="positioname" className="positionname ">EFL</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypesbs"></div>
               <div id="uldno" className="uldnosbs"></div>
               <div id="uldkg" className="uldkgsbs"></div>
               <div  className="nexx" >
                  <div className="arrowrightsbs"></div>
                  <div id="arrowup" className="arrowupsbs" ></div>
                  <div className="arrowleftsbs"></div>
                  <div className="arrowdownsbs"></div>
                  <div id="maxkg" className="maxkgsbs">3825</div>
               </div>
               <div id="dginfo" className="dginfosbs"></div>
            </div>
         </div>
      </td>
      <td id="POS_FHL">
         <div id="POS_FHL" className="sbsregular" >
            <div id="POS_FHL" className="drop nesz">
               <div id="positioname" className="positionname ">FHL</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypesbs"></div>
               <div id="uldno" className="uldnosbs"></div>
               <div id="uldkg" className="uldkgsbs"></div>
               <div  className="nexx" >
                  <div className="arrowrightsbs"></div>
                  <div id="arrowup" className="arrowupsbs" ></div>
                  <div className="arrowleftsbs"></div>
                  <div className="arrowdownsbs"></div>
                  <div id="maxkg" className="maxkgsbs">4321</div>
               </div>
               <div id="dginfo" className="dginfosbs"></div>
            </div>
         </div>
      </td>
      <td id="POS_HJL">
         <div id="POS_HJL" className="sbsregular" >
            <div id="POS_HJL" className="drop nesz">
               <div id="positioname" className="positionname ">HJL</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypesbs"></div>
               <div id="uldno" className="uldnosbs"></div>
               <div id="uldkg" className="uldkgsbs"></div>
               <div  className="nexx" >
                  <div className="arrowrightsbs"></div>
                  <div id="arrowup" className="arrowupsbs" ></div>
                  <div className="arrowleftsbs"></div>
                  <div className="arrowdownsbs"></div>
                  <div id="maxkg" className="maxkgsbs">4321</div>
               </div>
               <div id="dginfo" className="dginfosbs"></div>
            </div>
         </div>
      </td>
      <td id="POS_JKL">
         <div id="POS_JKL" className="sbsregular" >
            <div id="POS_JKL" className="drop nesz">
               <div id="positioname" className="positionname ">JKL</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypesbs"></div>
               <div id="uldno" className="uldnosbs"></div>
               <div id="uldkg" className="uldkgsbs"></div>
               <div  className="nexx" >
                  <div className="arrowrightsbs"></div>
                  <div id="arrowup" className="arrowupsbs" ></div>
                  <div className="arrowleftsbs"></div>
                  <div className="arrowdownsbs"></div>
                  <div id="maxkg" className="maxkgsbs">4321</div>
               </div>
               <div id="dginfo" className="dginfosbs"></div>
            </div>
         </div>
      </td>
      <td id="POS_KML">
         <div id="POS_KML" className="sbsregular" >
            <div id="POS_KML" className="drop nesz">
               <div id="positioname" className="positionname ">KML</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypesbs"></div>
               <div id="uldno" className="uldnosbs"></div>
               <div id="uldkg" className="uldkgsbs"></div>
               <div  className="nexx" >
                  <div className="arrowrightsbs"></div>
                  <div id="arrowup" className="arrowupsbs" ></div>
                  <div className="arrowleftsbs"></div>
                  <div className="arrowdownsbs"></div>
                  <div id="maxkg" className="maxkgsbs">2964</div>
               </div>
               <div id="dginfo" className="dginfosbs"></div>
            </div>
         </div>
      </td>
      <td id="POS_MPL">
         <div id="POS_MPL" className="sbsregular" >
            <div id="POS_MPL" className="drop nesz">
               <div id="positioname" className="positionname ">MPL</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypesbs"></div>
               <div id="uldno" className="uldnosbs"></div>
               <div id="uldkg" className="uldkgsbs"></div>
               <div  className="nexx" >
                  <div className="arrowrightsbs"></div>
                  <div id="arrowup" className="arrowupsbs" ></div>
                  <div className="arrowleftsbs"></div>
                  <div className="arrowdownsbs"></div>
                  <div id="maxkg" className="maxkgsbs">2756</div>
               </div>
               <div id="dginfo" className="dginfosbs"></div>
            </div>
         </div>
      </td>
      <td id="POS_PRL">
         <div id="POS_PRL" className="sbspag" >
            <div id="POS_PRL" className="drop nesz">
               <div id="positioname" className="positionname ">PRL</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypesbs"></div>
               <div id="uldno" className="uldnosbs"></div>
               <div id="uldkg" className="uldkgsbs"></div>
               <div  className="nexx" >
                  <div className="arrowrightsbs"></div>
                  <div id="arrowup" className="arrowupsbs" ></div>
                  <div className="arrowleftsbs"></div>
                  <div className="arrowdownsbs"></div>
                  <div id="maxkg" className="maxkgsbs">2629</div>
               </div>
               <div id="dginfo" className="dginfosbs"></div>
            </div>
         </div>
      </td>
   </tr>
</table>
<table className="tableld">
   <tr>
      <td id="POS_11R">
         <div id="POS_11R" className="ake" >
            <div id="POS_11R" className="drop nesz">
               <div id="positioname" className="positionname ">11R</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypeake"></div>
               <div id="uldno" className="uldnoake"></div>
               <div id="uldkg" className="uldkgake"></div>
               <div  className="nexx" >
                  <div className="noshow"></div>
                  <div id="arrowup" className="noshow" ></div>
                  <div className="noshow"></div>
                  <div className="noshow"></div>
                  <div id="maxkg" className="maxkgake">1587</div>
               </div>
               <div id="dginfo" className="dginfoake"></div>
            </div>
         </div>
      </td>
      <td id="POS_12R">
         <div id="POS_12R" className="ake sizing22 nexx2">
            <div id="POS_12R" className="drop nesz">
               <div id="positioname" className="positionname ">12R</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypeake"></div>
               <div id="uldno" className="uldnoake"></div>
               <div id="uldkg" className="uldkgake"></div>
               <div  className="nexx" >
                  <div className="noshow"></div>
                  <div id="arrowup" className="noshow" ></div>
                  <div className="noshow"></div>
                  <div className="noshow"></div>
                  <div id="maxkg" className="maxkgake">1587</div>
               </div>
               <div id="dginfo" className="dginfoake"></div>
            </div>
         </div>
      </td>
      <td id="POS_13R">
         <div id="POS_13R" className="ake" >
            <div id="POS_13R" className="drop nesz">
               <div id="positioname" className="positionname ">13R</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypeake"></div>
               <div id="uldno" className="uldnoake"></div>
               <div id="uldkg" className="uldkgake"></div>
               <div  className="nexx" >
                  <div className="noshow"></div>
                  <div id="arrowup" className="noshow" ></div>
                  <div className="noshow"></div>
                  <div className="noshow"></div>
                  <div id="maxkg" className="maxkgake">1587</div>
               </div>
               <div id="dginfo" className="dginfoake"></div>
            </div>
         </div>
      </td>
      <td id="POS_14R">
         <div id="POS_14R" className="ake" >
            <div id="POS_14R" className="drop nesz">
               <div id="positioname" className="positionname ">14R</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypeake"></div>
               <div id="uldno" className="uldnoake"></div>
               <div id="uldkg" className="uldkgake"></div>
               <div  className="nexx" >
                  <div className="noshow"></div>
                  <div id="arrowup" className="noshow" ></div>
                  <div className="noshow"></div>
                  <div className="noshow"></div>
                  <div id="maxkg" className="maxkgake">1587</div>
               </div>
               <div id="dginfo" className="dginfoake"></div>
            </div>
         </div>
      </td>
      <td id="POS_21R">
         <div id="POS_21R" className="ake sizing22 nexx2">
            <div id="POS_21R" className="drop nesz">
               <div id="positioname" className="positionname ">21R</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypeake"></div>
               <div id="uldno" className="uldnoake"></div>
               <div id="uldkg" className="uldkgake"></div>
               <div  className="nexx" >
                  <div className="noshow"></div>
                  <div id="arrowup" className="noshow" ></div>
                  <div className="noshow"></div>
                  <div className="noshow"></div>
                  <div id="maxkg" className="maxkgake">1587</div>
               </div>
               <div id="dginfo" className="dginfoake"></div>
            </div>
         </div>
      </td>
      <td id="POS_22R">
         <div id="POS_22R" className="ake" >
            <div id="POS_22R" className="drop nesz">
               <div id="positioname" className="positionname ">22R</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypeake"></div>
               <div id="uldno" className="uldnoake"></div>
               <div id="uldkg" className="uldkgake"></div>
               <div  className="nexx" >
                  <div className="noshow"></div>
                  <div id="arrowup" className="noshow" ></div>
                  <div className="noshow"></div>
                  <div className="noshow"></div>
                  <div id="maxkg" className="maxkgake">1587</div>
               </div>
               <div id="dginfo" className="dginfoake"></div>
            </div>
         </div>
      </td>
      <td id="POS_23R">
         <div id="POS_23R" className="ake" >
            <div id="POS_23R" className="drop nesz">
               <div id="positioname" className="positionname ">23R</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypeake"></div>
               <div id="uldno" className="uldnoake"></div>
               <div id="uldkg" className="uldkgake"></div>
               <div  className="nexx" >
                  <div className="noshow"></div>
                  <div id="arrowup" className="noshow" ></div>
                  <div className="noshow"></div>
                  <div className="noshow"></div>
                  <div id="maxkg" className="maxkgake">1587</div>
               </div>
               <div id="dginfo" className="dginfoake"></div>
            </div>
         </div>
      </td>
      <td id="POS_">
         <div id="POS_" className="busy sizing44 nexx1">
            <div id="POS_" className="drop nesz">
               <div id="positioname" className="positionname "></div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypeake"></div>
               <div id="uldno" className="uldnoake"></div>
               <div id="uldkg" className="uldkgake"></div>
               <div  className="nexx" >
                  <div className="noshow"></div>
                  <div id="arrowup" className="noshow" ></div>
                  <div className="noshow"></div>
                  <div className="noshow"></div>
               </div>
               <div id="dginfo" className="dginfoake"></div>
            </div>
         </div>
      </td>
      <td id="POS_31R">
         <div id="POS_31R" className="ake" >
            <div id="POS_31R" className="drop nesz">
               <div id="positioname" className="positionname ">31R</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypeake"></div>
               <div id="uldno" className="uldnoake"></div>
               <div id="uldkg" className="uldkgake"></div>
               <div  className="nexx" >
                  <div className="noshow"></div>
                  <div id="arrowup" className="noshow" ></div>
                  <div className="noshow"></div>
                  <div className="noshow"></div>
                  <div id="maxkg" className="maxkgake">1587</div>
               </div>
               <div id="dginfo" className="dginfoake"></div>
            </div>
         </div>
      </td>
      <td id="POS_32R">
         <div id="POS_32R" className="ake" >
            <div id="POS_32R" className="drop nesz">
               <div id="positioname" className="positionname ">32R</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypeake"></div>
               <div id="uldno" className="uldnoake"></div>
               <div id="uldkg" className="uldkgake"></div>
               <div  className="nexx" >
                  <div className="noshow"></div>
                  <div id="arrowup" className="noshow" ></div>
                  <div className="noshow"></div>
                  <div className="noshow"></div>
                  <div id="maxkg" className="maxkgake">1587</div>
               </div>
               <div id="dginfo" className="dginfoake"></div>
            </div>
         </div>
      </td>
      <td id="POS_33R">
         <div id="POS_33R" className="ake" >
            <div id="POS_33R" className="drop nesz">
               <div id="positioname" className="positionname ">33R</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypeake"></div>
               <div id="uldno" className="uldnoake"></div>
               <div id="uldkg" className="uldkgake"></div>
               <div  className="nexx" >
                  <div className="noshow"></div>
                  <div id="arrowup" className="noshow" ></div>
                  <div className="noshow"></div>
                  <div className="noshow"></div>
                  <div id="maxkg" className="maxkgake">1587</div>
               </div>
               <div id="dginfo" className="dginfoake"></div>
            </div>
         </div>
      </td>
      <td id="POS_41R">
         <div id="POS_41R" className="ake sizing55 nexx3">
            <div id="POS_41R" className="drop nesz">
               <div id="positioname" className="positionname ">41R</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypeake"></div>
               <div id="uldno" className="uldnoake"></div>
               <div id="uldkg" className="uldkgake"></div>
               <div  className="nexx" >
                  <div className="noshow"></div>
                  <div id="arrowup" className="noshow" ></div>
                  <div className="noshow"></div>
                  <div className="noshow"></div>
                  <div id="maxkg" className="maxkgake">1587</div>
               </div>
               <div id="dginfo" className="dginfoake"></div>
            </div>
         </div>
      </td>
      <td id="POS_42R">
         <div id="POS_42R" className="ake" >
            <div id="POS_42R" className="drop nesz">
               <div id="positioname" className="positionname ">42R</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypeake"></div>
               <div id="uldno" className="uldnoake"></div>
               <div id="uldkg" className="uldkgake"></div>
               <div  className="nexx" >
                  <div className="noshow"></div>
                  <div id="arrowup" className="noshow" ></div>
                  <div className="noshow"></div>
                  <div className="noshow"></div>
                  <div id="maxkg" className="maxkgake">1587</div>
               </div>
               <div id="dginfo" className="dginfoake"></div>
            </div>
         </div>
      </td>
      <td id="POS_43R">
         <div id="POS_43R" className="ake" >
            <div id="POS_43R" className="drop nesz">
               <div id="positioname" className="positionname ">43R</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypeake"></div>
               <div id="uldno" className="uldnoake"></div>
               <div id="uldkg" className="uldkgake"></div>
               <div  className="nexx" >
                  <div className="noshow"></div>
                  <div id="arrowup" className="noshow" ></div>
                  <div className="noshow"></div>
                  <div className="noshow"></div>
                  <div id="maxkg" className="maxkgake">1587</div>
               </div>
               <div id="dginfo" className="dginfoake"></div>
            </div>
         </div>
      </td>
      <td id="POS_51B">
         <div id="POS_51B" className="zzellibir sizing66 nexx4">
            <div id="POS_51B" className="drop nesz">
               <div id="positioname" className="positionname ">51</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypeake"></div>
               <div id="uldno" className="uldnoake"></div>
               <div id="uldkg" className="uldkgake"></div>
               <div  className="nexx" >
                  <div className="noshow"></div>
                  <div id="arrowup" className="noshow" ></div>
                  <div className="noshow"></div>
                  <div className="noshow"></div>
                  <div id="maxkg" className="maxkgellibir">264</div>
               </div>
               <div id="dginfo" className="dginfoake"></div>
            </div>
         </div>
      </td>
      <td id="POS_51">
         <div id="POS_51" className="noshow nexx5">
            <div id="POS_51" className="drop nesz">
               <div id="positioname" className="positionname ">51</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypeake"></div>
               <div id="uldno" className="uldnoake"></div>
               <div id="uldkg" className="uldkgake"></div>
               <div  className="nexx" >
                  <div className="noshow"></div>
                  <div id="arrowup" className="noshow" ></div>
                  <div className="noshow"></div>
                  <div className="noshow"></div>
                  <div id="maxkg" className="maxkgellibir">339</div>
               </div>
               <div id="dginfo" className="dginfoake"></div>
            </div>
         </div>
      </td>
      <td id="POS_53B">
         <div id="POS_53B" className="elliuc" >
            <div id="POS_53B" className="drop nesz">
               <div id="positioname" className="positionname ">53</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypeld"></div>
               <div id="uldno" className="uldnold"></div>
               <div id="uldkg" className="uldkgld"></div>
               <div  className="nexx" >
                  <div className="noshow"></div>
                  <div id="arrowup" className="noshow" ></div>
                  <div className="noshow"></div>
                  <div className="noshow"></div>
                  <div id="maxkg" className="maxkgeu">1617</div>
               </div>
               <div id="dginfo" className="dginfopla"></div>
            </div>
         </div>
      </td>
      <td id="POS_53">
         <div id="POS_53" className="noshow" >
            <div id="POS_53" className="drop nesz">
               <div id="positioname" className="positionname ">53</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypeld"></div>
               <div id="uldno" className="uldnold"></div>
               <div id="uldkg" className="uldkgld"></div>
               <div  className="nexx" >
                  <div className="noshow"></div>
                  <div id="arrowup" className="noshow" ></div>
                  <div className="noshow"></div>
                  <div className="noshow"></div>
                  <div id="maxkg" className="maxkgeu">1716</div>
               </div>
               <div id="dginfo" className="dginfopla"></div>
            </div>
         </div>
      </td>
   </tr>
   <tr>
      <td id="POS_11L">
         <div id="POS_11L" className="ake2" >
            <div id="POS_11L" className="drop nesz">
               <div id="positioname" className="positionname ">11L</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypeake"></div>
               <div id="uldno" className="uldnoake"></div>
               <div id="uldkg" className="uldkgake"></div>
               <div  className="nexx" >
                  <div className="noshow"></div>
                  <div id="arrowup" className="noshow" ></div>
                  <div className="noshow"></div>
                  <div className="noshow"></div>
                  <div id="maxkg" className="maxkgake">1587</div>
               </div>
               <div id="dginfo" className="dginfoake"></div>
            </div>
         </div>
      </td>
      <td id="POS_12L">
         <div id="POS_12L" className="ake2 sizing22 nexx2">
            <div id="POS_12L" className="drop nesz">
               <div id="positioname" className="positionname ">12L</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypeake"></div>
               <div id="uldno" className="uldnoake"></div>
               <div id="uldkg" className="uldkgake"></div>
               <div  className="nexx" >
                  <div className="noshow"></div>
                  <div id="arrowup" className="noshow" ></div>
                  <div className="noshow"></div>
                  <div className="noshow"></div>
                  <div id="maxkg" className="maxkgake">1587</div>
               </div>
               <div id="dginfo" className="dginfoake"></div>
            </div>
         </div>
      </td>
      <td id="POS_13L">
         <div id="POS_13L" className="ake2" >
            <div id="POS_13L" className="drop nesz">
               <div id="positioname" className="positionname ">13L</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypeake"></div>
               <div id="uldno" className="uldnoake"></div>
               <div id="uldkg" className="uldkgake"></div>
               <div  className="nexx" >
                  <div className="noshow"></div>
                  <div id="arrowup" className="noshow" ></div>
                  <div className="noshow"></div>
                  <div className="noshow"></div>
                  <div id="maxkg" className="maxkgake">1587</div>
               </div>
               <div id="dginfo" className="dginfoake"></div>
            </div>
         </div>
      </td>
      <td id="POS_14L">
         <div id="POS_14L" className="ake2" >
            <div id="POS_14L" className="drop nesz">
               <div id="positioname" className="positionname ">14L</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypeake"></div>
               <div id="uldno" className="uldnoake"></div>
               <div id="uldkg" className="uldkgake"></div>
               <div  className="nexx" >
                  <div className="noshow"></div>
                  <div id="arrowup" className="noshow" ></div>
                  <div className="noshow"></div>
                  <div className="noshow"></div>
                  <div id="maxkg" className="maxkgake">1587</div>
               </div>
               <div id="dginfo" className="dginfoake"></div>
            </div>
         </div>
      </td>
      <td id="POS_21L">
         <div id="POS_21L" className="ake2 sizing22 nexx2">
            <div id="POS_21L" className="drop nesz">
               <div id="positioname" className="positionname ">21L</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypeake"></div>
               <div id="uldno" className="uldnoake"></div>
               <div id="uldkg" className="uldkgake"></div>
               <div  className="nexx" >
                  <div className="noshow"></div>
                  <div id="arrowup" className="noshow" ></div>
                  <div className="noshow"></div>
                  <div className="noshow"></div>
                  <div id="maxkg" className="maxkgake">1587</div>
               </div>
               <div id="dginfo" className="dginfoake"></div>
            </div>
         </div>
      </td>
      <td id="POS_22L">
         <div id="POS_22L" className="ake2" >
            <div id="POS_22L" className="drop nesz">
               <div id="positioname" className="positionname ">22L</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypeake"></div>
               <div id="uldno" className="uldnoake"></div>
               <div id="uldkg" className="uldkgake"></div>
               <div  className="nexx" >
                  <div className="noshow"></div>
                  <div id="arrowup" className="noshow" ></div>
                  <div className="noshow"></div>
                  <div className="noshow"></div>
                  <div id="maxkg" className="maxkgake">1587</div>
               </div>
               <div id="dginfo" className="dginfoake"></div>
            </div>
         </div>
      </td>
      <td id="POS_23L">
         <div id="POS_23L" className="ake2" >
            <div id="POS_23L" className="drop nesz">
               <div id="positioname" className="positionname ">23L</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypeake"></div>
               <div id="uldno" className="uldnoake"></div>
               <div id="uldkg" className="uldkgake"></div>
               <div  className="nexx" >
                  <div className="noshow"></div>
                  <div id="arrowup" className="noshow" ></div>
                  <div className="noshow"></div>
                  <div className="noshow"></div>
                  <div id="maxkg" className="maxkgake">1587</div>
               </div>
               <div id="dginfo" className="dginfoake"></div>
            </div>
         </div>
      </td>
      <td id="POS_">
         <div id="POS_" className="busy sizing44 nexx1" >
            <div id="POS_" className="drop nesz">
               <div id="positioname" className="positionname "></div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypeake"></div>
               <div id="uldno" className="uldnoake"></div>
               <div id="uldkg" className="uldkgake"></div>
               <div  className="nexx" >
                  <div className="noshow"></div>
                  <div id="arrowup" className="noshow" ></div>
                  <div className="noshow"></div>
                  <div className="noshow"></div>
               </div>
               <div id="dginfo" className="dginfoake"></div>
            </div>
         </div>
      </td>
      <td id="POS_31L">
         <div id="POS_31L" className="ake2" >
            <div id="POS_31L" className="drop nesz">
               <div id="positioname" className="positionname ">31L</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypeake"></div>
               <div id="uldno" className="uldnoake"></div>
               <div id="uldkg" className="uldkgake"></div>
               <div  className="nexx" >
                  <div className="noshow"></div>
                  <div id="arrowup" className="noshow" ></div>
                  <div className="noshow"></div>
                  <div className="noshow"></div>
                  <div id="maxkg" className="maxkgake">1587</div>
               </div>
               <div id="dginfo" className="dginfoake"></div>
            </div>
         </div>
      </td>
      <td id="POS_32L">
         <div id="POS_32L" className="ake2" >
            <div id="POS_32L" className="drop nesz">
               <div id="positioname" className="positionname ">32L</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypeake"></div>
               <div id="uldno" className="uldnoake"></div>
               <div id="uldkg" className="uldkgake"></div>
               <div  className="nexx" >
                  <div className="noshow"></div>
                  <div id="arrowup" className="noshow" ></div>
                  <div className="noshow"></div>
                  <div className="noshow"></div>
                  <div id="maxkg" className="maxkgake">1587</div>
               </div>
               <div id="dginfo" className="dginfoake"></div>
            </div>
         </div>
      </td>
      <td id="POS_33L">
         <div id="POS_33L" className="ake2" >
            <div id="POS_33L" className="drop nesz">
               <div id="positioname" className="positionname ">33L</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypeake"></div>
               <div id="uldno" className="uldnoake"></div>
               <div id="uldkg" className="uldkgake"></div>
               <div  className="nexx" >
                  <div className="noshow"></div>
                  <div id="arrowup" className="noshow" ></div>
                  <div className="noshow"></div>
                  <div className="noshow"></div>
                  <div id="maxkg" className="maxkgake">1587</div>
               </div>
               <div id="dginfo" className="dginfoake"></div>
            </div>
         </div>
      </td>
      <td id="POS_41L">
         <div id="POS_41L" className="ake2 sizing55 nexx3">
            <div id="POS_41L" className="drop nesz">
               <div id="positioname" className="positionname ">41L</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypeake"></div>
               <div id="uldno" className="uldnoake"></div>
               <div id="uldkg" className="uldkgake"></div>
               <div  className="nexx" >
                  <div className="noshow"></div>
                  <div id="arrowup" className="noshow" ></div>
                  <div className="noshow"></div>
                  <div className="noshow"></div>
                  <div id="maxkg" className="maxkgake">1587</div>
               </div>
               <div id="dginfo" className="dginfoake"></div>
            </div>
         </div>
      </td>
      <td id="POS_42L">
         <div id="POS_42L" className="ake2" >
            <div id="POS_42L" className="drop nesz">
               <div id="positioname" className="positionname ">42L</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypeake"></div>
               <div id="uldno" className="uldnoake"></div>
               <div id="uldkg" className="uldkgake"></div>
               <div  className="nexx" >
                  <div className="noshow"></div>
                  <div id="arrowup" className="noshow" ></div>
                  <div className="noshow"></div>
                  <div className="noshow"></div>
                  <div id="maxkg" className="maxkgake">1587</div>
               </div>
               <div id="dginfo" className="dginfoake"></div>
            </div>
         </div>
      </td>
      <td id="POS_43L">
         <div id="POS_43L" className="ake2" >
            <div id="POS_43L" className="drop nesz">
               <div id="positioname" className="positionname ">43L</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypeake"></div>
               <div id="uldno" className="uldnoake"></div>
               <div id="uldkg" className="uldkgake"></div>
               <div  className="nexx" >
                  <div className="noshow"></div>
                  <div id="arrowup" className="noshow" ></div>
                  <div className="noshow"></div>
                  <div className="noshow"></div>
                  <div id="maxkg" className="maxkgake">1587</div>
               </div>
               <div id="dginfo" className="dginfoake"></div>
            </div>
         </div>
      </td>
      <td id="POS_52">
         <div id="POS_52" className="noshow nexx44">
            <div id="POS_52" className="drop nesz">
               <div id="positioname" className="positionname ">52</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypeake"></div>
               <div id="uldno" className="uldnoake"></div>
               <div id="uldkg" className="uldkgake"></div>
               <div  className="nexx" >
                  <div className="noshow"></div>
                  <div id="arrowup" className="noshow" ></div>
                  <div className="noshow"></div>
                  <div className="noshow"></div>
                  <div id="maxkg" className="maxkgake">1413</div>
               </div>
               <div id="dginfo" className="dginfoake"></div>
            </div>
         </div>
      </td>
      <td id="POS_44L">
         <div id="POS_44L" className="kirkdord sizing99 nexx5">
            <div id="POS_44L" className="drop nesz">
               <div id="positioname" className="positionname ">44L</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypeake"></div>
               <div id="uldno" className="uldnoake"></div>
               <div id="uldkg" className="uldkgake"></div>
               <div  className="nexx" >
                  <div className="noshow"></div>
                  <div id="arrowup" className="noshow" ></div>
                  <div className="noshow"></div>
                  <div className="noshow"></div>
                  <div id="maxkg" className="maxkgake">1587</div>
               </div>
               <div id="dginfo" className="dginfoake"></div>
            </div>
         </div>
      </td>
   </tr>
</table>
<table className='nexx66'>

   <tr>
      <td id="POS_12P">
         <div id="POS_12P" className="singlepmcld nexx77" >
            <div id="POS_12P" className="drop nesz">
               <div id="positioname" className="positionname ">12P</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypeldssingle"></div>
               <div id="uldno" className="uldno"></div>
               <div id="uldkg" className="uldkgldld"></div>
               <div  className="nexx" >
                  <div className="noshow"></div>
                  <div id="arrowup" className="noshow" ></div>
                  <div className="noshow"></div>
                  <div className="noshow"></div>
                  <div id="maxkgldpallet" className="maxkgldpallet">PMC-5103<br />PAG-4626</div>
               </div>
               <div id="dginfo" className="dginfo"></div>
            </div>
         </div>
      </td>
      <td id="POS_13P">
         <div id="POS_13P" className="singlepmcld" >
            <div id="POS_13P" className="drop nesz">
               <div id="positioname" className="positionname ">13P</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypeldssingle"></div>
               <div id="uldno" className="uldno"></div>
               <div id="uldkg" className="uldkgldld"></div>
               <div  className="nexx" >
                  <div className="noshow"></div>
                  <div id="arrowup" className="noshow" ></div>
                  <div className="noshow"></div>
                  <div className="noshow"></div>
                  <div id="maxkgldpallet" className="maxkgldpallet">PMC-5103<br />PAG-4626</div>
               </div>
               <div id="dginfo" className="dginfo"></div>
            </div>
         </div>
      </td>
      <td id="POS_21P">
         <div id="POS_21P" className="singlepmcld" >
            <div id="POS_21P" className="drop nesz">
               <div id="positioname" className="positionname ">21P</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypeldssingle"></div>
               <div id="uldno" className="uldno"></div>
               <div id="uldkg" className="uldkgldld"></div>
               <div  className="nexx" >
                  <div className="noshow"></div>
                  <div id="arrowup" className="noshow" ></div>
                  <div className="noshow"></div>
                  <div className="noshow"></div>
                  <div id="maxkgldpallet" className="maxkgldpallet">PMC-5103<br />PAG-4626</div>
               </div>
               <div id="dginfo" className="dginfo"></div>
            </div>
         </div>
      </td>
      <td id="POS_22P">
         <div id="POS_22P" className="singlepmcld" >
            <div id="POS_22P" className="drop nesz">
               <div id="positioname" className="positionname ">22P</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypeldssingle"></div>
               <div id="uldno" className="uldno"></div>
               <div id="uldkg" className="uldkgldld"></div>
               <div  className="nexx" >
                  <div className="noshow"></div>
                  <div id="arrowup" className="noshow" ></div>
                  <div className="noshow"></div>
                  <div className="noshow"></div>
                  <div id="maxkgldpallet" className="maxkgldpallet">PMC-5103<br />PAG-4626</div>
               </div>
               <div id="dginfo" className="dginfo"></div>
            </div>
         </div>
      </td>
      <td id="POS_">
         <div id="POS_" className="noshoww nexx1">
            <div id="POS_" className="drop nesz">
               <div id="positioname" className="positionname "></div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypeldssingle"></div>
               <div id="uldno" className="uldno"></div>
               <div id="uldkg" className="uldkgldld"></div>
               <div  className="nexx" >
                  <div className="noshow"></div>
                  <div id="arrowup" className="noshow" ></div>
                  <div className="noshow"></div>
                  <div className="noshow"></div>
                  <div id="maxkg" className="maxkgsinglepmc"></div>
               </div>
               <div id="dginfo" className="dginfo"></div>
            </div>
         </div>
      </td>
      <td id="POS_31P">
         <div id="POS_31P" className="singlepmcld" >
            <div id="POS_31P" className="drop nesz">
               <div id="positioname" className="positionname ">31P</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypeldssingle"></div>
               <div id="uldno" className="uldno"></div>
               <div id="uldkg" className="uldkgldld"></div>
               <div  className="nexx" >
                  <div className="noshow"></div>
                  <div id="arrowup" className="noshow" ></div>
                  <div className="noshow"></div>
                  <div className="noshow"></div>
                  <div id="maxkgldpallet" className="maxkgldpallet">PMC-5103<br />PAG-4626</div>
               </div>
               <div id="dginfo" className="dginfo"></div>
            </div>
         </div>
      </td>
      <td id="POS_32P">
         <div id="POS_32P" className="singlepmcld" >
            <div id="POS_32P" className="drop nesz">
               <div id="positioname" className="positionname ">32P</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypeldssingle"></div>
               <div id="uldno" className="uldno"></div>
               <div id="uldkg" className="uldkgldld"></div>
               <div  className="nexx" >
                  <div className="noshow"></div>
                  <div id="arrowup" className="noshow" ></div>
                  <div className="noshow"></div>
                  <div className="noshow"></div>
                  <div id="maxkgldpallet" className="maxkgldpallet">PMC-5103<br />PAG-4626</div>
               </div>
               <div id="dginfo" className="dginfo"></div>
            </div>
         </div>
      </td>
      <td id="POS_41P">
         <div id="POS_41P" className="singlepmcld" >
            <div id="POS_41P" className="drop nesz">
               <div id="positioname" className="positionname ">41P</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypeldssingle"></div>
               <div id="uldno" className="uldno"></div>
               <div id="uldkg" className="uldkgldld"></div>
               <div  className="nexx" >
                  <div className="noshow"></div>
                  <div id="arrowup" className="noshow" ></div>
                  <div className="noshow"></div>
                  <div className="noshow"></div>
                  <div id="maxkgldpallet" className="maxkgldpallet">PMC-5103<br />PAG-4626</div>
               </div>
               <div id="dginfo" className="dginfo"></div>
            </div>
         </div>
      </td>
      <td id="POS_42P">
         <div id="POS_42P" className="singlepmcld" >
            <div id="POS_42P" className="drop nesz">
               <div id="positioname" className="positionname ">42P</div>
               <div id="destination" className="destination"></div>
               <br />
               <div id="transit3" className="transit3 "></div>
               <div id="uldtype" className="uldtypeldssingle"></div>
               <div id="uldno" className="uldno"></div>
               <div id="uldkg" className="uldkgldld"></div>
               <div  className="nexx" >
                  <div className="noshow"></div>
                  <div id="arrowup" className="noshow" ></div>
                  <div className="noshow"></div>
                  <div className="noshow"></div>
                  <div id="maxkgldpallet" className="maxkgldpallet">PMC-5103<br />PAG-4626</div>
               </div>
               <div id="dginfo" className="dginfo"></div>
            </div>
         </div>
      </td>
   </tr>
</table>

    </div>
  );
}

export default EntegreLir;