import { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';  // Import useNavigate
import { jwtDecode } from 'jwt-decode';
import db from '../components/GlobalDb';
import config from '../config';

export default function CreateClassroom() {
  const [className, setClassName] = useState('');
  const [classCreationError, setClassCreationError] = useState(null);
  const [myCourses, setMyCourses] = useState([]);

  const fetchMyCourses = async () => {
    try {
      const tokenObject = await db.tokens.toCollection().first();
      if (tokenObject) {
        const token = tokenObject.token;

        const response = await fetch(`${config.MY_API}api/mycourses`, {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });

        const responseData = await response.json();

        if (response.ok && responseData.success) {
          setMyCourses(responseData.message);
        } else {
          throw new Error(responseData.message || 'Error fetching courses');
        }
      } else {
        throw new Error('No token found');
      }
    } catch (error) {
      setClassCreationError(error.message);
    }
  };

  useEffect(() => {
    fetchMyCourses();
  }, []);

  const navigate = useNavigate();
  const handleClassroomClick = (classroomId) => {
    // Navigate to the specific classroom based on its ID
    navigate(`/manage-course/${classroomId}`);
  };




  const handleClassCreation = async () => {
    try {
      const tokenObject = await db.tokens.toCollection().first();
      if (tokenObject) {
        const token = tokenObject.token;

        const response = await fetch(`${config.MY_API}api/newclassroom`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
          body: JSON.stringify({
            classroom: className,
          }),
        });

        const responseData = await response.json();

        if (response.ok && responseData.success) {
          // Handle successful classroom creation
          console.log('Classroom created successfully');
          // After creating the classroom, fetch the updated list of courses
          fetchMyCourses();
        } else {
          throw new Error(responseData.message || 'Error creating classroom');
        }
      } else {
        throw new Error('No token found');
      }
    } catch (error) {
      setClassCreationError(error.message);
    }
  };

  return (

    <div className="card">
    <h5 className="card-header">Devam Eden Eğitimler</h5>
    <div className="table-responsive text-nowrap">
      <table className="table">
        <thead className="table-light">
          <tr>
            <th>Eğitim Adı</th>
            <th>Dil</th>
            <th>Durum</th>
            <th>İşlemler</th>
          </tr>
        </thead>

        <tbody className="table-border-bottom-0">
          {myCourses.map((course) => (
            <tr key={course.id}>
              <td>{course.name}</td>
              <td>{course.status}</td>
              <td>{course.created_date}</td>
              <td>
                <div className="dropdown">
                  <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                    <i className="ti ti-dots-vertical"></i>
                  </button>
                  <div className="dropdown-menu">
                    <a className="dropdown-item" href="javascript:void(0);"><i className="ti ti-pencil me-1"></i> Edit</a>
                    <a className="dropdown-item" href="javascript:void(0);"><i className="ti ti-trash me-1"></i> Delete</a>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
  );
}
