import React from 'react';


const GoogleSlides = (link) => {
  console.log(link)
  return (
 
    <iframe
      className="fullscreen-iframe" // Özel stil sınıfı
      src={link.link}
      allow="autoplay"
    ></iframe>
 
  );
};

export default GoogleSlides;
