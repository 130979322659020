import React, { useEffect, useState, useRef } from 'react';
import { Nav, Tabs, Tab, Accordion, Modal, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { IconBook, IconFileText, IconTools, IconPlugConnectedX, IconSquareRoundedX, IconUsers, IconRefresh, IconUsersGroup, IconPencil, IconEyeCheck, IconWifi, IconSchool, IconPointFilled, IconMaximize, IconChevronRight } from '@tabler/icons-react';
import { IconChevronsRight, IconChevronsLeft } from '@tabler/icons-react';
import trainingDetails from '../components/trainingData';
import DraggableResizableModal from '../components/SpecialModal';
import Calculatorx from '../components/Calculator';
import Lirx from '../components/Lirx';
import MyPdfViewer from '../components/MyPdfViewer';
import DrawingBoard from '../components/DrawingBoard';
import QRCode from 'qrcode.react';
import Avatar from 'react-hot-avatars';
import Leaderboard from '../components/Leaderboardx';
import config from '../config';
import { Sidebar } from 'primereact/sidebar';
import GoogleSlides from '../components/GoogleSlides';


const Connect = () => {
  const [fullscreen, setFullscreen] = useState(false);
  const { id } = useParams();
  const [imageSrc, setImageSrc] = useState('');
  const [totalPage, setTotalPage] = useState(0);
  const [nowPage, setNowPage] = useState(0);
  const [nowEgitim, setnowEgitim] = useState('');
  const [isOpen, setOpen] = useState(false);
  const [isSubmenuOpen, setSubmenuOpen] = useState(false);
  const [isSubmenuOpen2, setSubmenuOpen2] = useState(false);
  const [wbmLinks, setWbmLinks] = useState([]); // wbm linklerini tutacak yeni state
  const [modals, setModals] = useState({}); // Her modal için durumu saklayacak yeni state
  const [isDrawingMode, setIsDrawingMode] = useState(false);
  const [show, setShow] = useState(false);
  const baseURL = (`${config.MY_DOMAIN}connect/`)
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [activeQuestionId, setActiveQuestionId] = useState('');
  const [activeScores, setActiveScores] = useState([]);
  const [toolboxLinks, setToolboxLinks] = useState([]);
  const [activeToolboxLink, setActiveToolboxLink] = useState('');
  const [visible, setVisible] = useState(false);
  const [calculatorVisible, setCalculatorVisible] = useState(false); // Separate state for calculator visibility
  const [lirVisible, setlirVisible] = useState(false); // Separate state for calculator visibility
  const [drawingColor, setDrawingColor] = useState('black'); // Başlangıçta siyah renk seçili
  const [showColors, setShowColors] = useState(false);
  const [selectedColor, setSelectedColor] = useState('black'); // Başlangıç rengi siyah
  const [googleSlidesLink, setGoogleSlidesLink] = useState('');
  const [images, setImages] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [components, setComponents] = useState([]);
  const [openCategories, setOpenCategories] = useState({});
  const [imagesTitle, setImagesTitle] = useState('');
  const [showImages, setShowImages] = useState(false);
  const [showDocuments, setShowDocuments] = useState(false);
  const [pdfDocumentsTitle, setPdfDocumentsTitle] = useState('');
  const [pdfModalVisible, setPdfModalVisible] = useState(false);
  const [activePdfLink, setActivePdfLink] = useState('');



  
  function DangerousHtml({ htmlContent }) {
    return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
  }

  const handlePdfClick = (pdfLink) => {
    setActivePdfLink(pdfLink);
    setPdfModalVisible(true);
    toggleDrawer();
  };


  const toggleDocumentsVisibility = () => {
    setShowDocuments(!showDocuments);
  };

  const pickerRef = useRef(null);

  const colors = ['black', 'red', 'blue', 'green', 'yellow'];

  const handleColorSelect = (color) => {
    setSelectedColor(color);
    setDrawingColor(color);
    setShowColors(false); // Renk seçildikten sonra menüyü kapat
  };

  // Dropdown dışına tıklama kontrolü
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setShowColors(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [pickerRef]);

  const toggleCategory = (kategoriAdi) => {
    setOpenCategories(prevState => ({
      ...prevState,
      [kategoriAdi]: !prevState[kategoriAdi]
    }));
  };

  const toggleImagesVisibility = () => {
    setShowImages(prevShowImages => !prevShowImages);
  };

  useEffect(() => {
    // Komponent yüklendiğinde arka plan rengini değiştir
    document.body.style.backgroundColor = "#e5e7e8";

    // Komponent kaldırıldığında arka plan rengini sıfırla
    return () => {
      document.body.style.backgroundColor = null;
    };
  }, []);

  useEffect(() => {
    const fetchGoogleSlidesLinkAndJsonx = async () => {
      try {
        const response = await fetch(`${config.MY_API}api/kurs-bilgisi`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ classroom: id }),
        });
        const data = await response.json();
        if (data.success) {
          setGoogleSlidesLink(data.message);
          // jsonx verisini almak için ek işlemler
          const jsonxUrl = `${config.MY_DOMAIN}${data.jsonx}`; // Bu kısım önemli, URL'inizi doğru bir şekilde oluşturduğunuzdan emin olun
          console.log(`Fetching JSONX from: ${jsonxUrl}`); // URL'i console'a yazdırma
          const jsonxResponse = await fetch(jsonxUrl);
          const jsonxData = await jsonxResponse.json();

          processJsonxData(jsonxData);
        } else {
          console.error('Failed to fetch Google Slides link and jsonx:', data.message);
        }
      } catch (error) {
        console.error('Error fetching Google Slides link and jsonx:', error);
      }
    };

    fetchGoogleSlidesLinkAndJsonx();
  }, [id]); // `id` değişkeni değiştiğinde useEffect tekrar çalışır


  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    toggleDrawer();

  };

  const processJsonxData = (data) => {
    // Resimler için state güncelleme
    const imageCategories = data.resimler_icin_bolum.resim_kategorileri.map(category => ({
      kategori_adi: category.kategori_adi,
      icerik: category.icerik
    }));
    setImages(imageCategories);
    setImagesTitle(data.resimler_icin_bolum.ana_baslik);

    // PDF dosyaları için state güncelleme
    const pdfFiles = data.pdf_dosyalari_icin_bolum.pdf_dosyalari;
    setDocuments(pdfFiles);

    // Bileşenler için state güncelleme
    const componentStatus = data.bilesenler_icin_bolum.bilesenler;
    setComponents(componentStatus);


    // Resimler için ana başlık güncellemesi
    setPdfDocumentsTitle(data.pdf_dosyalari_icin_bolum.ana_baslik);

    const pdfDocuments = data.pdf_dosyalari_icin_bolum.pdf_dosyalari.map(document => ({
      baslik: document.baslik,
      link: document.link
    }));
    setDocuments(pdfDocuments);



  };


  const handleToolboxLinkClick = (pdfUrl) => {
    setActiveToolboxLink(pdfUrl);
    setVisible(true);
  };

  const toggleCalculatorVisibility = () => {
    setCalculatorVisible(!calculatorVisible);
    toggleDrawer();
  };

  const togglelirVisibility = () => {
    setlirVisible(!lirVisible);
    toggleDrawer();
  };

  const fetchActiveScores = async () => {
    if (activeQuestionId) { // Aktif bir soru varsa
      try {
        const response = await fetch(`${config.MY_API}api/active-scores`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ classroom: id }),
        });
        const data = await response.json();
        if (data.success) {
          setActiveScores(data.message);
        } else {
          console.error('Failed to fetch active scores:', data.message);
        }
      } catch (error) {
        console.error('Error fetching active scores:', error);
      }
    }
  };

  useEffect(() => {
    let intervalId;

    if (activeQuestionId) {
      fetchActiveScores(); // İlk yüklemede ve soru yayınlandığında hemen fetch edilir
      intervalId = setInterval(fetchActiveScores, 4000); // 4 saniyede bir fetch işlemi
    }

    return () => clearInterval(intervalId); // Cleanup
  }, [activeQuestionId, id]);

  // Sonuçları sıralayın ve listeyin
  const sortedScores = activeScores.sort((a, b) => {
    if (a.result === b.result) {
      return a.timex - b.timex; // Süreye göre sıralama
    }
    return b.result - a.result; // Sonuca göre sıralama
  });



  useEffect(() => {
    const fetchActiveQuestion = async () => {
      // Burada check-active endpoint'ine istek yapın ve aktif soru ID'sini alın
      const response = await fetch(`${config.MY_API}api/check-active`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ classroom: id }),
      });
      const data = await response.json();
      if (data.success && data.message) {
        setActiveQuestionId(data.message);
        // Aktif soru detaylarını da burada alabilirsiniz
        // Örneğin: setActiveQuestionDetails(fetchedQuestionDetails);
      } else {
        setActiveQuestionId('');
        // Aktif soru yoksa, detayları temizleyin
        // Örneğin: setActiveQuestionDetails({});
      }
    };
    fetchActiveQuestion();
  }, [id])

  const fetchQuestions = async () => {
    try {
      const response = await fetch(`${config.MY_API}api/sorulari-listele`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          classroom: id,
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      if (data.success) {
        setQuestions(data.message); // Soruları state'e set et
      } else {
        throw new Error('Failed to fetch questions: ' + data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Component mount olduğunda soruları fetch et
  useEffect(() => {
    fetchQuestions();
  }, [id]); // id değiştiğinde soruları yeniden fetch et





  const fetchOnlineUsers = async () => {
    try {
      const response = await fetch(`${config.MY_API}api/connected-users`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          classroom: id,
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const { success, message } = await response.json(); // Parse JSON response
      if (success) {
        const users = message.split(',') // Split the message by comma and space
          .filter(user => user.trim() !== ''); // Filter out empty strings
        setOnlineUsers(users); // Update the state with the fetched users
      } else {
        throw new Error('Failed to fetch online users: ' + message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchOnlineUsers(); // Fetch once on component mount
    const intervalId = setInterval(fetchOnlineUsers, 3000); // Poll every 3 seconds

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [id]); // Depend on `id` so it refetches if `id` changes




  const toggleDrawingMode = () => {
    setIsDrawingMode(!isDrawingMode);
  };



  const handleSubmenuToggle = () => {
    setSubmenuOpen(!isSubmenuOpen);
  };

  const handleSubmenuToggle2 = () => {
    setSubmenuOpen2(!isSubmenuOpen2);
  };



  const toggleDrawer = () => {
    setOpen(!isOpen);
  };


  useEffect(() => {
    // Eğitim detayları ve linklerin yüklenmesi...
    const egitimDetaylari = trainingDetails.find(egitim => egitim.KonuTur === nowEgitim);
    if (egitimDetaylari) {
      const ilgiliKonu = egitimDetaylari.konular.find(konu => parseInt(konu.id) === parseInt(nowPage));
      if (ilgiliKonu) {

        const newToolboxLinks = [];
        for (let i = 1; i <= 3; i++) { // Varsayılan olarak 3 toolbox linki kontrol ediliyor, bu sayı artırılabilir.
          const name = egitimDetaylari[`toolbox_${i}_name`];
          const pdf = egitimDetaylari[`toolbox_${i}_pdf`];
          if (name && pdf) {
            newToolboxLinks.push({ name, pdf });
          }
        }
        setToolboxLinks(newToolboxLinks);

        const links = [];
        const newModals = {};
        for (let i = 1; i <= 10; i++) {
          const title = ilgiliKonu[`wbm${i}_title`];
          const link = ilgiliKonu[`wbm${i}_link`];
          const page = ilgiliKonu[`wbm${i}_page`];
          if (title && link && page) {
            const id = `modal-${i}`;
            links.push({ id, title, link });
            newModals[id] = { show: false, content: { title, link, page } };
          }
        }
        setWbmLinks(links);
        setModals(newModals);
      }
    }
  }, [nowEgitim, nowPage]);











  const toggleFullscreen = () => {
    try {
      const element = document.documentElement;

      if (!document.fullscreenElement) {
        // If document is not in fullscreen, request fullscreen
        element.requestFullscreen();
      } else {
        // If document is in fullscreen, exit fullscreen
        document.exitFullscreen();
      }

      setFullscreen(!fullscreen);
    } catch (error) {
      console.error('Error toggling fullscreen:', error);
    }
  };

  const publishQuestion = async (qid = '') => {
    const url = `${config.MY_API}api/publish-question`;
    const payload = {
      classroom: id,
      qid,
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      if (data.success) {
        // Soruları ve aktif soru ID'sini güncelleyin
        setActiveQuestionId(qid); // qid boş ise tüm sorular pasif yapılıyor
        fetchQuestions(); // Soruları yeniden fetch edin
      } else {
        console.error('Failed to publish question:', data.message);
      }
    } catch (error) {
      console.error('Error publishing question:', error);
    }
  };


  useEffect(() => {
    const fsEnterButton = document.getElementById('fullscr');

    if (fsEnterButton) {
      fsEnterButton.addEventListener('click', toggleFullscreen);

      return () => {
        fsEnterButton.removeEventListener('click', toggleFullscreen);
      };
    } else {
      console.error('Fullscreen button not found.');
    }
  }, [fullscreen]);




  const buttonStyle = {
    position: 'absolute',
    right: '10px',
    zIndex: '100',

  };

  const buttonStyle2 = {
    position: 'absolute',
    right: '100px',
    zIndex: '99',

  };

  const buttonStyle3 = {
    position: 'absolute',
    right: '10px',
    zIndex: '99',

  };


  
  let seceneklerDizisi;

  try {
    seceneklerDizisi = JSON.parse(activeQuestion.secenekler);
  } catch (e) {
    console.error('Parsing error:', e);
    // Eğer parse işlemi başarısız olursa, seceneklerDizisi'ni varsayılan bir diziye ayarlayabilirsiniz.
    seceneklerDizisi = []; // veya uygun bir hata mesajı gösterebilirsiniz.
  }





  const [highestZIndex, setHighestZIndex] = useState(1000); // Başlangıç z-index değeri

  const openModal = (id, link, title, page) => {
    setModals(prevModals => ({
      ...prevModals,
      [id]: {
        show: true,
        content: {
          imgLink: link,
          imgTitle: title,
          imgPage: page, // Doğru bir şekilde aktarılmalı
        },
      },
    }));
    toggleDrawer();
  };
  const closeModal = (modalId) => {
    setModals(prevModals => ({
      ...prevModals,
      [modalId]: { ...prevModals[modalId], show: false },
    }));
    // Z-index değerini azaltma işlemi genellikle gerekli değildir, çünkü modal kapandığında görünmez olur
  };

  const activeQuestion = questions.find(question => question.id === activeQuestionId);

  const sidebarHeader = (
    <h2 className="offcanvas-title" id="addEventSidebarLabel">
      Training Content
    </h2>
  );

  const renderCategoryContent = (category) => {
    if (openCategories[category.kategori_adi]) {
      return (
        <div>
          {category.icerik.map((item, index) => (
            <div key={index} className="nav-link" onClick={() => openModal(`modal-${index}`, item.link, item.baslik, item.linktitle)}>
              <IconChevronRight size={16} stroke={2} className="icon-margin" /> {item.baslik}
            </div>
          ))}
        </div>
      );
    }
    return null;
  };


  const handleReflesh = () => {
    window.location.reload(); // Sayfayı yeniden yükleyin
  };


  const QuestionOptions = ({ secenekler }) => {
    let seceneklerDizisi;
  
    try {
      // secenekler dizisi halinde geliyorsa direkt atama yap, değilse JSON.parse ile dönüştür
      seceneklerDizisi = Array.isArray(secenekler) ? secenekler : JSON.parse(secenekler);
    } catch (e) {
      console.error('Parsing error:', e);
      seceneklerDizisi = []; // Parse işlemi başarısız olursa boş bir dizi ata
    }
  
    return (
      <div>
        {seceneklerDizisi.map((secenek, index) => (
          <><span key={index}><b>{String.fromCharCode(65 + index)}</b>) {secenek}</span><br /></>
        ))}
      </div>
    );
  };

  return (
    <div>
      <span style={buttonStyle} id="fullscr">
        <IconMaximize size={28} stroke={2} color={'green'} />
      </span>
      <Sidebar header={<h2 className="offcanvas-title" id="addEventSidebarLabel">Training Content</h2>} visible={isOpen} position="right" onHide={toggleDrawer}>
        <div>
          <ul className="listview flush transparent no-line image-listview">
          {
  images && images.length > 0 && (
    <li>
      <Nav>
        <Nav.Link onClick={toggleImagesVisibility} className="item">
          <div className="icon-box bg-primary">
            <IconFileText size={20} stroke={2} color="#fff" />
          </div>
          <div className="in">{imagesTitle}</div>
        </Nav.Link>
      </Nav>
      {showImages && (
        <div className='mini-box'>
          {images.map((category, index) => (
            <div key={index}>
              <div onClick={() => toggleCategory(category.kategori_adi)} className="item">
                <IconPointFilled size={25} stroke={2} className="icon-margin" /> {category.kategori_adi}
              </div>
              {renderCategoryContent(category)}
            </div>
          ))}
        </div>
      )}
    </li>
  )
}

{
  documents && documents.length > 0 && (
    <li>
      <Nav>
        <Nav.Link onClick={() => toggleDocumentsVisibility()} className="item">
          <div className="icon-box bg-primary">
            <IconBook size={20} stroke={2} color="#fff" />
          </div>
          <div className="in">{pdfDocumentsTitle}</div>
        </Nav.Link>
      </Nav>
      {showDocuments && (
        <div className="mini-box">
          {documents.map((document, index) => (
            <div key={index} className="item" onClick={() => handlePdfClick(document.link)}>
              <IconPointFilled size={25} stroke={2} className="icon-margin" /> {document.baslik}
            </div>
          ))}
        </div>
      )}
    </li>
  )
}




            <li>
              <Nav defaultActiveKey="/home">
                <Nav.Link onClick={() => setSubmenuOpen2(!isSubmenuOpen2)} className="item">
                  <div className="icon-box bg-primary">
                    <IconTools size={20} stroke={2} color="#fff" />
                  </div>
                  <div className="in">Toolbox</div>
                </Nav.Link>
                {isSubmenuOpen2 && (
                  <div className="mini-box">
                    {toolboxLinks.map((link, index) => (
                      <a key={index} onClick={() => handleToolboxLinkClick(link.pdf)} className="nav-link">
                        <IconPointFilled size={25} stroke={2} /> {link.name}
                      </a>


                    ))}
                    <a className="nav-link" onClick={toggleCalculatorVisibility} >
                      <IconPointFilled size={16} stroke={2} /> Calculator
                    </a>
                    <a className="nav-link" onClick={togglelirVisibility} >
                      <IconPointFilled size={16} stroke={2} /> A330F Positions
                    </a>
                  </div>
                )}
              </Nav>
            </li>


            <li>
              <span onClick={handleShow} className="item">
                <div className="icon-box bg-primary">
                  <IconUsers size={20} stroke={2} />
                </div>
                <div className="in">
                  Course Management
                </div>
              </span>
            </li>

          </ul>
        </div>


      </Sidebar >

      <GoogleSlides link={googleSlidesLink} />

      {
        Object.entries(modals).map(([id, modal]) => {
          if (modal.show && modal.content) { // modal açıksa ve content özelliği varsa
            return (
              <DraggableResizableModal
                key={id}
                modalId={id}
                show={modal.show}
                imgLink={modal.content.imgLink} // imgLink'i kullan
                imgTitle={modal.content.imgTitle}
                imgPage={modal.content.imgPage}  // Başlık veya diğer içerik özelliklerini kullanabilirsiniz
                onHide={() => closeModal(id)}
              />
            );
          }
          return null;
        })
      }


      <Calculatorx
        show={calculatorVisible}
        onHide={() => setCalculatorVisible(false)}
      />


      <Lirx
        show={lirVisible}
        onHide={() => setlirVisible(false)}
      />

      {/* Other components or elements */}
      <DrawingBoard isDrawingMode={isDrawingMode} drawingColor={drawingColor} />



      <button className="btn btn-label-dark waves-effect pekstrabutton" onClick={toggleDrawer}><IconSchool size={18} stroke={1} color="#000" />&nbsp;&nbsp;Training Data</button>

      <button className="btn btn-label-dark waves-effect button-boxr" onClick={handleReflesh}><IconRefresh size={18} stroke={2} color="#000" /></button>


      <button className="btn btn-label-dark waves-effect pekstrabutton2" onClick={toggleDrawingMode}><IconPencil size={18} stroke={1} color="#000" />&nbsp;
        {isDrawingMode ? 'Stop Drawing' : 'Start Drawing'}
      </button>

      <div ref={pickerRef} className='color-box'>
        <div
          onClick={() => setShowColors(!showColors)}
          style={{ width: '20px', height: '20px', borderRadius: '50%', backgroundColor: selectedColor, cursor: 'pointer', boxShadow: '0 2px 4px rgba(0,0,0,0.2)' }}
        ></div>
        {showColors && (
          <div style={{
            position: 'absolute',
            bottom: '50px', // Yönü yukarı çevir
            left: '50%', // Orta hizalama için
            transform: 'translateX(-50%)', // Orta hizalamayı sağlamak için
            backgroundColor: '#fff',
            boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
            borderRadius: '5px',
            padding: '5px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center', // İçerikleri merkezile
          }}>
            {colors.map(color => (
              <div
                key={color}
                onClick={() => handleColorSelect(color)}
                style={{ width: '20px', height: '20px', marginBottom: '5px', borderRadius: '50%', backgroundColor: color, cursor: 'pointer' }}
              ></div>
            ))}
          </div>
        )}
      </div>


      <Modal className="modal fade modalbox" show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title><IconUsers size={22} stroke={2} color='#000' /> Course Management</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3">
            <Tab eventKey="home" title="Connected Users">
              <div className="row">
                <div className="col-md-6">
                  <p><b>Kick-Start Guide</b></p>
                  <p> You can join the training with an internet-connected mobile device or computer.
                  </p>
                  <p>Mobile devices can log in by using their cameras to scan the QR code below. 👇</p>
                  <QRCode size={256} value={baseURL + id} />
                  <p></p>    <p></p>
                  <p>Other internet-connected devices can log in through the URL provided below.</p>
                  <div className="form-group boxed">
                    <div className="input-wrapper">
                      <label className="form-label" for="name5">URL</label>
                      <input type="text" className="form-control" id="name5" value={baseURL + id} autocomplete="off" />
                      <i className="clear-input">
                        <ion-icon name="close-circle" role="img" className="md hydrated" aria-label="close circle"></ion-icon>
                      </i>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <p> <IconWifi size={20} stroke={2} color="#000" /> <b>Connected Users</b></p>
                  <ul className="listview image-listview">
                    {onlineUsers.map((user, index) => (
                      <li key={index}>
                        <div className="item">

                          <Avatar name={user.replace(/\s/g, '')} size="36" rounded />
                          <div className="in">
                            <div>&nbsp; {user}</div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>

                </div>
              </div>
            </Tab>
            <Tab eventKey="profile" title="Quiz Management">
              <div className="row">
                <div className="col-md-6">



                  <div className="card mb-2">

                    <h5 className="card-header">Select Question to Publish</h5>

                    <div className="table-responsive text-nowrap">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th></th>
                            <th></th>
                            <th></th>

                          </tr>
                        </thead>
                        <tbody className="table-border-bottom-0">
                          {questions.map((question, index) => (
                            <tr key={index} className={question.id === activeQuestionId ? 'active-question' : ''}>
                              <td>{question.soruadi}</td>
                              <td>
                                {question.soruzorluk === 'easy' ? (
                                  <span className="badge bg-label-success me-1">&nbsp;</span>
                                ) : question.soruzorluk === 'medium' ? (
                                  <span className="badge bg-label-warning me-1">&nbsp;</span>
                                ) : (
                                  <span className="badge bg-label-danger me-1">&nbsp;</span>
                                )}
                              </td>
                              <td>
                                {question.id === activeQuestionId ? (
                                  <span className="badge bg-label-success me-1">&nbsp;Active&nbsp; </span>
                                ) : (
                                  <span className="badge bg-label-secondary me-1">&nbsp;Inactive&nbsp; </span>
                                )}
                              </td>
                              <td>
                                <button type="button" className="kbutton" onClick={() => publishQuestion(question.id)}><IconUsersGroup size={16} stroke={2} /> Publish</button>

                              </td>
                            </tr>

                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end">
                    <button type="button" className="kbutton" onClick={() => publishQuestion()}><IconPlugConnectedX size={50} stroke={2} /></button>
                  </div>
                </div>
                <div className="col-md-6">

                  <div className="card">
                    {activeQuestion ? (
                      <div>
                        {/* Burada aktif sorunun detaylarını göster */}
                        <p className='m-2'>Active Question: <b><u>{activeQuestion.soruadi}</u></b></p>
                        {/* Gerekirse diğer detayları da burada gösterebilirsiniz */}


                        <Accordion defaultActiveKey="0">
                          <Accordion.Item eventKey="0">
                            <Accordion.Header><div className="online-dot"></div> Live Results</Accordion.Header>
                            <Accordion.Body>








                              <ul className="listview image-listview">
                                {sortedScores.length > 0 ? sortedScores.map((score, index) => (
                                  <li key={index}>
                                    <div className="item">
                                      <Avatar name={score.namex.replace(/\s/g, '')} size="36" rounded />
                                      <div className="in">
                                        &nbsp;&nbsp;{score.namex} &nbsp;&nbsp; <OverlayTrigger
                                          key="bottom"
                                          placement="bottom"
                                          overlay={
                                            <Tooltip id={`tooltip-bottom`}>{score.verilen_cevap}</Tooltip>
                                          }
                                        >
                                          <div>{score.result === '10' ? '✅' : '❌'}</div>
                                        </OverlayTrigger>
                                        <small>{score.timex >= 60000 ? `${Math.floor(score.timex / 60000)}min ` : ''}{((score.timex % 60000) / 1000).toFixed(0)}sec</small></div>
                                    </div>

                                  </li>
                                )) : <div className="loading-text m-5">Waiting for results<span className="dots"><span>.</span><span>.</span><span>.</span></span></div>}
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="1">
                            <Accordion.Header><IconEyeCheck size={28} stroke={2} /> &nbsp;View Question</Accordion.Header>
                            <Accordion.Body>
                              <div className="d-flex flex-column justify-content-center align-items-center text-center mb-5" style={{ height: '100%' }}>

                                <p className='mt-5 mb-5'>

                                  <DangerousHtml htmlContent={activeQuestion.sorutxt} />
                                </p>

                                {activeQuestion.sorutip !== 'klasik' && (
                                  <div className="answers-container">
                                    {activeQuestion && activeQuestion.sorutip !== 'klasik' && (
  <div className="answers-container">
    <QuestionOptions secenekler={activeQuestion.secenekler} />
  </div>
)}
                                  </div>
                                )}
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>


                      </div>
                    ) : (
                      <div className="d-flex flex-column justify-content-center align-items-center text-center mb-5" style={{ height: '200px' }}>
                        <p>No question is active 🙊. To share a new question, please select from the list on the right and click the publish button.</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Tab>

            <Tab eventKey="leaderboard" title="Leaderboard">
              <Leaderboard id={id} />
            </Tab>
          </Tabs>

        </Modal.Body>
        <Modal.Footer>

          <Button variant="kbutton" onClick={handleClose}>
            <IconSquareRoundedX size={18} stroke={1} color="#000" />&nbsp;Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Sidebar visible={visible} onHide={() => setVisible(false)} fullScreen Style={{ backgroundColor: '#2f2f2f' }}>
        <div className='fzhidden'>
          <iframe
            className="fullscreen-iframex"
            src={activeToolboxLink}
            allow="autoplay"
          ></iframe>
        </div>
        <button
          onClick={() => setVisible(false)} // Sidebar'ı kapatmak için setVisible'i false yapar
          style={{
            position: 'absolute', // Butonun pozisyonunu ayarlar
            bottom: '10px', // Üstten boşluk
            right: '30px', // Sağdan boşluk
            zIndex: 1050, // Yüksek z-index değeri, iframe'den üstte gözükmesini sağlar
            background: 'red', // Butonun arkaplan rengi
            color: 'white', // Butonun yazı rengi
            border: 'none', // Kenarlık kaldırılır
            padding: '10px 20px', // İç boşluk
            borderRadius: '5px', // Kenar yuvarlaklığı
            cursor: 'pointer', // Fare imlecini işaretçi olarak değiştirir
          }}
        >
          <IconSquareRoundedX size={32} stroke={1} color="#fff" />&nbsp; Close
        </button>
      </Sidebar>
      {/* PDF Modal */}
      <Modal
        show={pdfModalVisible}
        onHide={() => setPdfModalVisible(false)}
        size="lg"
        fullscreen
        style={{ background: '#30302e' }}
      >

        <Modal.Body style={{ background: '#30302e' }}>
          {/* PDF görüntüleyici, aktif link ile */}
          <MyPdfViewer link={activePdfLink} />
          <Button className='buttonxxq' variant="secondary" onClick={() => setPdfModalVisible(false)}>
            <IconSquareRoundedX size={18} stroke={2} color="#fff" />&nbsp;Close
          </Button>
        </Modal.Body>
      </Modal>

    </div >
  );
};

export default Connect;
